import { Injectable } from '@angular/core';
import { Action, State, Store } from '@ngxs/store';
import { ResetBagList } from './manifest.action';
import { IManifestInfoResetStateModel } from 'src/app/features/model/manifest.model';
import { defaultManifestInformationData } from '../manifest.config';
import { ResetNotesSection } from 'src/app/shared/components/notes/store/notes.action';
import { ResetAtatchmnetCodes } from 'src/app/shared/components/attachments/store/attachments.action';

// Reset action
export class ManifestResetAllStates {
  static readonly type = '[Manifest] Reset All States';
}
export class ResetSharedStates {
  static readonly type = '[Global] Reset Shared States';
}
@State<IManifestInfoResetStateModel>({
  name: 'manifestResetStates',
  defaults: {
    data: defaultManifestInformationData(),
  },
})
@Injectable()
export class ManifestResetAllState {
  constructor(private readonly store: Store) {}

  @Action(ManifestResetAllStates)
  resetAllStates() {
    this.store.dispatch(new ResetBagList());
  }

  @Action(ResetSharedStates)
  clearSharedStates() {
    this.store.dispatch(new ResetNotesSection());
    this.store.dispatch(new ResetAtatchmnetCodes());
  }
}
