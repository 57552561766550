import { SelectionModel } from '@angular/cdk/collections';
import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { map, startWith, Subject, takeUntil } from 'rxjs';
import { ITableProp } from 'src/app/shared/models/tables.model';
import { CommonService } from 'src/app/shared/services/common.service';
import {
  ActionIcons,
  StatusCodes,
  MasterLookup,
  validationErrorMessages,
  CrudActions,
  pageType,
  ModeIcons,
  formFieldIds,
  popupWidth,
  ModalPopupMessages,
  selectionTableNames,
  selectedIdForSummaryTable,
} from 'src/app/shared/utility/constants';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { SvgIconComponent } from '../../svg-icon/svg-icon.component';
import { TableService } from '../tables.service';
import { ValidatorService } from 'src/app/shared/services/form-control-validators.service';
import {
  FormConfig,
  IDropdownResponse,
} from 'src/app/shared/models/dynamic.model';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandlerService } from 'src/app/core/services/error-handler.service';
import { CustomErrorTooltipComponent } from '../../custom-error-tooltip/custom-error-tooltip.component';
import { MatSort } from '@angular/material/sort';
import {
  ApiControls,
  AutocompleteControls,
  InputWithAlphaNumbers,
  InputWithAlphaNumbersHyphen,
  InputWithDecimalValidationKeys,
  InputWithNumbers,
  InputWithoutValidationKeys,
  InputWithTwoDecimal,
  TableControls,
  ValueControls,
} from '../tables.config';
import { ModalService } from 'src/app/shared/services/modal.service';
import { CrudPopupComponent } from '../../crud-popup/crud-popup.component';
import { StatuscodeAttachmentsComponent } from '../../statuscode-attachments/statuscode-attachments.component';
import { StatuscodeAdditionalDetailsComponent } from '../../statuscode-additional-details/statuscode-additional-details.component';

@Component({
  selector: 'app-create-edit-table',
  standalone: true,
  imports: [
    CommonModule,
    SvgIconComponent,
    SharedModule,
    CustomErrorTooltipComponent,
  ],
  templateUrl: './create-edit-table.component.html',
  styleUrl: './create-edit-table.component.scss',
})
export class CreateEditTableComponent
  implements OnInit, AfterViewChecked, OnDestroy
{
  @Input() crudTableDataSource!: MatTableDataSource<any>;
  @Input() crudTableData!: any;
  @Input() crudTableCustomizeConfig!: ITableProp[];
  @Input() isFrom!: string;
  @Input() formConfig!: FormConfig;
  @Output() crudTableFormValue = new EventEmitter<any>();
  @Output() selectionTableChange = new EventEmitter<any[]>();
  @Output() navigateData = new EventEmitter<any>();
  @ViewChild(MatSort) sort!: MatSort;
  @Input() pageMode: any;

  public crudTableForm!: FormGroup;
  public tableDisplayedColumns!: string[];
  public selection = new SelectionModel<any>(true, []);
  public selectAllChecked = false;
  public customiseCrudTableColumns!: ITableProp[];
  public filteredAirlineTypeOptions!: any;
  public airlineField!: any;
  public filteredPackagingTypeOptions!: any;
  public setCrudTableWidth: any = {};
  public setCrudTableErrorTooltip: any = {};
  public setCrudTableTooltipEvent: any = {};
  public filteredStatusCodesTypeOptions!: any;
  public statusCodesField!: any;
  public actionIcons = ActionIcons;
  public formFields = formFieldIds;
  public selectedRowModuleId = '';
  private errorMessages!: string;
  private readonly destroy$: Subject<boolean> = new Subject<boolean>();
  private hasFocused = false;
  private statusCodeSelected = false;
  @ViewChild('firstInput', { static: false }) firstInput!: ElementRef;
  public serviceTypeData!: any;
  public filteredserviceTypeOptions!: any;
  public serviceTypeSelected = false;
  public rateScaleData!: any;
  public filteredrateScaleOptions!: any;
  public fuelSurchargeData!: any;
  public filteredfuelSurchargeOptions!: any;

  constructor(
    public tableService: TableService,
    public commonService: CommonService,
    private readonly formUtilsService: ValidatorService,
    private readonly errorHandlerService: ErrorHandlerService,
    private readonly modalService: ModalService,
    private readonly cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    if (
      this.isFrom !== selectionTableNames.ShipmentCommodity &&
      this.isFrom !== selectionTableNames.customerService
    ) {
      this.getMasterLookupType(MasterLookup.airlineCode);
      this.getMasterLookupType(MasterLookup.statusCode);
    } else if (this.isFrom === selectionTableNames.customerService) {
      this.getMasterLookupType(MasterLookup.serviceType);
      this.getMasterLookupType(MasterLookup.rateScale);
      this.getMasterLookupType(MasterLookup.fuelSurcharge);
    }
    this.createForm();
    this.setRequiredInitilization();
    this.fetchDataOfOtherScreens();
    this.fetchDataOfOtherScreensandPatch();
  }

  ngAfterViewChecked(): void {
    if (
      !this.hasFocused &&
      this.firstInput &&
      this.pageMode == ModeIcons.view
    ) {
      this.firstInput.nativeElement.focus();
      this.hasFocused = true;
      this.cdRef.detectChanges();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  public toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      this.selectAllChecked = false;
    } else {
      const nonInputRows = this.crudTableDataSource.data.filter(
        (row: any) => !row.isInputRow
      );
      this.selection.select(...nonInputRows);
      this.selectAllChecked = true;
    }
    this.emitCreateSelectionChange();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  public isAllSelected() {
    const nonInputRows = this.crudTableDataSource.data.filter(
      (row: any) => !row.isInputRow
    );
    return (
      this.selection.selected.length === nonInputRows.length &&
      nonInputRows.length > 0
    );
  }

  // for table body checkbox selection
  public checked(row: any) {
    this.selection.select(row);
    this.checkForCreateModeId(row);
    const found = this.selection.selected.find(
      x => x[this.selectedRowModuleId] == row[this.selectedRowModuleId]
    );
    if (found) {
      found.checked = true;
    }
  }

  public unchecked(row: any) {
    this.checkForCreateModeId(row);
    const found = this.selection.selected.find(
      x => x[this.selectedRowModuleId] == row[this.selectedRowModuleId]
    );
    if (found) {
      found.checked = false;
      this.selection.deselect(found);
    }
  }

  public isChecked(row: any): boolean {
    this.checkForCreateModeId(row);
    const found = this.selection.selected.find(
      el => el[this.selectedRowModuleId] === row[this.selectedRowModuleId]
    );
    if (found) {
      return true;
    }
    return false;
  }

  public checkForEnableOrDisable() {
    return (
      (this.isFrom !== pageType.shipmentCommodity &&
        this.crudTableDataSource.data.length <= 0) ||
      (this.isFrom === pageType.shipmentCommodity &&
        this.crudTableDataSource.data.length <= 1 &&
        this.crudTableDataSource.data[0]?.isInputRow)
    );
  }

  public emitCreateSelectionChange() {
    const selectedRows = this.selection.selected;
    this.selectionTableChange.emit(selectedRows);
  }

  public onBlurCrudTableInput() {
    if (
      this.commonService.isValidField(
        this.crudTableForm.get(TableControls.serviceType)?.value
      )
    ) {
      const serviceTypeId = this.getServiceTypeName(
        this.crudTableForm.get(TableControls.serviceType)?.value
      );
      this.crudTableForm.get('serviceTypeId')?.setValue(serviceTypeId);
    }
    this.crudTableFormValue.emit(this.crudTableForm);
  }

  public basicInputOnChanges() {
    if (this.isFrom === pageType.shipmentCommodity) {
      this.setOrRemoveValidationForCommodity();
    }
  }

  public fetchWidth(event: MouseEvent, fieldName: string) {
    this.commonService.setErrorTooltipData(
      event,
      fieldName,
      this.setCrudTableWidth,
      this.setCrudTableTooltipEvent,
      this.setCrudTableErrorTooltip
    );
  }

  public datePickerclose(controlName: string) {
    this.crudTableForm.get(controlName)?.addValidators(Validators.required);
    this.crudTableForm.get(controlName)?.updateValueAndValidity();
  }

  public getAutocompleteFields(key: string) {
    return AutocompleteControls.indexOf(key) > -1;
  }

  public getValueField(key: string) {
    return ValueControls.indexOf(key) > -1;
  }

  public getInputFormControl(key: string) {
    return (
      key !== TableControls.airlineCode &&
      key !== TableControls.arrivalDate &&
      key !== TableControls.dimLWH &&
      key !== TableControls.packageType &&
      key !== TableControls.dateTimeAndTimezone &&
      key !== TableControls.codeAndDescription &&
      key !== TableControls.serviceType &&
      key !== TableControls.rateScale &&
      key !== TableControls.fuelSurcharge
    );
  }

  public getBasicInputControl(key: string) {
    return InputWithoutValidationKeys.indexOf(key) > -1;
  }

  public getInputControlWithAlphaNumbers(key: string) {
    return InputWithAlphaNumbers.indexOf(key) > -1;
  }

  public getInputControlWithAlphaNumbersHyphen(key: string) {
    return InputWithAlphaNumbersHyphen.indexOf(key) > -1;
  }

  public getInputControlWithDecimal(key: string) {
    return InputWithDecimalValidationKeys.indexOf(key) > -1;
  }

  public getInputControlWithNumbers(key: string) {
    return InputWithNumbers.indexOf(key) > -1;
  }

  public getDiscountWithDecimal(key: string) {
    return InputWithTwoDecimal.indexOf(key) > -1;
  }

  public navigateToInformationPage(
    element: any,
    index: number,
    mode: any,
    isFromComp: boolean
  ) {
    const data = {
      element: element,
      index: index,
      mode: mode,
      isFromComp: isFromComp,
      isFrom: this.isFrom,
    };
    this.navigateData.emit(data);
  }

  private createForm() {
    const { form, errorMessages } = this.formUtilsService.generateForm(
      this.formConfig
    );
    this.crudTableForm = form;
    this.errorMessages = errorMessages;
    this.crudTableValidation();
  }

  public crudTableValidation() {
    if (this.isFrom === pageType.shipmentCommodity) {
      this.crudTableForm.reset();
      this.crudTableForm.get('value')?.disable();
      this.crudTableFormValue.emit(this.crudTableForm);
    }
  }

  public getErrorMessage(controlName: string) {
    if (this.crudTableForm.get(controlName)?.errors?.['autocompleteError']) {
      return validationErrorMessages.autocompleteError;
    } else if (this.crudTableForm.get(controlName)?.errors?.['invalidFlight']) {
      return validationErrorMessages.invalidFlight;
    } else {
      return this.formUtilsService.getErrorMessage(
        this.crudTableForm,
        this.errorMessages,
        controlName
      );
    }
  }

  public onAutocompleteOptionSelected(controlName: string) {
    if (controlName === TableControls.codeAndDescription) {
      this.statusCodeSelected = true;
      this.onOptionSelectedFormChanges('packagingTypeName');
    } else if (controlName === TableControls.serviceType) {
      this.serviceTypeSelected = true;
      this.onOptionSelectedFormChanges('serviceType');
    } else if (controlName === TableControls.rateScale) {
      this.onOptionSelectedFormChanges('rateScale');
    } else if (controlName === TableControls.fuelSurcharge) {
      this.onOptionSelectedFormChanges('fuelSurcharge');
    }
  }

  public getAutocompleteOptionsList(key: string) {
    if (key === this.formFields.airlineCode) {
      return this.filteredAirlineTypeOptions;
    } else if (key === this.formFields.codeAndDescription) {
      return this.filteredStatusCodesTypeOptions;
    } else if (key === this.formFields.serviceType) {
      return this.filteredserviceTypeOptions;
    } else if (key === this.formFields.rateScale) {
      return this.filteredrateScaleOptions;
    } else if (key === this.formFields.fuelSurcharge) {
      return this.filteredfuelSurchargeOptions;
    }
  }

  public detectHyperlink(key: string): boolean {
    const validKeys = [formFieldIds.attachments, formFieldIds.addtionalDetails];
    return validKeys.includes(key);
  }

  public handleHyperlinkClickEvent(key: string) {
    const mapFieldKey = {
      [formFieldIds.attachments]: () => {
        this.triggerCrudPopup(
          ModalPopupMessages.attachmentsHeader,
          popupWidth.deletePopup,
          StatuscodeAttachmentsComponent,
          {
            showCrudHeaderOptions: true,
            isStatusCodeAttachmentDetails: true,
          }
        );
      },
      [formFieldIds.addtionalDetails]: () => {
        this.triggerCrudPopup(
          ModalPopupMessages.additionalDetailsHeader,
          popupWidth.downloadPopup,
          StatuscodeAdditionalDetailsComponent,
          {
            showModalCloseOption: true,
            isStatusCodeAdditionalOrPodDetails: true,
          }
        );
      },
    };
    mapFieldKey[key]();
  }

  private triggerCrudPopup(
    modalHeader: string,
    popupWidth: string,
    injectedComponent: any,
    config?: object
  ) {
    this.modalService.openPopup(
      { ...config, isFooterRequired: true },
      modalHeader,
      CrudPopupComponent,
      popupWidth,
      injectedComponent
    );
  }

  private setRequiredInitilization() {
    this.customiseCrudTableColumns = this.crudTableCustomizeConfig; // set columns config
    this.tableDisplayedColumns = this.tableService.getDefaultDisplayColumns(
      this.isFrom
    ); // set headers for table
    this.setTableRowIds(false);
  }

  private setTableRowIds(idParam: boolean) {
    if (this.isFrom === selectionTableNames.MawbFlight) {
      this.selectedRowModuleId = idParam
        ? selectedIdForSummaryTable.Id
        : selectedIdForSummaryTable.FlightId;
    } else if (this.isFrom === selectionTableNames.AgentFacility) {
      this.selectedRowModuleId = selectedIdForSummaryTable.Agent;
    } else if (this.isFrom === pageType.shipmentCommodity) {
      this.selectedRowModuleId = selectedIdForSummaryTable.Commodity;
    } else if (this.isFrom === pageType.customerService) {
      this.selectedRowModuleId = idParam
        ? selectedIdForSummaryTable.tempId
        : selectedIdForSummaryTable.Id;
    }
  }

  private checkForCreateModeId(row: any) {
    if (
      (this.isFrom === selectionTableNames.MawbFlight && !row.flightId) ||
      (this.isFrom === selectionTableNames.customerService && !row.id)
    ) {
      this.setTableRowIds(true);
    }
  }

  private fetchDataOfOtherScreens() {
    this.commonService.clearSelectionInputValue
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: any) => {
        if (data === ActionIcons.reset) {
          this.crudTableForm.reset();
          this.selection.clear();
          this.selectAllChecked = false;
          setTimeout(() => {
            this.commonService.clearInputValue('');
          });
        }
      });
  }

  private fetchDataOfOtherScreensandPatch() {
    this.commonService.formPatchback
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: any) => {
        if (data) {
          this.crudTableForm.patchValue({
            airlineCode: data.airlineCode ?? '',
            airlineId: data.airlineId ?? '',
            flightNumber: data.flightNumber ?? '',
            slacCount: data.slacCount ?? '',
            slacWeight: data.slacWeight ?? '',
            arrivalDate: data.arrivalDate ?? '',
            mawbPart: this.crudTableForm.controls['mawbPart'].getRawValue(),
            isInputRow: false,
            action: data.action ?? CrudActions.add,
            source: data.source ?? CrudActions.ui,
            id: data.id ?? '',
            alreadyaddedAPI: data.alreadyaddedAPI ?? false,
          });
        }
      });
  }

  private getMasterLookupType(lookupKey: any) {
    this.commonService
      .getMasterLookUp(lookupKey)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data: any) => {
          if (data.statusCode == StatusCodes.Success) {
            if (lookupKey === MasterLookup.airlineCode) {
              this.airlineField = data.data;
              this.airlineField.forEach((airline: any) => {
                airline.name = `${airline.code}-${airline.name}`;
              });
              this.filteredAirlineTypeOptions = this.autocompleteValueChanges(
                TableControls.airlineCode,
                this.airlineField,
                'name'
              );
            } else if (lookupKey === MasterLookup.statusCode) {
              this.statusCodesField = data.data;
              this.statusCodesField.forEach((statusCode: any) => {
                statusCode.name = `${statusCode.id} - ${statusCode.name}`;
              });
              this.filteredStatusCodesTypeOptions =
                this.autocompleteValueChanges(
                  TableControls.codeAndDescription,
                  this.statusCodesField,
                  'name'
                );
            } else if (lookupKey === MasterLookup.serviceType) {
              this.serviceTypeData = data.data;
              this.filteredserviceTypeOptions = this.autocompleteValueChanges(
                TableControls.serviceType,
                this.serviceTypeData,
                'id'
              );
            } else if (lookupKey === MasterLookup.rateScale) {
              this.rateScaleData = data.data;
              this.filteredrateScaleOptions = this.autocompleteValueChanges(
                TableControls.rateScale,
                this.rateScaleData,
                'name'
              );
            } else if (lookupKey === MasterLookup.fuelSurcharge) {
              this.fuelSurchargeData = data.data;
              this.filteredfuelSurchargeOptions = this.autocompleteValueChanges(
                TableControls.fuelSurcharge,
                this.fuelSurchargeData,
                'name'
              );
            }
            this.checkForCrudTablePageSidebarExpanded();
          } else {
            this.errorHandlerService.handleApiError(data);
          }
        },
        error: (error: HttpErrorResponse) => {
          this.errorHandlerService.handleError(error);
        },
      });
  }

  public formAutocompleteValueChanges(controlName: string) {
    if (controlName === TableControls.serviceType) {
      this.commonService.getAutocompleteDropdownIdParam(
        this.serviceTypeData,
        this.crudTableForm,
        controlName,
        ''
      );
    } else {
      this.commonService.getAutocompleteDropdownId(
        this.getDropdownResponseList(controlName),
        this.crudTableForm,
        controlName,
        this.getApiControlNames(controlName)
      );
    }
    this.onBlurCrudTableInput();
  }

  public onOptionSelectedFormChanges(controlName: string) {
    if (controlName === TableControls.serviceType) {
      this.commonService.getAutocompleteDropdownIdParam(
        this.serviceTypeData,
        this.crudTableForm,
        controlName,
        ''
      );
    } else {
      this.commonService.getAutocompleteDropdownBinding(
        this.getDropdownResponseList(controlName),
        this.crudTableForm,
        controlName,
        this.getApiControlNames(controlName),
        validationErrorMessages.name
      );
    }
    this.onBlurCrudTableInput();
  }

  private getDropdownResponseList(control: string) {
    const dropdownList = {
      [TableControls.airlineCode]: this.airlineField,
      [TableControls.serviceType]: this.serviceTypeData,
      [TableControls.codeAndDescription]: this.statusCodesField,
      [TableControls.rateScale]: this.rateScaleData,
      [TableControls.fuelSurcharge]: this.fuelSurchargeData,
    };
    return dropdownList[control] || [];
  }

  private getApiControlNames(control: string) {
    const apiControlName = {
      [TableControls.airlineCode]: ApiControls.airlineCode,
      [TableControls.serviceType]: ApiControls.serviceType,
      [TableControls.codeAndDescription]: ApiControls.codeAndDescription,
      [TableControls.rateScale]: ApiControls.rateScale,
      [TableControls.fuelSurcharge]: ApiControls.fuelSurcharge,
    };
    return apiControlName[control] || '';
  }

  private autocompleteValueChanges(
    controlName: string,
    dropdownData: IDropdownResponse[],
    param: string
  ) {
    return this.crudTableForm.get(controlName)?.valueChanges.pipe(
      startWith(''),
      map(
        val =>
          dropdownData?.filter(
            (option: any) =>
              option[param]?.toLowerCase().includes(val?.toLowerCase() || '')
          )
      )
    );
  }

  private checkForCrudTablePageSidebarExpanded() {
    this.commonService.isMenuExpanded.subscribe(() => {
      this.setCrudTableWidth = this.commonService.setModifiedTooltipWidth(
        this.setCrudTableTooltipEvent,
        this.setCrudTableErrorTooltip,
        this.setCrudTableWidth
      );
    });
  }

  private setOrRemoveValidationForCommodity() {
    if (this.crudTableForm.get('hsCode')?.value) {
      this.crudTableForm.get('value')?.enable();
      this.commonService.setControlRequiredValidation(
        this.crudTableForm,
        'value'
      );
    } else {
      this.crudTableForm.get('value')?.markAsUntouched();
      this.crudTableForm.get('value')?.disable();
      this.commonService.removeControlRequiredValidation(
        this.crudTableForm,
        'value'
      );
      this.crudTableForm.get('value')?.setValue('');
    }
  }

  public getFlightFormValue() {
    return this.crudTableForm;
  }

  private getServiceTypeName(selectedData: any) {
    const selectedServiceType = this.serviceTypeData.filter(
      (type: any) => type.id === selectedData
    );
    return selectedServiceType[0].name;
  }
}
