import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormField } from 'src/app/shared/models/dynamic.model';
import { ValidatorService } from 'src/app/shared/services/form-control-validators.service';
import { businessInformationFormFields } from '../../accounts.config';
import { CommonService } from 'src/app/shared/services/common.service';
import {
  ModeIcons,
  pageType,
  validationErrorMessages,
} from 'src/app/shared/utility/constants';
import { Store } from '@ngxs/store';
import { SaveBusinessDetails } from '../../store/accounts.action';
import { Observable, Subject, takeUntil } from 'rxjs';
import { IBusinessInfoDetailsData } from 'src/app/features/model/sm-accounts';

@Component({
  selector: 'app-business-information',
  templateUrl: './business-information.component.html',
  styleUrl: './business-information.component.scss',
})
export class BusinessInformationComponent implements OnInit, OnDestroy {
  public accountBusinessInfoForm!: FormGroup;
  public formFields!: FormField[];
  private errorMessages: any;
  public setBusinessInfoWidth: any = {};
  public setBusinessInfoErrorTooltip: any = {};
  public setBusinessInfoTooltipEvent: any = {};
  public businessInfoTabData$!: Observable<IBusinessInfoDetailsData>;
  public businessInfoTabFormData: any;
  public pageMode!: string;

  private readonly destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private readonly formUtilsService: ValidatorService,
    public readonly commonService: CommonService,
    private readonly fromUtilsService: ValidatorService,
    private readonly store: Store
  ) {
    this.setReqInit();
    this.businessInfoTabData$ = this.store.select(
      state => state.accountsBusinessInfo
    );
  }

  ngOnInit() {
    this.dataFetch();
    this.checkMode();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  private createForm() {
    const { form, errorMessages } = this.formUtilsService.generateForm(
      businessInformationFormFields
    );
    this.accountBusinessInfoForm = form;
    this.errorMessages = errorMessages;
  }

  private setReqInit() {
    this.createForm();
    this.formFields = businessInformationFormFields.fields;
  }

  public checkControlForError(controlName: string) {
    return this.commonService.checkControlError(
      this.accountBusinessInfoForm,
      controlName
    );
  }

  public fetchWidth(event: MouseEvent, controlName: string) {
    this.commonService.setErrorTooltipData(
      event,
      controlName,
      this.setBusinessInfoWidth,
      this.setBusinessInfoTooltipEvent,
      this.setBusinessInfoErrorTooltip
    );
  }

  public fetchErrorMessages(controlName: string): string {
    if (
      this.accountBusinessInfoForm.get(controlName)?.errors?.[
        'autocompleteError'
      ]
    ) {
      return validationErrorMessages.autocompleteError;
    } else {
      return this.fromUtilsService.getErrorMessage(
        this.accountBusinessInfoForm,
        this.errorMessages,
        controlName
      );
    }
  }

  public dipatchBusinessFormChanges() {
    this.store.dispatch(
      new SaveBusinessDetails(this.accountBusinessInfoForm.value)
    );
    this.commonService.checkPristineAccordionData(false);
  }

  private checkMode() {
    this.commonService.isCheckForMode
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: any) => {
        if (data === ModeIcons.view) {
          this.pageMode = ModeIcons.view;
          this.accountBusinessInfoForm.disable();
        } else {
          this.pageMode = data != '' ? data : ModeIcons.add;
          this.accountBusinessInfoForm.enable();
        }
      });
  }

  private dataFetch() {
    this.commonService.savedBagData
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: any) => {
        if (data === pageType.dataBind) {
          this.fetchBusinessInfoTabData();
        }
      });
  }

  private fetchBusinessInfoTabData() {
    this.businessInfoTabData$
      .pipe(takeUntil(this.destroy$))
      .subscribe(businessInfoData => {
        this.businessInfoTabFormData = businessInfoData;
        this.accountBusinessInfoForm.patchValue(this.businessInfoTabFormData);
      });
  }
}
