import { Component, OnDestroy } from '@angular/core';
import { DataSourceItem, TableColumnsProp } from '../../models/tables.model';
import { CommonTableService } from '../../services/common-table.service';
import {
  dateTimeFormat,
  ModalPopupMessages,
  popupButtons,
} from '../../utility/constants';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../material.module';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'app-summary-page-print',
  templateUrl: './summary-page-print.component.html',
  styleUrl: './summary-page-print.component.scss',
  standalone: true,
  imports: [CommonModule, MaterialModule],
})
export class SummaryPagePrintComponent implements OnDestroy {
  public dataSource: DataSourceItem[] = [];
  public displayedColumns!: string[];
  public summaryCustomiseTableColumns!: TableColumnsProp[];
  public isFrom!: string;
  public dateFormat = dateTimeFormat.onlyDate;
  public pageSize = '';
  public popupButtons = popupButtons;
  public isForprint = false;
  public isPrintClicked = false;
  public isPrintWarning = false;
  public warningMessage = ModalPopupMessages.printWarningContent;

  constructor(
    public commonTableService: CommonTableService,
    public commonService: CommonService
  ) {
    window.addEventListener('message', this.messageHandler);
  }

  ngOnDestroy() {
    window.removeEventListener('message', this.messageHandler);
  }

  messageHandler = (event: any) => {
    if (event.origin !== window.location.origin) return;
    if (Object.keys(event.data).length) {
      this.pageSize = event.data.selectedPageSize;
      this.displayedColumns = event.data.displayColumns;
      this.summaryCustomiseTableColumns = event.data.customizeColumns;
      this.dataSource = event.data.selectedData;
      this.isForprint = event.data.isPrintDialogRequired;
      this.isPrintWarning = event.data.isPreviewWarning;
      if (this.isForprint) {
        this.print();
      }
    }
  };

  public print() {
    this.isPrintClicked = true;
    window.onafterprint = () => {
      window.close();
    };
    setTimeout(() => {
      window.print();
    }, 0);
  }
}
