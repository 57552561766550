import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ModalService } from 'src/app/shared/services/modal.service';
import {
  HamburgerRecentListActions,
  OverlayIcons,
} from 'src/app/shared/utility/svg-constants';
import {
  ActionIcons,
  ModalPopupMessages,
  StatusCodes,
} from 'src/app/shared/utility/constants';
import { OverlayButtons } from '../../search.config';
import { RecentSavedSearchPopupComponent } from '../recent-saved-search-popup/recent-saved-search-popup.component';
import { HttpErrorResponse } from '@angular/common/http';
import { Subject, takeUntil } from 'rxjs';
import { ErrorHandlerService } from 'src/app/core/services/error-handler.service';
import { SearchService } from '../../search.service';

@Component({
  selector: 'app-search-options-overlay',
  templateUrl: './search-options-overlay.component.html',
  styleUrl: './search-options-overlay.component.scss',
})
export class SearchOptionsOverlayComponent implements OnInit, OnDestroy {
  @Input() isFrom!: string;
  @Output() hyperlinkClicked = new EventEmitter<boolean>();

  public overlayIcons = OverlayIcons;
  public overlayButtons = OverlayButtons;
  public recentSearchList: any;
  public requiredRecentSearches: any;
  public pinnedSearchList: any;
  public savedSearchTableActionIcons = JSON.parse(
    JSON.stringify(HamburgerRecentListActions)
  );
  public searchFieldValue!: string;
  public recentListPinIcon = ActionIcons.hamburgerRecentListPin;

  private readonly destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public searchService: SearchService,
    private readonly modalService: ModalService,
    private readonly errorHandlerService: ErrorHandlerService
  ) {}

  ngOnInit(): void {
    this.getRecentAndPinnedSearches();
    this.getDataForButtonEnableDisable();
    this.loadRecentSearchBasedOnAction();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  public openRecentAndSavedSearchPopup(isSavedSearch: boolean) {
    const dataForPopup = {
      isFromSavedPopup: isSavedSearch,
      tableData: !isSavedSearch ? this.recentSearchList : [],
      isFrom: this.isFrom,
    };
    this.modalService.openPopup(
      dataForPopup,
      isSavedSearch
        ? ModalPopupMessages.savedSearchesHeader
        : ModalPopupMessages.recentSearchesHeader,
      RecentSavedSearchPopupComponent,
      '900px'
    );
  }

  public onhyperlinkClicked(clickedItem: any) {
    this.hyperlinkClicked.emit(true);
    this.searchService.setClickedHyperlinkData({
      item: clickedItem,
      isOpenOverlay: false,
    });
  }

  public onActionClicked(clickedIcon: any, rowData: any) {
    if (clickedIcon.name === ActionIcons.hamburgerRecentListPin) {
      this.searchService.preparePayloadForPinOrUnpin(rowData, this.isFrom);
      setTimeout(() => {
        this.getRecentAndPinnedSearches();
      }, 700);
    } else if (clickedIcon.name === ActionIcons.hamburgerRecentListSave) {
      this.searchService.openSaveAsNewSearchPopup(
        false,
        this.isFrom,
        '',
        rowData
      );
    } else if (clickedIcon.name === ActionIcons.hamburgerRecentListDelete) {
      this.searchService.deleteSearchPopup(rowData, this.isFrom, true);
    }
  }

  private getRecentAndPinnedSearches(isReload?: boolean) {
    this.searchService
      .getRecentAndPinnedSearchList({ screenTag: this.isFrom })
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (result: any) => {
          if (result.statusCode == StatusCodes.Success) {
            this.recentSearchList = result.data?.isRecent;
            this.requiredRecentSearches = this.recentSearchList.slice(0, 10);
            this.pinnedSearchList = result.data?.isPinned;
            if (isReload) {
              this.searchService.setRecentGridReloadData(this.recentSearchList);
            }
          } else {
            this.errorHandlerService.handleApiError(result);
          }
        },
        error: (error: HttpErrorResponse) => {
          this.errorHandlerService.handleError(error);
        },
      });
  }

  private getDataForButtonEnableDisable() {
    this.searchService.isHamburgerSaveNewButtonData.subscribe(fieldData => {
      this.searchFieldValue = fieldData;
    });
  }

  private loadRecentSearchBasedOnAction() {
    this.searchService.isActionSuccessful.subscribe(action => {
      if (action) {
        this.getRecentAndPinnedSearches(true);
        this.searchService.setActionSuccessfulData(false);
      }
    });
  }
}
