import { Directive, HostListener, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appNumbersWithHyphen]',
})
export class AppNumbersWithHyphenDirective {
  private isFormatting = true; // Flag to manage formatting
  @Input() formatAsFein: boolean = false;
  constructor(private readonly el: ElementRef) {}
  
  @HostListener('input', ['$event'])
  onInput(): void {
    const input = this.el.nativeElement as HTMLInputElement;
    const cursorPosition = input.selectionStart ?? 0;
    const rawValue = input.value.replace(/\D/g, '');
    this.el.nativeElement.value = rawValue;
    // Restore cursor position
    setTimeout(() => {
      input.setSelectionRange(cursorPosition, cursorPosition);
    }, 0);
  }

  @HostListener('blur', ['$event'])
  onBlur(): void {
    const input = this.el.nativeElement as HTMLInputElement;
    const rawValue = input.value.replace(/\D/g, '');
    if (this.formatAsFein) {
      const formattedValue = this.formatFeinValue(rawValue);
      input.value = formattedValue;
    } else {
      const formattedValue = this.formatValue(rawValue);
      input.value = formattedValue;
    }
    this.isFormatting = true;
  }

  @HostListener('focus', ['$event'])
  onFocus(): void {
    const input = this.el.nativeElement as HTMLInputElement;
    if (this.isFormatting) {
      // Remove hyphen on focus
      const rawValue = input.value.replace(/-/g, ''); // Remove hyphen
      input.value = rawValue;
      // Ensure the cursor is set to the end of the input
      setTimeout(() => {
        input.setSelectionRange(rawValue.length, rawValue.length);
      }, 0);
    }
    this.isFormatting = false;
  }

  private formatValue(rawValue: string): string {
    let formattedValue = '';
    if (rawValue.length > 3) {
      formattedValue = rawValue.slice(0, 3) + '-' + rawValue.slice(3);
    } else {
      formattedValue = rawValue;
    }
    // Ensure the length does not exceed 12 characters
    if (formattedValue.length > 12) {
      formattedValue = formattedValue.slice(0, 12);
    }
    return formattedValue;
  }

  private formatFeinValue(rawValue: string): string {
    let formattedValue = '';
  
    if (rawValue.length === 9) {
      formattedValue = rawValue.slice(0, 3) + '-' + rawValue.slice(3, 5) + '-' + rawValue.slice(5, 9);
    } else if (rawValue.length > 5) {
      formattedValue = rawValue.slice(0, 3) + '-' + rawValue.slice(3, 5) + '-' + rawValue.slice(5);
    } else if (rawValue.length > 2) {
      formattedValue = rawValue.slice(0, 3) + '-' + rawValue.slice(3);
    } else {
      formattedValue = rawValue;
    }
    return formattedValue;
  }
  
}
