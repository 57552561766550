import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';
import { BaseService } from 'src/app/core/services/base.httpService';
import { MessagePopupComponent } from 'src/app/shared/components/message-popup/message-popup.component';
import { ModalService } from 'src/app/shared/services/modal.service';
import {
  ActionIcons,
  HttpSignatures,
  rolesOptionalFields,
} from 'src/app/shared/utility/constants';
import { URLConstants } from 'src/app/shared/utility/url-constants';

@Injectable({
  providedIn: 'root',
})
export class HawbService implements OnDestroy {
  public isCheckForMode = new BehaviorSubject<any>([]);
  public commodityIdForPage = new BehaviorSubject<number>(0);
  public clickedIcon = new BehaviorSubject<any>([]);
  public commodityData = new BehaviorSubject<any>([]);
  public commoditySelectedData = new BehaviorSubject<any>([]);
  public checkForRolesData = new BehaviorSubject<any>([]);
  public checkForRolesValidData = new BehaviorSubject<any>(false);
  public checkForShipmentStatusData = new BehaviorSubject<boolean>(false);
  public paginationData = new BehaviorSubject<any>({});
  private readonly destroy$ = new Subject<void>();
  public paginationData$ = this.paginationData
    .asObservable()
    .pipe(takeUntil(this.destroy$));
  public mockFlag = false;
  public selectedIdsForBulkEdit = new BehaviorSubject<any>([]);
  public commodityListApiResponse = new BehaviorSubject<any>([]);
  public isPreviousPageSummary = new BehaviorSubject<any>('');
  public deletedApiCommodities = new BehaviorSubject<any>([]);
  public isHawbPrevSummary = new BehaviorSubject<any>('');
  public checkForConsigneeAndShipmentData = new BehaviorSubject<any>([]);
  public checkForAPIHits = new BehaviorSubject<any>(false);
  public checkDeleteRolesData = new BehaviorSubject<any>([]);
  public isCreatedLinkedData = new BehaviorSubject<any>('');
  public isHawbSummaryApiData = new BehaviorSubject<any>(false);

  constructor(
    public baseService: BaseService,
    private readonly http: HttpClient,
    private readonly modalService: ModalService
  ) {}

  public setData(data: any): void {
    this.paginationData.next(data);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public getSearchData(event: any): Observable<any> {
    return this.baseService.initiateApiRequest(
      'POST',
      URLConstants.getHawbList,
      event,
      '',
      'json'
    );
  }

  public getHawbByHawbId(hawbId: number): Observable<any> {
    const apiUrl = `${URLConstants.getHawbById}/hawbIds/${hawbId}`;
    return this.baseService.initiateApiRequest(
      'GET',
      apiUrl,
      '',
      'json'
    );
  }

  public deleteHawbs(event: any): Observable<any> {
    return this.baseService.initiateApiRequest(
      'POST',
      URLConstants.deleteHawb,
      event,
      '',
      'json'
    );
  }

  public setCommodityIdForPage(data: number) {
    this.commodityIdForPage.next(data);
  }

  public setMode(data: any) {
    this.isCheckForMode.next(data);
  }

  public updateClickedIcon(icon: string) {
    this.clickedIcon.next(icon);
  }

  public updateCommodityData(data: any) {
    this.commodityData.next(data);
  }

  public commodityDetails(data: any) {
    this.commoditySelectedData.next(data);
  }

  public checkRoleData(data: any) {
    this.checkForRolesData.next(data);
  }

  public CheckValidData(data: any) {
    this.checkForRolesValidData.next(data);
  }

  public CheckShipmentStatusData(data: boolean) {
    this.checkForShipmentStatusData.next(data);
  }

  public checkAccountDetails(): Observable<any> {
    return this.baseService.initiateApiRequest(
      'GET',
      URLConstants.getAccountDetails,
      '',
      'json'
    );
  }

  public checkCustomerDetails(data: string): Observable<any> {
    return this.baseService.initiateApiRequest(
      'GET',
      URLConstants.getCustomerDetails + data,
      '',
      'json'
    );
  }

  public saveHawb(data: any): Observable<any> {
    return this.baseService.initiateApiRequest(
      'PUT',
      URLConstants.saveHawbInformation,
      data
    );
  }

  public updateHawb(data: any): Observable<any> {
    return this.baseService.initiateApiRequest(
      'POST',
      URLConstants.updateHawbInformation,
      data,
      'json'
    );
  }

  public getHawbData(data: any): Observable<any> {
    return this.baseService.initiateApiRequest(
      'GET',
      URLConstants.getHawbDataById + data,
      '',
      'json'
    );
  }

  public getMasterHawbData(data: any): Observable<any> {
    return this.baseService.initiateApiRequest(
      'POST',
      URLConstants.getMasterHawbData,
      data,
      'json'
    );
  }

  public blockUnblockHawbData(data: any): Observable<any> {
    return this.baseService.initiateApiRequest(
      HttpSignatures['Post'],
      URLConstants.blockUnblockHawb,
      data,
      'json'
    );
  }

  public AddHawbNotesData(data: any): Observable<any> {
    return this.baseService.initiateApiRequest(
      HttpSignatures['Post'],
      URLConstants.addHawbNotes,
      data,
      'json'
    );
  }

  public createErrorPopupData(
    message: string,
    title: string,
    isFooterRequired: boolean
  ) {
    this.modalService.openPopup(
      {
        message: message,
        isFooterRequired: isFooterRequired,
      },
      title,
      MessagePopupComponent,
      '360px'
    );
  }

  public setBulkEditIds(data: any) {
    this.selectedIdsForBulkEdit.next(data);
  }

  public setCommodityListDataFromResponse(data: any) {
    this.commodityListApiResponse.next(data);
  }

  public isSummaryPage(data: string) {
    this.isPreviousPageSummary.next(data);
  }

  public setDeletedApiCommodities(data: any) {
    this.deletedApiCommodities.next(data);
  }

  public deleteCommodity(event: any): Observable<any> {
    return this.baseService.initiateApiRequest(
      HttpSignatures['Post'],
      URLConstants.getDeleteCommodityDataById,
      event,
      '',
      'json'
    );
  }

  public saveCommodity(event: any): Observable<any> {
    return this.baseService.initiateApiRequest(
      HttpSignatures['Post'],
      URLConstants.getSaveCommodityDataById,
      event,
      '',
      'json'
    );
  }

  public updateCommodity(event: any): Observable<any> {
    return this.baseService.initiateApiRequest(
      HttpSignatures['Post'],
      URLConstants.getUpdateCommodityDataById,
      event,
      '',
      'json'
    );
  }

  public addRoles(event: any): Observable<any> {
    return this.baseService.initiateApiRequest(
      HttpSignatures['Post'],
      URLConstants.addRoles,
      event,
      '',
      'json'
    );
  }

  public isHawbSummaryPrevSearch(data: string) {
    this.isHawbPrevSummary.next(data);
  }

  public checkConsigneeAndShipmentData(data: any) {
    this.checkForConsigneeAndShipmentData.next(data);
  }

  public apicallAfterChildEvents(data: any) {
    this.checkForAPIHits.next(data);
  }

  public ishawbSummaryApiData(data: boolean) {
    this.isHawbSummaryApiData.next(data);
  }

  public setDeletedRolesData(data: any) {
    this.checkDeleteRolesData.next(data);
  }

  public getHawbBulkEditUniqueData(hawbIds: string): Observable<any> {
    const url = URLConstants.uniqueHawbDataBulkEdit + hawbIds;
    return this.baseService.initiateApiRequest('GET', url, '', 'json');
  }

  public updateBulkHawb(data: any): Observable<any> {
    const url = URLConstants.updateBulkHawb;
    return this.baseService.initiateApiRequest('POST', url, data, 'json');
  }

  public advanceHawb(data: any): Observable<any> {
    return this.baseService.initiateApiRequest(
      'POST',
      URLConstants.advanceHawbInformation,
      data,
      'json'
    );
  }

  public bulkBaggingConditionsCheck(selectedData: any) {
    return this.baseService.initiateApiRequest(
      HttpSignatures['Post'],
      URLConstants.bulkBaggingPieces,
      selectedData,
      'json'
    );
  }

  public bulkGatewayingConditionsCheck(selectedData: any) {
    return this.baseService.initiateApiRequest(
      HttpSignatures['Post'],
      URLConstants.bulkGatewayValidate,
      selectedData,
      'json'
    );
  }

  public bulkGatewayShipments(bulkBagData: any) {
    return this.baseService.initiateApiRequest(
      HttpSignatures['Post'],
      URLConstants.BulkGatewayShipments,
      bulkBagData,
      'json'
    );
  }

  public getModifiedDataForCommodityRoles(commodityRoleData: any) {
    const propertiesToCheck = rolesOptionalFields;
    propertiesToCheck.forEach(property => {
      commodityRoleData.forEach((role: any) => {
        role.id = role.action === ActionIcons.add ? 1 : role.id;
        if (!role[property]) {
          delete role['isFromScreen'];
          delete role[property];
          delete role['commodityId'];
        }
        if (!role['zipCode']) {
          delete role.zipCode;
        }
      });
    });
  }

  public getShipmentGatewayValidation(event: any): Observable<any> {
    return this.baseService.initiateApiRequest(
      HttpSignatures['Post'],
      URLConstants.validateGateway,
      event,
      'json'
    );
  }

  public gatewayedShipment(event: any): Observable<any> {
    return this.baseService.initiateApiRequest(
      HttpSignatures['Post'],
      URLConstants.gatewayShipment,
      event,
      '',
      'json'
    );
  }

  public setLinkedCriteriaData(data: any) {
    this.isCreatedLinkedData.next(data);
  }
}
