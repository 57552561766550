import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnInit,
} from '@angular/core';
import { ValidatorPattern } from '../utility/validators.pattern';

@Directive({
  selector: '[appAlphabetsWithNumbers]',
})
export class AppAlphabetsWithNumbersDirective implements OnInit {
  @Input() appAlphabetsWithNumbers!: number;
  private readonly allowNumbers: number = 4;
  private regexPattern!: RegExp;
  private readonly specialKeys: Array<string> = [
    'Backspace',
    'Tab',
    'End',
    'Home',
    'ArrowLeft',
    'ArrowRight',
    'Control',
  ];

  constructor(private readonly el: ElementRef) {}

  ngOnInit() {
    this.updatePattern();
  }

  private updatePattern() {
    // Set pattern for two alphabets followed by 1 to 4 digits
    if (this.appAlphabetsWithNumbers === 2) {
      this.regexPattern = new RegExp(ValidatorPattern.flightValidation);
    }
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const input = this.el.nativeElement;
    const current: string = input.value;
    const cursorPos = input.selectionStart;
    if (
      cursorPos === null ||
      this.specialKeys.includes(event.key) ||
      (event.ctrlKey && (event.key === 'a' || event.key === 'A'))
    ) {
      return;
    }

    // Block any special character that is not allowed
    if (!/[A-Za-z0-9]/.test(event.key)) {
      event.preventDefault();
      return;
    }

    this.checkForValidation(event, cursorPos, current);
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    const clipboardData = event.clipboardData;
    const pastedText = clipboardData?.getData('text');
    const input = this.el.nativeElement;
    const current: string = input.value;
    const cursorPos = input.selectionStart;
    if (cursorPos === null || !pastedText) {
      return;
    }
    const next: string = [
      current.slice(0, cursorPos),
      pastedText,
      current.slice(cursorPos),
    ].join('');
    if (!this.isValid(next)) {
      event.preventDefault();
    }
  }

  private isValid(value: string): boolean {
    const alphabets = value
      .substring(0, this.appAlphabetsWithNumbers)
      .replace(/[^A-Za-z]/g, '').length;
    const numbers = value
      .substring(this.appAlphabetsWithNumbers)
      .replace(/[^0-9]/g, '').length;
    if (
      alphabets > this.appAlphabetsWithNumbers ||
      numbers > this.allowNumbers ||
      value.length > this.appAlphabetsWithNumbers + this.allowNumbers
    ) {
      return false;
    }
    return this.regexPattern.test(value);
  }

  private checkForValidation(
    event: KeyboardEvent,
    cursorPos: number,
    current: string
  ) {
    if (event.key.length === 1) {
      if (cursorPos < this.appAlphabetsWithNumbers) {
        if (
          /[0-9]/.test(event.key) ||
          current
            .slice(0, this.appAlphabetsWithNumbers)
            .replace(/[^A-Za-z]/g, '').length >= this.appAlphabetsWithNumbers
        ) {
          event.preventDefault();
          return;
        }
      } else if (
        /[A-Za-z]/.test(event.key) ||
        current.slice(this.appAlphabetsWithNumbers).replace(/[^0-9]/g, '')
          .length >= this.allowNumbers
      ) {
        event.preventDefault();
        return;
      }
    }
  }
}
