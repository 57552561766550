import { FormConfig } from "src/app/shared/models/dynamic.model";

export interface MawbsModal {
    originAirport: string;
    destinationAirport: string;
    recevingAgent: string;
    clearanceType: string;
  }
  
  // Default MawbsModal values
  export const defaultMawbsModal: MawbsModal = {
    originAirport: '',
    destinationAirport: '',
    recevingAgent: '',
    clearanceType: '',
  };
  
  // State model interface
  export interface MawbModalStateModel {
    mawbModal: MawbsModal;
  }
  
  // Initial state
  export const defaultMawbState: MawbModalStateModel = {
    mawbModal: defaultMawbsModal,
  };

  export const mawbAdvancedSearchFormFieldsConfig: FormConfig = {
    fields: [
      {
        id: 'originAirport',
        type: 'input',
        label: 'Origin',
        maxValue: 126,
      },
      {
        id: 'destinationAirport',
        type: 'input',
        label: 'Destination Airport',
        maxValue: 126,
      },
      {
        id: 'recevingAgent',
        type: 'input',
        label: 'Shipment',
        maxValue: 126,
      },
      {
        id: 'clearanceType',
        type: 'input',
        label: 'clearance Type',
        maxValue: 126,
      },
    ],
  };