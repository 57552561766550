import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { ErrorHandlerService } from './error-handler.service';
import { SpinnerService } from 'src/app/shared/services/spinner.service';

@Injectable({
  providedIn: 'root',
})
export class IntercepterService implements HttpInterceptor {
  constructor(
    private readonly errorHandler: ErrorHandlerService,
    private readonly spinnerService: SpinnerService
  ) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let ok: string;
    return next.handle(request).pipe(
      tap({
        next: event => {
          ok = event instanceof HttpResponse ? 'succeeded' : '';
          if (ok === 'succeeded') {
            this.spinnerService.hideSpinner();
          } else {
            this.spinnerService.showSpinner();
          }
        },
        error: _error => {
          if (_error instanceof HttpErrorResponse) {
            this.spinnerService.hideSpinner();
            this.errorHandler.handleError(_error);
          }
        },
      })
    );
  }
}
