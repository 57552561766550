import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { ValidatorPattern } from '../utility/validators.pattern';

@Directive({
  selector: '[appPhoneNumber]',
})
export class AppPhoneNumberDirective {
  @Input() appPhoneNumber!: boolean;

  public regexPattern!: RegExp;
  private readonly specialKeys: Array<string> = [
    'Backspace',
    'Tab',
    'End',
    'Home',
    'ArrowLeft',
    'ArrowRight',
    'Control',
  ];

  constructor(private readonly el: ElementRef) {}

  @HostListener('keypress', ['$event'])
  onKeyPress(event: KeyboardEvent) {
    const current: string = this.el.nativeElement.value;
    const next: string = current.concat(event.key);

    if (this.appPhoneNumber) {
      this.regexPattern = new RegExp(ValidatorPattern.NANPPhoneValidation);
    } else {
      this.regexPattern = new RegExp(
        ValidatorPattern.PhoneValidationWith10Numbers
      );
    }

    // Allow Backspace, tab, end, and home keys
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    if (
      (this.appPhoneNumber && next.length > 12) ||
      (!this.appPhoneNumber && next.length > 10) ||
      !new RegExp(
        this.appPhoneNumber
          ? ValidatorPattern.PhoneValidationWith10NumbersHyphen
          : ValidatorPattern.PhoneValidationWith10Numbers
      ).test(event.key)
    ) {
      event.preventDefault();
    }
  }
}
