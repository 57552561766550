import { FormConfig } from 'src/app/shared/models/dynamic.model';

export const detailsAdvancedSearchFormFieldsConfig: FormConfig = {
  fields: [
    {
      id: 'piece_id',
      type: 'input',
      label: 'Piece',
      maxValue: 126,
    },
    {
      id: 'commodity_id',
      type: 'input',
      label: 'Commodity',
      maxValue: 126,
    },
    {
      id: 'shipment_id',
      type: 'input',
      label: 'Shipment',
      maxValue: 126,
    },
    {
      id: 'bag_id',
      type: 'input',
      label: 'Bag',
      maxValue: 126,
    },
    {
      id: 'mawb_id',
      type: 'input',
      label: 'Mawb',
      maxValue: 126,
    },
    {
      id: 'flight_id',
      type: 'input',
      label: 'Flight',
      maxValue: 126,
    },
  ],
};

export interface DetailsModal {
  piece_id: string;
  commdity_id: string;
  shipment_id: string;
  bag_id: string;
  mawb_id: string;
  flight_id: string;
}

export const defaultDetailsModal: DetailsModal = {
  piece_id: '',
  commdity_id: '',
  shipment_id: '',
  bag_id: '',
  mawb_id: '',
  flight_id: '',
};

export interface DetailModalStateModel {
  detailModal: DetailsModal;
}

// Initial state
export const defaultState: DetailModalStateModel = {
  detailModal: defaultDetailsModal,
};
