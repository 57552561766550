import { FormConfig } from 'src/app/shared/models/dynamic.model';

export const miscellaneousAdvancedSearchFormFieldsConfig: FormConfig = {
  fields: [
    {
      type: 'input',
      id: 'listprice',
      label: 'Piece',
      maxValue: 126,
    },
    {
      label: 'Commodity',
      id: 'orderdate',
      type: 'input',
      maxValue: 126,
    },
    {
      id: 'orderid',
      type: 'input',
      maxValue: 126,
      label: 'Shipment',
    },
    {
      type: 'input',
      label: 'Bag',
      id: 'rentalprice',
      maxValue: 126,
    },
    {
      id: 'url',
      type: 'input',
      maxValue: 126,
      label: 'Mawb',
    },
  ],
};

export interface MiscellaneousModal {
  listprice: string;
  orderdate: string;
  orderid: string;
  rentalprice: string;
  url: string;
}

export const defaultMiscellaneousModal: MiscellaneousModal = {
  listprice: '',
  orderdate: '',
  orderid: '',
  rentalprice: '',
  url: '',
};

export interface MiscellaneousModalStateModel {
  miscellaneousModal: MiscellaneousModal;
}

// Initial state
export const defaultMiscellaneousState: MiscellaneousModalStateModel = {
  miscellaneousModal: defaultMiscellaneousModal,
};
