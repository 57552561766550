import { FormConfig } from '../../models/dynamic.model';

export const searchPopupFieldsConfig: FormConfig = {
  fields: [
    {
      id: 'name',
      type: 'input',
      label: 'Name',
      required: true,
      requiredError: 'Name is required',
      minValue: 1,
      maxValue: 126,
      noInitialWhiteSpace: true,
      isCheckForMaxValue: true,
    },
    {
      id: 'description',
      type: 'input',
      label: 'Description',
      maxValue: 500,
      noInitialWhiteSpace: true,
      isCheckForMaxValue: true,
    },
    {
      id: 'searchId',
      type: 'input',
      label: 'Id',
    },
  ],
};

export const OverlayButtons = [
  {
    id: 1,
    name: 'Saved Search',
  },
  {
    id: 2,
    name: 'Save as New Search',
  },
];

export const SearchTableColumns = [
  'select',
  'savedSearchName',
  'description',
  'createdDate',
  'actions',
];

export const recentSearchColumns = ['savedSearchName', 'actions'];

export const recentSavedSearchColumns = [
  {
    key: 'savedSearchName',
    label: 'Saved Search',
  },
  {
    key: 'description',
    label: 'Description',
  },
  {
    key: 'createdDate',
    label: 'Created On',
  },
];
