import { Directive, HostListener, ElementRef, Renderer2 } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appUppercase]',
})
export class UppercaseDirective {
  constructor(
    private readonly el: ElementRef,
    private readonly renderer: Renderer2,
    private readonly control: NgControl
  ) {}

  @HostListener('input', ['$event']) onInputChange(event: Event): void {
    const input = this.el.nativeElement as HTMLInputElement;
    const start = input.selectionStart;
    const end = input.selectionEnd;
    const upperCaseValue = input.value.toUpperCase();
    this.renderer.setProperty(input, 'value', upperCaseValue);
    if (this.control.control) {
      this.control.control.setValue(upperCaseValue, { emitEvent: false });
    }
    input.setSelectionRange(start, end);
  }
}
