<div
  class="business-information-container"
  [formGroup]="accountBusinessInfoForm">
  <mat-form-field
    *ngFor="let field of formFields"
    class="mat-md"
    appearance="outline">
    <mat-label>{{ field.label }}</mat-label>
    <input
    *ngIf="field.id !== 'FEINNumber' && field.id !== 'stateTaxId'"
      matInput
      formControlName="{{ field.id }}"
      (blur)="dipatchBusinessFormChanges()" />
    <input
      *ngIf="field.id === 'FEINNumber'"
      matInput
      appNumbersWithHyphen
      formControlName="{{ field.id }}"
      (blur)="dipatchBusinessFormChanges()" />
    <input
      *ngIf="field.id === 'stateTaxId'"
      matInput
      appNumbersWithHyphen
      [formatAsFein]="true"
      formControlName="{{ field.id }}"
      (blur)="dipatchBusinessFormChanges()" />
    <app-svg-icon
      *ngIf="checkControlForError(field.id)"
      matSuffix
      [iconName]="'infoCircle'"
      [isStaticSvg]="true"
      class="cursor info-icon-error"
      (click)="fetchWidth($event, field.id)"
      (keydown)="$event.stopPropagation()"></app-svg-icon>
    <app-custom-error-tooltip
      *ngIf="
        checkControlForError(field.id) && setBusinessInfoErrorTooltip[field.id]
      "
      [tooltipWidth]="setBusinessInfoWidth[field.id]"
      [tooltipText]="fetchErrorMessages(field.id)"
      (tooltipCloseClicked)="
        commonService.closeErrorTooltip(field.id, setBusinessInfoErrorTooltip)
      ">
    </app-custom-error-tooltip>
  </mat-form-field>
</div>
