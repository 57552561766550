import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import {
  SearchTableIcons,
  SearchModalActionHeaderIcons,
} from 'src/app/shared/utility/svg-constants';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  SearchTableColumns,
  recentSearchColumns,
  recentSavedSearchColumns,
} from '../../search.config';
import {
  SavedSearch,
  SvgIcon,
  SvgIconProp,
} from 'src/app/shared/models/dynamic.model';
import { MatSort } from '@angular/material/sort';
import { Subject, takeUntil } from 'rxjs';
import { ModalService } from 'src/app/shared/services/modal.service';
import {
  ActionIcons,
  gridMessages,
  ModalPopupMessages,
  popupWidth,
  SingleDeleteHeaderText,
  StatusCodes,
} from 'src/app/shared/utility/constants';
import { MessagePopupComponent } from '../../../message-popup/message-popup.component';
import { SearchService } from '../../search.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandlerService } from 'src/app/core/services/error-handler.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-recent-saved-search-popup',
  templateUrl: './recent-saved-search-popup.component.html',
  styleUrl: './recent-saved-search-popup.component.scss',
})
export class RecentSavedSearchPopupComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild(MatSort) sort!: MatSort;

  public popupData: any;
  public dataSource = new MatTableDataSource<SavedSearch>([]);
  public selection = new SelectionModel<SavedSearch>(true, []);
  public tableSortDirection!: string;
  public searchPopupTableColumns!: string[];
  public requiredTableColumns = recentSavedSearchColumns;
  public savedSearchTableActionIcons = JSON.parse(
    JSON.stringify(SearchTableIcons)
  );
  public searchPopupHeaderIcons = JSON.parse(
    JSON.stringify(SearchModalActionHeaderIcons)
  );
  public selectAllChecked = false;
  public noRecentSavedSearchMessage!: string;
  public recentSearchActionPinIcon = ActionIcons.searchPopupPin;
  public searchLabel = ActionIcons.search;

  private readonly destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly ref: MatDialogRef<RecentSavedSearchPopupComponent>,
    private readonly cdref: ChangeDetectorRef,
    private readonly modalService: ModalService,
    private readonly searchService: SearchService,
    private readonly errorHandlerService: ErrorHandlerService,
    private readonly datePipe: DatePipe
  ) {
    this.tableSortDirection = 'desc';
  }

  ngOnInit(): void {
    this.reloadRecentGridData();
    this.popupData = this.data;
    if (this.popupData?.code?.isFromSavedPopup) {
      this.searchPopupTableColumns = SearchTableColumns;
      this.getSavedSearchesDataFromApi();
      this.getIconsForPopup(true);
      this.enableOverAllDeleteIcon(true); //disable over all delete icon
      this.noRecentSavedSearchMessage = gridMessages.noSavedSearch;
    } else {
      this.searchPopupTableColumns = recentSearchColumns;
      this.getIconsForPopup(false);
      this.noRecentSavedSearchMessage = gridMessages.noRecentSearch;
    }
    this.dataSource.data = this.popupData?.code?.tableData;
  }

  ngAfterViewInit() {
    this.getDataWithSort();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  public onhyperlinkClicked(clickedItem: any) {
    this.ref.close();
    this.searchService.setClickedHyperlinkData({
      item: clickedItem,
      isOpenOverlay: false,
    });
  }

  public closePopup() {
    this.ref.close();
  }

  /**
   * Select all or deselect all based on the header
   * checkbox state
   * @param event - Checkbox change event*/

  public onCheckAllChange(event: any): void {
    const isChecked = event.checked;
    if (isChecked) {
      this.selectAllChecked = true;
      this.selection.clear();
      this.dataSource.data.forEach(item => (item.checked = true));
      this.selection.select(...this.dataSource.data);
    } else {
      this.selectAllChecked = false;
      this.dataSource.data.forEach(item => (item.checked = false));
      this.selection.clear();
    }
  }

  // for table body checkbox selection
  public checked(row: any) {
    this.selection.select(row);
    const found = this.selection.selected.find(
      x => x.savedSearchId == row.savedSearchId
    );
    if (found) {
      found.checked = true;
    }
  }

  public unchecked(row: any) {
    const found = this.selection.selected.find(
      x => x.savedSearchId == row.savedSearchId
    );
    if (found) {
      found.checked = false;
      this.selection.deselect(found);
    }
  }

  public isChecked(row: any): boolean {
    const found = this.selection.selected.find(
      el => el.savedSearchId === row.savedSearchId
    );
    if (found) {
      return true;
    }
    return false;
  }

  public getSelectionStatus() {
    this.enableOverAllDeleteIcon(!this.selection.selected.length);
  }

  public setSelectedAll() {
    this.selectAllChecked =
      this.selection.selected.length === this.dataSource.data.length;
  }

  public onIconClicked(clickedIcon: SvgIcon) {
    const isFromSaved = this.popupData?.code?.isFromSavedPopup;
    if (clickedIcon.name === ActionIcons.delete) {
      if (!isFromSaved || (isFromSaved && this.selection.selected.length > 1)) {
        this.deleteAllRecentSearches(this.popupData?.code?.isFromSavedPopup);
      } else {
        this.searchService.deleteSearchPopup(
          this.selection.selected[0],
          this.popupData?.code?.isFrom,
          !isFromSaved
        );
      }
    }
  }

  public tableActionClicked(clickedIcon: any, rowData: any) {
    if (clickedIcon.name === ActionIcons.searchPopupPin) {
      this.searchService.preparePayloadForPinOrUnpin(
        rowData,
        this.popupData?.code?.isFrom
      );
    } else if (clickedIcon.name === ActionIcons.searchPopupEdit) {
      this.searchService.openSaveAsNewSearchPopup(
        false,
        this.popupData?.code?.isFrom,
        '',
        rowData,
        true
      );
    } else if (clickedIcon.name === ActionIcons.searchPopupDelete) {
      this.searchService.deleteSearchPopup(
        rowData,
        this.popupData?.code?.isFrom,
        !this.popupData?.code?.isFromSavedPopup
      );
    } else if (clickedIcon.name === ActionIcons.searchPopupSave) {
      this.searchService.openSaveAsNewSearchPopup(
        false,
        this.popupData?.code?.isFrom,
        '',
        rowData
      );
    }
  }

  private getDataWithSort() {
    this.sort.active = 'savedSearchName';
    this.dataSource.sort = this.sort;
    this.cdref.detectChanges();
    this.sort.sortChange.pipe(takeUntil(this.destroy$)).subscribe(data => {
      this.tableSortDirection = data.direction;
    });
  }

  private getIconsForPopup(isForSaved: boolean) {
    this.savedSearchTableActionIcons = this.savedSearchTableActionIcons.filter(
      (icon: SvgIconProp) => icon.order !== (isForSaved ? 3 : 2)
    );
  }

  private enableOverAllDeleteIcon(condition: boolean) {
    const modify = this.searchPopupHeaderIcons.map((icon: SvgIconProp) => {
      icon.isDisabled = condition;
      return icon;
    });
    this.searchPopupHeaderIcons = modify;
  }

  private deleteAllRecentSearches(isFromSaved: boolean) {
    const dialogRef = this.modalService.openPopup(
      {
        message: isFromSaved
          ? ModalPopupMessages.bulkDeleteMessage(
              this.selection.selected.length,
              SingleDeleteHeaderText.savedSearches
            )
          : ModalPopupMessages.clearRecentSearchContent,
        isForSearch: true,
        isFooterRequired: true,
        isBulk: true,
      },
      isFromSaved
        ? ModalPopupMessages.BulkDeleteHeader
        : ModalPopupMessages.recentSearchesHeader,
      MessagePopupComponent,
      popupWidth.deletePopup
    );
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        if (data === true) {
          const deletionIds: number[] = [];
          const allRecentSearchData = isFromSaved
            ? this.selection.selected
            : this.popupData?.code?.tableData;
          allRecentSearchData.forEach((item: any) => {
            deletionIds.push(item.savedSearchId);
          });
          const payload = {
            savedSearchIds: deletionIds,
            tagName: this.popupData?.code?.isFrom,
            isRecent: !isFromSaved,
          };
          this.searchService.deleteSearches(payload, !isFromSaved);
        }
      });
  }

  private getSavedSearchesDataFromApi() {
    this.searchService
      .getSavedSearchesList({ screenTag: this.popupData?.code?.isFrom })
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (result: any) => {
          if (result.statusCode == StatusCodes.Success) {
            result.data.forEach((item: any) => {
              item.createdDate = this.datePipe.transform(
                item.createdDate,
                'MM-dd-yyyy'
              );
            });
            this.dataSource.data = result.data;
          } else {
            this.errorHandlerService.handleApiError(result);
          }
        },
        error: (error: HttpErrorResponse) => {
          this.errorHandlerService.handleError(error);
        },
      });
  }

  private reloadRecentGridData() {
    this.searchService.isRecentGridReload.subscribe(recentSearchData => {
      if (
        recentSearchData.length > 0 &&
        !this.popupData?.code?.isFromSavedPopup
      ) {
        this.dataSource.data = recentSearchData;
        this.searchService.setRecentGridReloadData([]);
      } else if (
        recentSearchData.length > 0 &&
        this.popupData?.code?.isFromSavedPopup
      ) {
        this.selection.clear();
        this.enableOverAllDeleteIcon(true); //disable over all delete icon
        this.getSavedSearchesDataFromApi();
        this.searchService.setRecentGridReloadData([]);
      }
    });
  }
}
