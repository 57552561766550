import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/core/services/base.httpService';
import { URLConstants } from '../../utility/url-constants';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import {
  ActionIcons,
  actionText,
  ModalPopupMessages,
  popupWidth,
  searchLimitCount,
  SingleDeleteHeaderText,
  StatusCodes,
} from '../../utility/constants';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandlerService } from 'src/app/core/services/error-handler.service';
import { SnakbarService } from '../../services/snakbar.service';
import { ModalService } from '../../services/modal.service';
import { MessagePopupComponent } from '../message-popup/message-popup.component';
import { MatDialogRef } from '@angular/material/dialog';
import { SearchPopupComponent } from './search-options/search-popup/search-popup.component';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  public clickedHyperlinkData = new BehaviorSubject<any>({});
  public isHamburgerSaveNewButtonData = new BehaviorSubject<string>('');
  public isActionSuccessful = new BehaviorSubject<boolean>(false);
  public isRecentGridReload = new BehaviorSubject<any>([]);
  private readonly destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private readonly baseService: BaseService,
    private readonly errorHandlerService: ErrorHandlerService,
    private readonly snakbarService: SnakbarService,
    private readonly modalService: ModalService
  ) {}

  public setClickedHyperlinkData(data: any): void {
    this.clickedHyperlinkData.next(data);
  }

  public setHamburgerSaveNewButtonData(data: string): void {
    this.isHamburgerSaveNewButtonData.next(data);
  }

  public setActionSuccessfulData(data: boolean): void {
    this.isActionSuccessful.next(data);
  }

  public setRecentGridReloadData(data: any): void {
    this.isRecentGridReload.next(data);
  }

  public preparePayloadForPinOrUnpin(rowData: any, isFromModule: string) {
    const savePinSearchPayload = {
      name: rowData.savedSearchName,
      description: rowData.description,
      savedSearchIds: [rowData.savedSearchId],
      action: rowData.isPinned
        ? ActionIcons.unPinSearch
        : ActionIcons.pinSearch,
      tagName: isFromModule,
    };
    this.saveUpdatePinSearches(savePinSearchPayload);
  }

  public openSaveAsNewSearchPopup(
    isNew: boolean,
    isFromModule: string,
    searchValue: string,
    existingData?: any,
    isForUpdate?: boolean
  ) {
    const dataForPopup = {
      data: {
        searchName: isNew ? searchValue : existingData.savedSearchName,
        description: isNew ? '' : existingData.description,
        searchId: isNew ? 0 : existingData.savedSearchId,
        isPinned: isNew ? false : existingData.isPinned,
        isFrom: isFromModule,
      },
    };
    this.modalService.openPopup(
      dataForPopup,
      isForUpdate
        ? ModalPopupMessages.updateSearch
        : ModalPopupMessages.saveAsNewSearchHeader,
      SearchPopupComponent,
      popupWidth.searchPopup
    );
  }

  public saveUpdatePinSearches(payload: any, ref?: MatDialogRef<any>) {
    this.saveUpdatePinSearch(payload).subscribe({
      next: (result: any) => {
        if (result.statusCode == StatusCodes.Success) {
          this.snakbarService.openCustomisedSnackBar(
            ModalPopupMessages.searchSuccess(
              SingleDeleteHeaderText.Search,
              payload.action === ActionIcons.unPinSearch
                ? actionText.unpinned
                : actionText.pinned
            ),
            ModalPopupMessages.success
          );
          ref?.close();
          this.setActionSuccessfulData(true);
        } else if (result.statusCode == StatusCodes.SuccessfulRequest) {
          this.snakbarService.openCustomisedSnackBar(
            ModalPopupMessages.searchLimitWarning(
              actionText.pin,
              searchLimitCount.pin
            ),
            ModalPopupMessages.Warning
          );
        } else {
          this.errorHandlerService.handleApiError(result);
        }
      },
      error: (error: HttpErrorResponse) => {
        this.errorHandlerService.handleError(error);
      },
    });
  }

  public saveUpdateNewSearches(
    payload: any,
    ref: MatDialogRef<any>,
    isForUpdate: boolean
  ) {
    this.saveUpdateNewSearch(payload).subscribe({
      next: (result: any) => {
        if (result.statusCode == StatusCodes.Success) {
          this.snakbarService.openCustomisedSnackBar(
            ModalPopupMessages.searchSuccess(
              SingleDeleteHeaderText.Search,
              isForUpdate ? actionText.update : actionText.saved
            ),
            ModalPopupMessages.success
          );
          ref?.close();
          this.setActionSuccessfulData(true);
        } else if (result.statusCode == StatusCodes.SuccessfulRequest) {
          this.snakbarService.openCustomisedSnackBar(
            ModalPopupMessages.searchLimitWarning(
              actionText.Saved,
              searchLimitCount.saved
            ),
            ModalPopupMessages.Warning
          );
        } else {
          this.errorHandlerService.handleApiError(result);
        }
      },
      error: (error: HttpErrorResponse) => {
        this.errorHandlerService.handleError(error);
      },
    });
  }

  public deleteSearchPopup(
    deleteData: any,
    isFrom: string,
    isFromRecent: boolean
  ) {
    const dialogRef = this.modalService.openPopup(
      {
        message:
          ModalPopupMessages.singleDeleteMessage(
            isFromRecent
              ? SingleDeleteHeaderText.RecentSearch
              : SingleDeleteHeaderText.savedSearch
          ) + `${deleteData.savedSearchName}?`,
        isForSearch: true,
        isFooterRequired: true,
        isBulk: false,
      },
      ModalPopupMessages.singleDeleteHeader(SingleDeleteHeaderText.Search),
      MessagePopupComponent,
      popupWidth.deletePopup
    );
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        if (data === true) {
          const payload = {
            savedSearchIds: [deleteData.savedSearchId],
            tagName: isFrom,
            isRecent: isFromRecent,
          };
          this.deleteSearches(payload, isFromRecent);
        }
      });
  }

  public deleteSearches(payload: any, isFromRecent: boolean) {
    this.deleteSearch(payload).subscribe({
      next: (result: any) => {
        if (result.statusCode == StatusCodes.Success) {
          this.snakbarService.openCustomisedSnackBar(
            ModalPopupMessages.searchSuccess(
              this.getSearchSuccessMessages(payload, isFromRecent),
              actionText.deleted
            ),
            ModalPopupMessages.success
          );
          this.setActionSuccessfulData(true);
        } else {
          this.errorHandlerService.handleApiError(result);
        }
      },
      error: (error: HttpErrorResponse) => {
        this.errorHandlerService.handleError(error);
      },
    });
  }

  public saveUpdatePinSearch(data: any) {
    return this.baseService.initiateApiRequest(
      'POST',
      URLConstants.saveUpdatePinSearch,
      data,
      'json'
    );
  }

  public saveUpdateNewSearch(data: any) {
    return this.baseService.initiateApiRequest(
      'POST',
      URLConstants.saveUpdateNewSearch,
      data,
      'json'
    );
  }

  public deleteSearch(data: any) {
    return this.baseService.initiateApiRequest(
      'POST',
      URLConstants.deleteSearch,
      data,
      'json'
    );
  }

  public getRecentAndPinnedSearchList(data: any) {
    return this.baseService.initiateApiRequest(
      'POST',
      URLConstants.getRecentAndPinnedSearchesList,
      data,
      'json'
    );
  }

  public getSavedSearchesList(data: any) {
    return this.baseService.initiateApiRequest(
      'POST',
      URLConstants.getSavedSearchesList,
      data,
      'json'
    );
  }

  private getSearchSuccessMessages(payload: any, isFromRecent: boolean) {
    if (isFromRecent && payload.savedSearchIds.length > 1) {
      return SingleDeleteHeaderText.RecentSearches;
    } else if (isFromRecent && payload.savedSearchIds.length == 1) {
      return SingleDeleteHeaderText.RecentSearch;
    } else if (!isFromRecent && payload.savedSearchIds.length == 1) {
      return SingleDeleteHeaderText.savedSearch;
    } else if (!isFromRecent && payload.savedSearchIds.length > 1) {
      return SingleDeleteHeaderText.savedSearches;
    } else {
      return '';
    }
  }
}
