import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from 'src/app/core/services/base.httpService';
import { URLConstants } from 'src/app/shared/utility/url-constants';

@Injectable({
  providedIn: 'root',
})
export class IBCAuthService { 
    constructor(
        public baseService: BaseService,
        private readonly http: HttpClient
      ) {}

    public authorizeB2CToken(accessToken:string): Observable<any> {
    const url = URLConstants.authorize;
    return this.baseService.authorizeToken(url,accessToken);
    }
}