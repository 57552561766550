export interface CrudModal {
    shipment: string;
    origin: string;
    destination: string;
    description: string;
    services: string;
    account: string;
    customerId: string;
    customerName: string;
  }
  
  export const defaultCrudModal: CrudModal = {
    shipment: '',
    origin: '',
    destination: '',
    description: '',
    services: '',
    account: '',
    customerId: '',
    customerName: '',
  };
  
  export interface CrudModalStateModel {
    crudModal: CrudModal;
  }
  
  // Initial state
  export const defaultCrudState: CrudModalStateModel = {
    crudModal: defaultCrudModal,
  };
  