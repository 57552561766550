import { FormConfig } from "src/app/shared/models/dynamic.model";

export interface NoteModal {
  date_time: string;
  user: string;
  type: string;
  description: string;
  isFromScreen: '';
}

export const defaultNoteModal: NoteModal = {
  date_time: '',
  user: '',
  type: '',
  description: '',
  isFromScreen: '',
};

export interface NoteModalStateModel {
  noteModals: NoteModal[];
}

export const defaultNoteState: NoteModalStateModel = {
  noteModals: [],
};

export const noteAdvancedSearchFormFieldsConfig: FormConfig = {
  fields: [
    {
      id: 'date_time',
      type: 'input',
      label: 'Piece',
      maxValue: 126,
    },
    {
      type: 'input',
      maxValue: 126,
      label: 'Commodity',
      id: 'user',
    },
    {
      type: 'input',
      label: 'Shipment',
      id: 'type',
      maxValue: 126,
    },
    {
      id: 'description',
      type: 'input',
      maxValue: 126,
      label: 'Bag',
    },
    {
      id: 'isFromScreen',
      maxValue: 126,
      type: 'input',
      label: 'Mawb',
    },
  ],
};
