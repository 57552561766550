<div class="mailing-address-container">
  <div class="heading primary">
    <span class="ibc-heading-h7-bold-primary ng-star-inserted">
      {{ mailingAddressHeader(isFrom) }}
    </span>
  </div>
  <div class="address-container" [formGroup]="accountAddressForm">
    <mat-form-field
      *ngFor="let field of formFields"
      appearance="outline"
      class="mat-md">
      <mat-label>{{ field.label }}</mat-label>
      <input
        *ngIf="field.id === 'countryCode'"
        [id]="'accountAddressCountry' + isFrom"
        appOnOptionSelectedTabNewInput
        matInput
        type="text"
        formControlName="countryCode"
        [placeholder]="getAddressPlaceholder('countryCode')"
        [matAutocomplete]="countryAutoComplete"
        (input)="
          dispatchAccountsAddressFormChanges();
          setAutocompleteDropdownError('accountAddressCountry' + isFrom);
          clearFields(
            'countryCode',
            accountAddressForm.get('countryCode')?.value
          );
          getStateList(accountAddressForm.get('countryCode')?.value)
        "
        (focusout)="
          dispatchAccountsAddressFormChanges(); setAutocompleteDropdownError()
        " />
      <mat-autocomplete
        #countryAutoComplete="matAutocomplete"
        (optionSelected)="getStateList($event.option.value)"
        appOnOptionSelectedTabNewInput
        [controlId]="'accountAddressCountry' + isFrom">
        <mat-option
          [value]="item.id"
          *ngFor="let item of filteredCountryTypeOptions | async">
          {{ item.id }}
        </mat-option>
      </mat-autocomplete>
      <input
        *ngIf="field.id === 'state'"
        [id]="'accountAddressState' + isFrom"
        appOnOptionSelectedTabNewInput
        matInput
        type="text"
        formControlName="state"
        [placeholder]="getAddressPlaceholder('state')"
        [matAutocomplete]="stateAutoComplete"
        (input)="
          dispatchAccountsAddressFormChanges();
          formAutocompleteValueChanges(
            'state',
            'stateCodeListIdVal',
            stateList,
            accountAddressForm,
            'accountAddressState' + isFrom
          )
        "
        (focusout)="dispatchAccountsAddressFormChanges()" />
      <mat-autocomplete
        #stateAutoComplete="matAutocomplete"
        appOnOptionSelectedTabNewInput
        [controlId]="'accountAddressState' + isFrom">
        <mat-option
          [value]="item.name"
          *ngFor="let item of filteredStateDataTypeOptions | async"
          >{{ item.name }}
        </mat-option>
      </mat-autocomplete>
      <input
        matInput
        *ngIf="field.id === 'phone'"
        placeholder="Phone"
        formControlName="phone"
        [placeholder]="getAddressPlaceholder('phone')"
        [appPhoneNumber]="accountAddressForm.get('countryCode')?.value === countryCode.usa ? true : false"
        (focusout)="dispatchAccountsAddressFormChanges()" />
        <app-svg-icon
        *ngIf="checkControlHasError('phone') && field.id === 'phone'"
        matSuffix
        [iconName]="actionIcons.infoCircle"
        [isStaticSvg]="true"
        [isFrom]="svgIconFromList.page"
        class="cursor info-icon-error"
        (click)="fetchWidthPhone($event, 'phone')"
        (keydown)="$event.stopPropagation()"></app-svg-icon>
      <app-custom-error-tooltip
        *ngIf="
          checkControlHasError('phone') &&
          setAddressInfoErrorTooltip['phone'] && field.id === 'phone'
        "
        [tooltipWidth]="setAddressInfoWidth['phone']"
        [tooltipText]="getErrorMessage('phone')"
        (tooltipCloseClicked)="
          commonService.closeErrorTooltip(
            'phone',
            setAddressInfoErrorTooltip
          )
        "></app-custom-error-tooltip>
      <input
        matInput
        *ngIf="field.id !== 'countryCode' && field.id !== 'state' && field.id !== 'phone'"
        formControlName="{{ field.id }}"
        (blur)="dispatchAccountsAddressFormChanges()" />
      <app-svg-icon
        *ngIf="checkControlForError(field.id) && field.id !== 'phone'"
        matSuffix
        [iconName]="'infoCircle'"
        [isStaticSvg]="true"
        class="cursor info-icon-error"
        (click)="fetchWidth($event, field.id)"
        (keydown)="$event.stopPropagation()"></app-svg-icon>
      <app-custom-error-tooltip
        *ngIf="
          checkControlForError(field.id) &&
          setMailingAddressErrorTooltip[field.id] &&
          field.id !== 'phone'
        "
        [tooltipWidth]="setMailingAddressWidth[field.id]"
        [tooltipText]="fetchErrorMessages(field.id)"
        (tooltipCloseClicked)="
          commonService.closeErrorTooltip(
            field.id,
            setMailingAddressErrorTooltip
          )
        ">
      </app-custom-error-tooltip>
    </mat-form-field>
  </div>
</div>
