import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import { SvgIconGenerationService } from '../../services/svg-icon-generation.service';
import {
  StaticSvgIconProp,
  SvgIcon,
  SvgIconProp,
} from '../../models/dynamic.model';
import { MaterialModule } from '../../material.module';
import { CommonService } from '../../services/common.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-svg-icon',
  standalone: true,
  imports: [CommonModule, MaterialModule],
  templateUrl: './svg-icon.component.html',
  styleUrls: ['./svg-icon.component.scss'],
})
export class SvgIconComponent {
  @Input() list!: SvgIconProp[];
  @Input() isStaticSvg!: boolean;
  @Input() iconName!: string;
  @Input() condition!: string;
  @Input() isFrom!: string;

  @Output() iconClicked = new EventEmitter<SvgIcon>();
  @Output() staticIconClicked = new EventEmitter<StaticSvgIconProp>();
  private readonly destroy$: Subject<boolean> = new Subject<boolean>();
  public iconList!: SvgIcon[];
  public staticList!: StaticSvgIconProp;

  constructor(
    private readonly svgIconService: SvgIconGenerationService,
    private readonly commonService: CommonService
  ) {}

  ngOnInit() {
    this.commonService.isCheckForMode
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: any) => {
        if (data === 'View') {
          this.iconList = this.svgIconService.generateIconList(this.list);
        } else {
          this.iconList = this.svgIconService.generateIconList(this.list);
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['list']) {
      this.iconList = this.svgIconService.generateIconList(
        changes['list'].currentValue
      );
    } else if (changes['iconName']) {
      this.staticList = this.svgIconService.generateStaticIcon(
        changes['iconName'].currentValue,
        this.isFrom
      );
    }
  }

  public iconClick(icon: any) {
    this.iconClicked.emit(icon);
  }

  public staticIconClick(staticList: StaticSvgIconProp) {
    this.staticIconClicked.emit(staticList);
  }
}
