import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MaterialModule } from '../../material.module';

@Component({
  selector: 'app-statuscode-additional-details',
  standalone: true,
  imports: [CommonModule, MaterialModule],
  templateUrl: './statuscode-additional-details.component.html',
  styleUrl: './statuscode-additional-details.component.scss',
})
export class StatuscodeAdditionalDetailsComponent {}
