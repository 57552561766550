import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ValidatorService } from 'src/app/shared/services/form-control-validators.service';
import {
  customerServiceFormConfig,
  customerServiceInputRow,
  customerServiceTableColumnConfig,
  governmentInformationFields,
} from '../../accounts.config';
import {
  FormField,
  IDropdownResponse,
  ILookupDropdownResponse,
  SvgIcon,
  SvgIconProp,
} from 'src/app/shared/models/dynamic.model';
import {
  ActionIcons,
  BulkDeleteItemNames,
  conditionCheckConstants,
  DataTypes,
  formFieldTypes,
  MasterLookup,
  ModalPopupMessages,
  ModeIcons,
  pageType,
  popupWidth,
  selectionTableNames,
  SingleDeleteHeaderText,
  validationErrorMessages,
} from 'src/app/shared/utility/constants';
import { MatTableDataSource } from '@angular/material/table';
import { CustomerServiceGridContent } from '../../accounts.model';
import {
  DefaultInfoHeaderIcons,
  DefaultInfoTableHeaderIcons,
} from 'src/app/shared/utility/svg-constants';
import { ModalService } from 'src/app/shared/services/modal.service';
import { MessagePopupComponent } from 'src/app/shared/components/message-popup/message-popup.component';
import { SnakbarService } from 'src/app/shared/services/snakbar.service';
import { CommonService } from 'src/app/shared/services/common.service';

import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { map, Observable, startWith, Subject, takeUntil } from 'rxjs';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Store } from '@ngxs/store';
import { SaveGovernmentDetails } from '../../store/accounts.action';
import { IGovernmentInfoDetailsData } from 'src/app/features/model/sm-accounts';
import { AccountsService } from 'src/app/features/service/accounts.service';
import { CreateEditTableComponent } from 'src/app/shared/components/tables/create-edit-table/create-edit-table.component';

@Component({
  selector: 'app-government-information',
  templateUrl: './government-information.component.html',
  styleUrl: './government-information.component.scss',
})
export class GovernmentInformationComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild('emailInput') emailInput!: ElementRef;
  @ViewChild(CreateEditTableComponent)
  private readonly createEditTableComp!: CreateEditTableComponent;

  public accountGovtInfoForm!: FormGroup;
  public formFields!: FormField[];
  public errorMessages: any;
  public formFieldType = formFieldTypes;
  public actionIcons = ActionIcons;
  public customerServiceTableData: CustomerServiceGridContent[] =
    customerServiceInputRow;
  public customerServiceTableDataSource =
    new MatTableDataSource<CustomerServiceGridContent>([]);
  public customerServiceTableColumnConfig = customerServiceTableColumnConfig;
  public customerServiceFormConfig = customerServiceFormConfig;
  public tableName = selectionTableNames;
  public headerText = SingleDeleteHeaderText;
  public customerServiceTableHeaderIcons!: SvgIconProp[];
  public customerServiceSelectedRow!: any;
  public setGovernmentInfoWidth: any = {};
  public setGovernmentInfoErrorTooltip: any = {};
  public setGovernmentInfoTooltipEvent: any = {};
  public filteredClearanceTypeOptions!: Observable<IDropdownResponse[]>;
  public clearanceTypeData!: IDropdownResponse[];
  public governmentInfoTabData$!: Observable<IGovernmentInfoDetailsData>;
  public governmentInfoTabFormData: any;
  public pageMode!: string;
  public customerServiceGridFormData: any;
  public customerServiceGridForm!: FormGroup;

  private readonly destroy$: Subject<boolean> = new Subject<boolean>();

  // variables for chip autocomplete

  public visible = true;
  public selectable = true;
  public removable = true;
  public addOnBlur = false;

  separatorKeysCodes = [ENTER, COMMA];

  public emailCtrl = new FormControl();
  public emailsForInvoiceNotifications: any = [];
  public emailsForGatewayNotifications: any = [];
  public userInvoiceEmailData!: IDropdownResponse[];
  public filterUserInvoiceEmailData!: Observable<any[]>;
  public userGatewayEmailData!: IDropdownResponse[];
  public filterUserGatewayEmailData!: Observable<any[]>;
  public salesPersonData!: IDropdownResponse[];
  public filterSalesPersonOption!: Observable<any[]>;

  constructor(
    private readonly formUtilsService: ValidatorService,
    private readonly modalService: ModalService,
    private readonly snakbarService: SnakbarService,
    public readonly commonService: CommonService,
    private readonly store: Store,
    private readonly accountsService: AccountsService
  ) {
    this.setReqInit();
    this.governmentInfoTabData$ = this.store.select(
      state => state.accountsGovernmentInfo
    );
  }

  ngOnInit() {
    this.checkMode();
    this.dataFetch();
  }

  ngAfterViewInit(): void {
    this.customerServiceGridForm = this.createEditTableComp.crudTableForm;
    this.checkGovtInfoValidData();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  private setReqInit() {
    this.createForm();
    this.formFields = governmentInformationFields.fields.filter(
      controls => controls.type !== conditionCheckConstants.extra
    );
    this.getIconsForCustomerServiceTableInfoMode(ModeIcons.add);
    this.loadLookUpData();
  }

  public onCustomerServiceTableSelectionChange(selectedRows: any[]) {
    this.customerServiceSelectedRow = selectedRows;
    this.customerServiceTableDataSelected(selectedRows.length > 0);
  }

  public onCustomerServiceIconClicked(iconClicked: SvgIcon) {
    if (iconClicked.name === ActionIcons.add) {
      this.handleCustomerGridAdd();
    } else if (iconClicked.name === ActionIcons.save) {
      this.handleCustomerGridSave();
    } else if (iconClicked.name === ActionIcons.close) {
      this.handleCustomerGridCancel();
    } else if (iconClicked.name === ActionIcons.delete) {
      this.handleCustomerGridDelete();
    }
  }

  public checkControlForError(controlName: string) {
    return this.commonService.checkControlError(
      this.accountGovtInfoForm,
      controlName
    );
  }

  public fetchWidth(event: MouseEvent, controlName: string) {
    this.commonService.setErrorTooltipData(
      event,
      controlName,
      this.setGovernmentInfoWidth,
      this.setGovernmentInfoTooltipEvent,
      this.setGovernmentInfoErrorTooltip
    );
  }

  public fetchErrorMessages(controlName: string): string {
    if (
      this.accountGovtInfoForm.get(controlName)?.errors?.['autocompleteError']
    ) {
      return validationErrorMessages.autocompleteError;
    } else {
      return this.formUtilsService.getErrorMessage(
        this.accountGovtInfoForm,
        this.errorMessages,
        controlName
      );
    }
  }

  public dispatchBusinessFormChanges() {
    const govtInfoData = {
      ...this.accountGovtInfoForm.value,
      customerService: this.customerServiceTableDataSource.data,
    };
    this.store.dispatch(new SaveGovernmentDetails(govtInfoData));
    this.commonService.checkPristineAccordionData(false);
    this.validateGovernmentInfoTab();
  }

  private handleCustomerGridAdd() {
    const custServiceTableHeaderIcons = JSON.parse(
      JSON.stringify(DefaultInfoHeaderIcons)
    );
    const actionIconNames = [
      ActionIcons.add,
      ActionIcons.view,
      ActionIcons.edit,
      ActionIcons.delete,
      ActionIcons.save,
      ActionIcons.close,
    ];

    this.customerServiceTableHeaderIcons = custServiceTableHeaderIcons
      .filter((val: SvgIconProp) => actionIconNames.includes(val.name))
      .map((val: SvgIconProp) => {
        val.isDisabled =
          val.name !== ActionIcons.save && val.name !== ActionIcons.close;
        return val;
      });
    this.customerServiceTableDataSource.data = [
      ...customerServiceInputRow,
      ...this.customerServiceTableDataSource.data,
    ];
  }

  private handleCustomerGridSave() {
    if (this.customerServiceGridForm.valid) {
      const formValue = this.customerServiceGridForm.value;
      const newlyAddedRow = {
        id: 0,
        tempId: this.getCustomerServiceFilteredData().length + 1,
        isInputRow: false,
        ...formValue,
      };
      this.customerServiceGridForm.reset();
      this.customerServiceTableDataSource.data = [
        ...this.customerServiceTableDataSource.data,
        newlyAddedRow,
      ];
      this.dispatchBusinessFormChanges();
    } else {
      this.customerServiceGridForm.markAllAsTouched();
    }
  }

  private handleCustomerGridCancel() {
    if (this.pageMode === ModeIcons.view) {
      this.customerServiceTableDataSource.data =
        this.getCustomerServiceFilteredData();
      this.getIconsForCustomerServiceTableInfoMode(ModeIcons.add);
    }
    this.snakbarService.openCustomisedSnackBar(
      ModalPopupMessages.actionCancel,
      ModalPopupMessages.success
    );
    this.customerServiceGridForm.reset();
  }

  private handleCustomerGridDelete() {
    let messageType;
    let message;
    if (this.customerServiceSelectedRow.length > 1) {
      message = ModalPopupMessages.bulkDeleteMessage(
        this.customerServiceSelectedRow.length,
        BulkDeleteItemNames.CustomerServices
      );
      messageType = ModalPopupMessages.BulkDeleteHeader;
    } else {
      message =
        ModalPopupMessages.singleDeleteMessage(
          SingleDeleteHeaderText.CustomerService
        ) + `${this.customerServiceSelectedRow[0]?.serviceType}?`;
      messageType = ModalPopupMessages.singleDeleteHeader(
        SingleDeleteHeaderText.CustomerService
      );
    }
    const dialogRef = this.modalService.openPopup(
      {
        message: message,
        isMultiple: true,
        isFooterRequired: true,
        isBulk: this.customerServiceSelectedRow.length > 1,
      },
      messageType,
      MessagePopupComponent,
      popupWidth.deletePopup
    );
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        if (data) {
          this.customerServiceDelete();
        }
      });
  }

  private customerServiceDelete() {
    this.customerServiceSelectedRow.forEach((customerService: any) => {
      const param = customerService.id == 0 ? 'tempId' : 'id';
      this.customerServiceTableDataSource.data =
        this.customerServiceTableDataSource.data.filter(
          (record: any) => record[param] !== customerService[param]
        );
    });
    this.createEditTableComp.selection.clear();
    this.getIconsForCustomerServiceTableInfoMode(ModeIcons.add);
  }

  private customerServiceTableDataSelected(data: boolean) {
    const modifiedIcons = this.customerServiceTableHeaderIcons.map(
      (val: SvgIconProp) => {
        if (val.name === ActionIcons.delete || val.name === ActionIcons.edit) {
          val.isDisabled = !data;
        } else if (
          val.name === ActionIcons.save &&
          this.pageMode !== ModeIcons.view
        ) {
          val.isDisabled = data;
        }
        return val;
      }
    );
    this.customerServiceTableHeaderIcons = modifiedIcons;
  }

  private getIconsForCustomerServiceTableInfoMode(mode: string) {
    const custServiceTableHeaderIcons = JSON.parse(
      JSON.stringify(DefaultInfoTableHeaderIcons)
    );
    const filtered: SvgIconProp[] = [];
    if (mode === ModeIcons.add || mode === ModeIcons.edit) {
      custServiceTableHeaderIcons.forEach((val: SvgIconProp) => {
        if (val.name !== ActionIcons.add && val.name !== ActionIcons.view) {
          filtered.push(val);
        }
      });
    } else if (mode === ModeIcons.view) {
      custServiceTableHeaderIcons.forEach((val: SvgIconProp) => {
        if (val.name === ActionIcons.add) {
          val.isDisabled = false;
        } else if (
          val.name === ActionIcons.edit ||
          val.name === ActionIcons.delete ||
          val.name === ActionIcons.save ||
          val.name === ActionIcons.close
        ) {
          val.isDisabled = true;
        }
        filtered.push(val);
      });
    }
    this.customerServiceTableHeaderIcons = filtered;
  }

  private createForm() {
    const { form, errorMessages } = this.formUtilsService.generateForm(
      governmentInformationFields
    );
    this.accountGovtInfoForm = form;
    this.errorMessages = errorMessages;
  }

  public addForInvoiceEmail(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      this.emailsForInvoiceNotifications.push(value.trim());
    }
    if (input) {
      input.value = '';
    }
    this.emailCtrl.setValue(null);
  }

  public addForGatewayEmail(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      this.emailsForGatewayNotifications.push(value.trim());
    }
    if (input) {
      input.value = '';
    }
    this.emailCtrl.setValue(null);
  }

  removeForInvoiceEmail(email: any): void {
    const index = this.emailsForInvoiceNotifications.indexOf(email);
    if (index >= 0) {
      this.emailsForInvoiceNotifications.splice(index, 1);
    }
    this.filterInvoiceEmailDropdown();
  }

  removeForGatewayEmail(email: any): void {
    const index = this.emailsForGatewayNotifications.indexOf(email);
    if (index >= 0) {
      this.emailsForGatewayNotifications.splice(index, 1);
    }
    this.filterGatewayEmailDropdown();
  }

  selectedForInvoiceEmail(event: MatAutocompleteSelectedEvent): void {
    this.emailsForInvoiceNotifications.push(event.option.viewValue);
    this.emailInput.nativeElement.value = '';
    this.emailCtrl.setValue(null);
    this.filterInvoiceEmailDropdown();
  }

  selectedForGatewayEmail(event: MatAutocompleteSelectedEvent): void {
    this.emailsForGatewayNotifications.push(event.option.viewValue);
    this.emailInput.nativeElement.value = '';
    this.emailCtrl.setValue(null);
    this.filterGatewayEmailDropdown();
  }

  private filterInvoiceEmailDropdown() {
    this.filterUserInvoiceEmailData = this.filterUserInvoiceEmailData.pipe(
      map((data: any[]) =>
        data.filter(
          record => !this.emailsForInvoiceNotifications.includes(record.name)
        )
      )
    );
  }

  private filterGatewayEmailDropdown() {
    this.filterUserGatewayEmailData = this.filterUserGatewayEmailData.pipe(
      map((data: any[]) =>
        data.filter(
          record => !this.emailsForGatewayNotifications.includes(record.name)
        )
      )
    );
  }

  private async loadLookUpData() {
    const [clearanceTypeData, userEmailData, salesPersonData]: any =
      await Promise.all([
        this.getMasterLookups(MasterLookup.clearanceType),
        this.getMasterLookups(MasterLookup.userEmail),
        this.getMasterLookups(MasterLookup.salesPerson),
      ]);
    this.clearanceTypeData = clearanceTypeData;
    this.clearanceTypeData.forEach((clearance: any) => {
      clearance.name = `${clearance.id} - ${clearance.name}`;
    });
    this.filteredClearanceTypeOptions = this.autocompleteValueChanges(
      MasterLookup.clearanceType,
      this.clearanceTypeData
    );
    this.userInvoiceEmailData = userEmailData;
    this.filterUserInvoiceEmailData = this.autocompleteValueChanges(
      MasterLookup.selectEmailsForInvoiceNotification,
      this.userInvoiceEmailData
    );
    this.userGatewayEmailData = userEmailData;
    this.filterUserGatewayEmailData = this.autocompleteValueChanges(
      MasterLookup.selectEmailsforGatewayNotification,
      this.userGatewayEmailData
    );
    this.salesPersonData = salesPersonData;
    this.filterSalesPersonOption = this.autocompleteValueChanges(
      MasterLookup.salesPerson,
      this.salesPersonData
    );
    this.patchAllDropdownValuesData(this.governmentInfoTabFormData);
  }

  private getMasterLookups(requestType: string) {
    return new Promise((resolve, reject) => {
      try {
        this.commonService
          .getMasterLookUp(requestType)
          .subscribe((response: ILookupDropdownResponse) => {
            if (response.data) {
              resolve(response.data);
            } else {
              resolve([]);
            }
          });
      } catch (error) {
        reject(new Error(''));
      }
    });
  }

  public onTypeSelected(selectedType: any, dropdownData: any, control: string) {
    const selectedDataType = dropdownData.find(
      (type: IDropdownResponse) => type.name === selectedType
    );
    if (selectedDataType) {
      this.accountGovtInfoForm.get(control)?.setValue(selectedDataType.id);
      this.dispatchBusinessFormChanges();
    }
  }

  public formAutocompleteValueChanges(
    controlName: string,
    apiControlName: string,
    dropdownResponse: any,
    formControlId?: string
  ) {
    this.commonService.getAutocompleteDropdownId(
      dropdownResponse,
      this.accountGovtInfoForm,
      controlName,
      apiControlName,
      false,
      formControlId
    );
  }

  private autocompleteValueChanges(
    controlName: string,
    dropdownData: IDropdownResponse[]
  ): Observable<IDropdownResponse[]> {
    const formControl = this.accountGovtInfoForm.get(
      controlName
    ) as FormControl;
    return formControl.valueChanges.pipe(
      startWith(''),
      map(val => {
        const filterValue =
          typeof val === DataTypes.String ? val.toLowerCase() : '';
        return dropdownData?.filter(
          item => item.name?.toLowerCase().includes(filterValue)
        );
      })
    );
  }

  private checkMode() {
    this.commonService.isCheckForMode
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: any) => {
        if (data === ModeIcons.view) {
          this.pageMode = ModeIcons.view;
          this.accountGovtInfoForm.disable();
          this.getIconsForCustomerServiceTableInfoMode(ModeIcons.view);
        } else {
          this.pageMode = data != '' ? data : ModeIcons.add;
          this.accountGovtInfoForm.enable();
        }
      });
  }

  private dataFetch() {
    this.commonService.savedBagData
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: any) => {
        if (data === pageType.dataBind) {
          this.fetchGovernmentInfoTabData();
        }
      });
  }

  private fetchGovernmentInfoTabData() {
    this.governmentInfoTabData$
      .pipe(takeUntil(this.destroy$))
      .subscribe(governmentInfoData => {
        this.governmentInfoTabFormData = governmentInfoData;
        this.accountGovtInfoForm.patchValue(this.governmentInfoTabFormData);
        this.getCustomerServiceGridData();
      });
  }

  private patchAllDropdownValuesData(formData: any) {
    const clearanceObject = this.clearanceTypeData?.find(
      (item: IDropdownResponse) => item.id === formData.clearanceTypeId
    );
    this.accountGovtInfoForm
      .get('clearanceType')
      ?.setValue(clearanceObject ? `${clearanceObject.name}` : '');
    const salesPersonObject = this.salesPersonData?.find(
      (item: IDropdownResponse) => item.id === formData.salesPersonId
    );
    this.accountGovtInfoForm
      .get('salesPerson')
      ?.setValue(salesPersonObject ? salesPersonObject.name : '');
  }

  private checkGovtInfoValidData() {
    this.accountsService.isCheckForGovernmentInfoData
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        if (data === true) {
          this.commonService.validateFormFields(this.accountGovtInfoForm);
          if (!this.getCustomerServiceFilteredData().length) {
            this.customerServiceGridForm.markAllAsTouched();
          }
          this.validateGovernmentInfoTab();
        } else {
          this.accountGovtInfoForm.markAsPristine();
        }
      });
  }

  public onSameAsInvoiceEmailChange(event: any) {
    if (event.checked) {
      this.emailsForGatewayNotifications = [
        ...this.emailsForInvoiceNotifications,
      ];
    } else {
      this.emailsForGatewayNotifications = [];
    }
  }

  private getCustomerServiceFilteredData() {
    return this.customerServiceTableDataSource.data.filter(
      (row: any) => !row.isInputRow
    );
  }

  private validateGovernmentInfoTab() {
    if (
      this.accountGovtInfoForm.valid &&
      this.getCustomerServiceFilteredData().length
    ) {
      this.accountsService.checkGovernmentInfoDataValid(true);
    } else {
      this.accountsService.checkGovernmentInfoDataValid(false);
    }
  }

  private getCustomerServiceGridData() {
    this.customerServiceTableDataSource.data = [
      ...this.governmentInfoTabFormData.customerService,
    ];
  }
}
