import { WidgetStateModel } from 'src/app/features/model/hawb.model';
import { AttachmentModal } from '../advanced-search-attachments/advance-search-attachment.config';
import { BagsModal } from '../advanced-search-bags/advanced-search-bags.config';
import { MiscellaneousModal } from '../advanced-search-commodity-information/miscellaneous/miscellaneous-advance-search.config';
import { CommodityModal } from '../advanced-search-commodity/advanced-search-commodity.config';
import { DetailsModal } from '../advanced-search-details/advance-search-details.config';
import { ManifestsModal } from '../advanced-search-manifests/advanced-search-manifests.config';
import { MawbsModal } from '../advanced-search-mawbs/advanced-search-mawbs.config';
import { NoteModal } from '../advanced-search-notes/advance-search-notes.config';
import { RoleModal } from '../advanced-search-roles/advanced-search-roles.config';
import { StatusModal } from '../advanced-search-statuscodes/advanced-search-statuscodes.config';
import { CrudModal } from '../crud/crud.config';

export class UpdateAttachmentModal {
  static readonly type = '[AttachmentModal] Update';
  constructor(public payload: AttachmentModal) {}
}

// Action to reset the attachmentModals array
export class ResetAttachmentModal {
  static readonly type = '[AttachmentModal] Reset';
}

export class UpdateDetailModal {
  static readonly type = '[DetailModal] Update';
  constructor(public payload: Partial<DetailsModal>) {}
}

export class ResetDetailModal {
  static readonly type = '[DetailModal] Reset';
}

export class UpdateCommodityModal {
  static readonly type = '[CommodityModal] Update';
  constructor(public payload: Partial<CommodityModal>) {}
}

export class ResetCommodityModal {
  static readonly type = '[CommodityModal] Reset';
}

export class AddOrUpdateRoleModal {
  static readonly type = '[RoleModal] Add or Update';
  constructor(public payload: RoleModal) {}
}

// Action to reset the RoleModals
export class ResetRoleModals {
  static readonly type = '[RoleModal] Reset';
}

export class AddOrUpdateNoteModal {
  static readonly type = '[NoteModal] Add or Update';
  constructor(public payload: NoteModal) {}
}

// Action to reset the NoteModals
export class ResetNoteModals {
  static readonly type = '[NoteModal] Reset';
}

export class UpdateMiscellaneousModal {
  static readonly type = '[MiscellaneousModal] Update';
  constructor(public payload: MiscellaneousModal) {}
}

// Action to reset the MiscellaneousModal
export class ResetMiscellaneousModal {
  static readonly type = '[MiscellaneousModal] Reset';
}

// Action to update the CrudModal
export class UpdateCrudModal {
  static readonly type = '[CrudModal] Update';
  constructor(public payload: Partial<CrudModal>) {}
}

// Action to reset the CrudModal to default state
export class ResetCrudModal {
  static readonly type = '[CrudModal] Reset';
}

// Action to update the BagsModal
export class UpdateBagsModal {
  static readonly type = '[BagsModal] Update';
  constructor(public payload: Partial<BagsModal>) {}
}

// Action to reset the BagsModal to default state
export class ResetBagsModal {
  static readonly type = '[BagsModal] Reset';
}

// Action to update the ManifestsModal
export class UpdateManifestsModal {
  static readonly type = '[ManifestsModal] Update';
  constructor(public payload: Partial<ManifestsModal>) {}
}

// Action to reset the ManifestsModal to default state
export class ResetManifestsModal {
  static readonly type = '[ManifestsModal] Reset';
}

// Action to update the MawbsModal
export class UpdateMawbsModal {
  static readonly type = '[MawbsModal] Update';
  constructor(public payload: Partial<MawbsModal>) {}
}

// Action to reset the MawbsModal to default state
export class ResetMawbsModal {
  static readonly type = '[MawbsModal] Reset';
}

// Action to update the StatusModal
export class UpdateStatusModal {
  static readonly type = '[StatusModal] Update';
  constructor(public payload: Partial<StatusModal>) {}
}

// Action to reset the StatusModal to default state
export class ResetStatusModal {
  static readonly type = '[StatusModal] Reset';
}

// Action to update the RoleModal
export class UpdateConsigneeModal {
  static readonly type = '[ConsigneeModal] Update';
  constructor(public payload: Partial<RoleModal>) {}
}

// Action to reset the RoleModal to default state
export class ResetConsigneeModal {
  static readonly type = '[ConsigneeModal] Reset';
}

export class UpdateResidential {
  static readonly type = '[Misc] Update Residential';
  constructor(public payload: string) {}
}

export class UpdateSignatureReq {
  static readonly type = '[Misc] Update Signature Required';
  constructor(public payload: string) {}
}

export class ResetMiscState {
  static readonly type = '[Misc] Reset State';
}
export class ResetAllModals {
  static readonly type = '[Modal] Reset All';
}

export class UpdateWidgetFormValues {
  static readonly type = '[Widget] Update Values';
  constructor(public payload: WidgetStateModel) {}
}

export class ResetWidgetFormValues {
  static readonly type = '[Widget] Reset Values';
}
