<div class="summaryPage-Preview-Window">
  <div
    *ngIf="isPrintWarning"
    class="print-warning-text {{
      isPrintClicked ? 'hide-warningText' : 'show-warningText'
    }}">
    {{ warningMessage }}
  </div>

  <div *ngIf="dataSource" class="print-page {{ pageSize }}">
    <div class="logo" title="PACTrak">
      <img src="assets/Logo.svg" alt="" />
    </div>

    <div>
      <table class="ibc-print-table">
        <thead>
          <tr>
            <ng-container *ngFor="let item of summaryCustomiseTableColumns">
              <th
                *ngIf="
                  commonTableService.displaySelectedColumnsToTable(
                    item.key,
                    summaryCustomiseTableColumns,
                    displayedColumns
                  )
                "
                [style.flex]="'0 0 ' + item.defaultPrintWidth + 'px'">
                <div class="ibc-body-text-large-bold table-text">
                  {{ item.label }}
                </div>
              </th>
            </ng-container>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let element of dataSource">
            <ng-container *ngFor="let item of summaryCustomiseTableColumns">
              <td
                *ngIf="
                  commonTableService.displaySelectedColumnsToTable(
                    item.key,
                    summaryCustomiseTableColumns,
                    displayedColumns
                  )
                "
                [style.flex]="'0 0 ' + item.defaultPrintWidth + 'px'">
                <div
                  class="ibc-body-text-regular table-text {{
                    commonTableService.isHyperlink(item.label, isFrom)
                      ? 'text-hyperlink'
                      : ''
                  }}">
                  {{
                    item.key !== 'date'
                      ? element[item.key]
                      : (element.date | date: dateFormat)
                  }}
                </div>
              </td>
            </ng-container>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <button
    class="ibc-medium-button ibc-primary-default-button cursor preview-print-btn {{
      isPrintClicked ? 'hide-btn' : 'show-btn'
    }}"
    (click)="print()">
    <span class="ibc-body-text-regular">
      {{ popupButtons.Print }}
    </span>
  </button>
</div>
