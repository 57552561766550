<div
  class="ibc-application-header {{ isOverlayOpened ? 'overlay-opened' : '' }}">
  <div class="logo" title="PACTrak">
    <img src="assets/Logo.svg" alt="" />
    <app-svg-icon
      class="logout"
      [iconName]="'logout'"
      [isStaticSvg]="true"
      [isFrom]="'Page'"
      matTooltip="Logout"
      (click)="logout()"
      (keydown)="$event.stopPropagation()"
      aria-controls="logout"></app-svg-icon>
  </div>
</div>
