import { Directive, HostListener, Input } from '@angular/core';
import { tabKeyboardEvent } from '../utility/constants';
import { CommonService } from '../services/common.service';

@Directive({
  selector: '[appOnOptionSelectedTabNewInput]'
})
export class OnOptionSelectedTabNewInputDirective {
  @Input() controlName!: string;
  @Input() controlId!: string;
  constructor(public commonService: CommonService) { }


  @HostListener('mousedown', ['$event'])
  keyDown(event: Event): void {
    const target = event.target as HTMLInputElement;
    if (target?.value) {
      setTimeout(() => {
        target.select();
      }, 0);
    }
    event.stopPropagation();
  }

  @HostListener('optionSelected', ['$event'])
  onOptionSelected(): void {
    const tabEvent = tabKeyboardEvent;
    const focusedElement = document.activeElement;
    if (focusedElement) {
      focusedElement.dispatchEvent(tabEvent);
    }
    
    const nextInput = this.commonService.getNextInputElement(this.controlId);
    if (nextInput) {
      setTimeout(() => {
        (nextInput as HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement).focus();
      }, 0);
    }
  }
}
