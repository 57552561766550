import { searchDataParam } from '../utility/constants';

export const requiredCommodityKeys = [
  'commodityNumber',
  'hsCode',
  'value',
  'description',
  'countryOfManufacturer',
];

export const defaultBagPagination = {
  paging: {
    fromIndex: 0,
    toIndex: 10,
    sortingOrder: 'desc',
    sortingField: searchDataParam.bagId,
  },
  searchText: '',
};

export const paperSizes = [
  { value: 'Letter', viewValue: 'Letter' },
  { value: 'Legal', viewValue: 'Legal' },
  { value: 'A4', viewValue: 'A4' },
];

export const paperSizesList = {
  Letter: 'Letter',
  Legal: 'Legal',
  A4: 'A4',
};

export const paperSizesWidth = {
  // 20px padding also added for width
  Letter: 856,
  Legal: 871,
  A4: 856,
};

export const NotesHeaders = [
  {
    key: 'note',
    label: 'Note',
    isSelected: true,
    defaultPrintWidth: 200,
  },
  {
    key: 'noteType',
    label: 'Notes Type',
    isSelected: true,
    defaultPrintWidth: 120,
  },
  {
    key: 'createdBy',
    label: 'Created By',
    isSelected: true,
    defaultPrintWidth: 180,
  },
  {
    key: 'dateAndTime',
    label: 'Date and Time',
    isSelected: true,
    defaultPrintWidth: 180,
  },
];

export const AttachmentsHeaders = [
  {
    key: 'attachmentType',
    label: 'Type',
    isSelected: true,
    defaultPrintWidth: 170,
  },
  {
    key: 'fileName',
    label: 'File Name',
    isSelected: true,
    defaultPrintWidth: 200,
  },
  {
    key: 'description',
    label: 'Description',
    isSelected: true,
    defaultPrintWidth: 150,
  },
  {
    key: 'createdBy',
    label: 'Created By',
    isSelected: true,
    defaultPrintWidth: 170,
  },
  {
    key: 'dateAndTime',
    label: 'Date and Time',
    isSelected: true,
    defaultPrintWidth: 170,
  },
];
