<div class="status-codes-attachment-container">
  <mat-form-field appearance="outline" class="mat-md">
    <mat-label>Attachment</mat-label>
    <input readonly matInput (click)="attachmentFileInput.click()" />
    <input hidden #attachmentFileInput type="file" accept=".csv,.xls,.pdf" />
  </mat-form-field>
  <mat-form-field appearance="outline" class="mat-md">
    <mat-label>Description</mat-label>
    <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="2"></textarea>
  </mat-form-field>
</div>
