import { Injectable } from '@angular/core';
import {
  StaticSvgIconProp,
  SvgIcon,
  SvgIconProp,
} from '../models/dynamic.model';
import {
  StaticIcons,
  StaticPopupIcons,
  StaticSnackbarIcons,
  SvgConstants,
} from '../utility/svg-constants';
import {
  ActionIcons,
  actionText,
  BasicSearchValues,
  ModalPopupMessages,
  SingleDeleteHeaderText,
} from '../utility/constants';

@Injectable({
  providedIn: 'root',
})
export class SvgIconGenerationService {
  constructor() {}

  public generateIconList(iconList: SvgIconProp[] | undefined) {
    if (!iconList || !Array.isArray(iconList)) {
      // If iconList is undefined or not an array, return an empty array
      return [];
    }
    
    const svgIconsList: SvgIcon[] = [];
  
    for (const item of iconList) {
      const iconPath = SvgConstants[item.name as keyof typeof SvgConstants];
      const tooltip = this.generateTooltip(item.name);
      
      const data = 
        item.name === 'print' || item.name === 'blockShipment' || item.name === 'unblock'
        ? {
            path: iconPath,
            pathAdditional: SvgConstants[(item.name + 1) as keyof typeof SvgConstants],
            pathAdditional1: SvgConstants[(item.name + 2) as keyof typeof SvgConstants],
            disabled: item.isDisabled,
            iconClass: item.name + 'Icon',
            width: item.width,
            height: item.height,
            toolTip: tooltip,
            name: item.name,
          }
        : {
            path: iconPath,
            disabled: item.isDisabled,
            iconClass: item.name + 'Icon',
            width: item.width,
            height: item.height,
            toolTip: tooltip,
            name: item.name,
          };
        
      svgIconsList.push(data);
    }
    return svgIconsList;
  } 

  public generateStaticIcon(iconName: string, isFrom: string) {
    const iconData =
      isFrom === 'Popup'
        ? StaticPopupIcons.filter(val => val.name === iconName)
        : isFrom === 'Snackbar'
          ? StaticSnackbarIcons.filter(val => val.name === iconName)
          : StaticIcons.filter(val => val.name === iconName);
    let iconClassName;
    if (isFrom === 'Snackbar') {
      iconClassName = iconName + 'SnackbarIcon';
    } else if (isFrom === 'Popup') {
      iconClassName = iconName + 'PopupIcon';
    } else {
      iconClassName = iconName + 'Icon';
    }
    const pathArray = [];
    for (let i = 1; i <= iconData[0].totalPath; i++) {
      const name = iconData[0].totalPath > 1 ? iconName + i : iconName;
      pathArray.push(SvgConstants[name as keyof typeof SvgConstants]);
    }
    const staticSvgIcon: StaticSvgIconProp = {
      path: pathArray,
      iconClass: iconClassName,
      width: iconData[0].width,
      height: iconData[0].height,
      name: iconName,
      pathClass: iconName + 'Path',
      totalPath: iconData[0].totalPath,
    };
    return staticSvgIcon;
  }

  private generateTooltip(name: string): string {
    let tooltip = name[0].toUpperCase() + name.slice(1).toLowerCase();
    switch (name) {
      case 'addCom':
        tooltip = ActionIcons.AddTooltip;
        break;
      case 'downloadFile':
        tooltip = ActionIcons.download;
        break;
      case 'blockShipment':
        tooltip = ActionIcons.block;
        break;
      case 'advancedSearchTriggerHeader':
        tooltip = SingleDeleteHeaderText.Search;
        break;
      case 'advancedSearchPinHeader':
        tooltip = actionText.pin;
        break;
      case 'advancedSearchHamburgerHeader':
        tooltip = BasicSearchValues.hamburger;
        break;
      case 'advancedSearchHelpHeader':
        tooltip = BasicSearchValues.helperText;
        break;
      case 'bulkBag':
        tooltip = ModalPopupMessages.bulkBag;
        break;
      case 'bulkGateway':
        tooltip = ModalPopupMessages.bulkGatewayHeader;
        break;
      case 'createDuplicateShipment':
        tooltip = ModalPopupMessages.createDuplicateShipment;
        break;
      default:
        break;
    }
    return tooltip;
  }
}
