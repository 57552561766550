import { Component } from '@angular/core';
import { MaterialModule } from '../../material.module';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-statuscode-attachments',
  standalone: true,
  imports: [MaterialModule, CommonModule],
  templateUrl: './statuscode-attachments.component.html',
  styleUrl: './statuscode-attachments.component.scss',
})
export class StatuscodeAttachmentsComponent {}
