import {
  FormConfig,
  IBasicSearchPaginationProps,
} from 'src/app/shared/models/dynamic.model';
import { Widget } from '../../model/hawb.model';
import {
  ComponentMapping,
  FlightGridColumns,
  IFlightMiscellaneousData,
  IMawbAttachmentData,
  IMawbInformationData,
  IMawbMiscellaneousData,
  IMawbNotesData,
  ManifestGridColumns,
} from '../../model/mawb.model';
import { MiscellaneousComponent } from './mawb-information/miscellaneous/miscellaneous.component';
import { TableColumnsProp } from 'src/app/shared/models/tables.model';
import {
  AccordionTabs,
  searchDataParam,
} from 'src/app/shared/utility/constants';

export const MAWBSearchConfig: IBasicSearchPaginationProps = {
  fromIndex: 0,
  toIndex: 10,
  sortingOrder: 'desc',
  sortingField: 'mawb',
};

export const widgets: Widget[] = [
  {
    id: 1,
    title: 'Number of Flights',
    value: '--',
    extraClasses: 'pt-6',
    iconName: 'flightNumbers',
    name: 'noOfFlights',
    controlName: 'numberOfFlights',
  },
  {
    id: 2,
    title: 'Number of Manifests',
    value: '--',
    extraClasses: 'pt-6',
    iconName: 'manifestNumbers',
    name: 'noOfManifests',
    controlName: 'numberOfManifest',
  },
];

export const mawbInfoFormFieldsConfig: FormConfig = {
  fields: [
    {
      id: 'mawbDate',
      label: 'Manifest Date',
      type: 'input',
      required: true,
      requiredError: 'MAWB Date is required',
    },
    {
      id: 'mawbExt',
      type: 'input',
      label: 'MAWB',
      required: true,
      requiredError: 'MAWB is required',
      minError: '11 numbers is required',
      minValue: 11,
      maxValue: 11,
      allowNumeric: true,
    },
    {
      id: 'originID',
      type: 'input',
      label: 'Origin',
      minValue: 1,
      maxValue: 126,
      required: true,
      requiredError: 'Origin Airport is required',
      noInitialWhiteSpace: true,
      isCheckForMaxValue: true,
    },
    {
      id: 'originAirport',
      type: 'input',
      label: 'Origin',
    },
    {
      id: 'destinationID',
      type: 'input',
      label: 'Destination',
      minValue: 1,
      maxValue: 126,
      required: true,
      requiredError: 'Destination Airport is required',
      noInitialWhiteSpace: true,
      isCheckForMaxValue: true,
    },
    {
      id: 'destinationAirport',
      type: 'input',
      label: 'Destination',
    },
    {
      id: 'clearanceType',
      type: 'input',
      label: 'Clearance Type',
      minValue: 1,
      maxValue: 8,
    },
    {
      id: 'clearanceTypeCode',
      type: 'input',
      label: 'Clearance Type',
      minValue: 1,
      maxValue: 20,
      isCheckForMaxValue: true,
    },
    {
      id: 'airlineName',
      type: 'input',
      label: 'Airline Code and Name',
      isDisableField: true,
    },
    {
      id: 'airlineId',
      type: 'input',
      label: 'Airline Name',
    },
    {
      id: 'agentName',
      type: 'input',
      label: 'Agent Code or Name',
      minValue: 1,
      maxValue: 126,
      isCheckForMaxValue: true,
    },
    {
      id: 'receivingAgent',
      type: 'input',
      label: 'Agent Code and Name',
    },
    {
      id: 'information',
      type: 'input',
      label: 'Information',
    },
    {
      id: 'mawbId',
      type: 'input',
      label: 'Mawb Id',
    },
  ],
};

export const gridMawbSummaryColumns: TableColumnsProp[] = [
  {
    key: 'mawb',
    label: 'MAWB',
    isSelected: true,
    defaultView: true,
    defaultPrintWidth: 150,
  },
  {
    key: 'origin',
    label: 'Origin',
    isSelected: true,
    defaultView: true,
    defaultPrintWidth: 130,
  },
  {
    key: 'destination',
    label: 'Destination',
    isSelected: true,
    defaultView: true,
    defaultPrintWidth: 130,
  },
  {
    defaultPrintWidth: 120,
    key: 'isPublished',
    isSelected: true,
    defaultView: true,
    label: 'Published',
  },
  {
    key: 'agentCode',
    isSelected: true,
    label: 'Agent Code',
    defaultView: true,
    defaultPrintWidth: 120,
  },
  {
    key: 'airlineCode',
    isSelected: true,
    defaultView: true,
    label: 'Airline Code',
    defaultPrintWidth: 130,
  },
  {
    label: 'Clearance Type',
    isSelected: true,
    key: 'clearanceType',
    defaultView: true,
    defaultPrintWidth: 130,
  },
  {
    key: 'agentName',
    defaultView: true,
    label: 'Agent Name',
    isSelected: true,
    defaultPrintWidth: 130,
  },
  {
    key: 'airlineName',
    isSelected: true,
    label: 'Airline Name',
    defaultView: true,
    defaultPrintWidth: 130,
  },
];

export const componentMappings: ComponentMapping[] = [
  { title: 'Miscellaneous', componentType: MiscellaneousComponent },
  { title: AccordionTabs.StatusCodes, componentType: '' },
  { title: 'Notes', componentType: '' },
  { title: 'Events', componentType: '' },
  { title: 'Attachments', componentType: '' },
  { title: 'Details', componentType: '' },
];

export const miscellaneousFormFieldsConfig: FormConfig = {
  fields: [
    {
      id: 'usCbpEccf86EntryNumber',
      type: 'input',
      label: 'US CBP ECCF 86 Entry Number',
      allowNumeric: true,
      minValue: 1,
      maxValue: 10,
    },
    {
      id: 'clearanceAgent',
      type: 'input',
      label: 'Clearance Agent',
      allowAlphaNumeric: true,
      minValue: 1,
      maxValue: 10,
    },
  ],
};

/* Manifest Grid Input Row Data Configuration */
export const manifestGridInputRowData: ManifestGridColumns[] = [
  {
    manifestId: '',
    manifestNumber: '',
    date: '',
    bags: 0,
    shipments: 0,
    isInputRow: true,
  },
];

/* MAWB - Manifest Grid Columns */
export const MAWBManifestTableGridColumns: TableColumnsProp[] = [
  {
    key: 'manifestNumber',
    label: 'Number',
    isSelected: true,
    defaultView: true,
    defaultPrintWidth: 150,
  },
  {
    key: 'date',
    label: 'Date',
    isSelected: true,
    defaultView: true,
    defaultPrintWidth: 150,
  },
  {
    key: 'bags',
    label: 'Bags',
    isSelected: true,
    defaultView: true,
    defaultPrintWidth: 150,
  },
  {
    key: 'shipments',
    label: 'Shipments',
    isSelected: true,
    defaultView: true,
    defaultPrintWidth: 150,
  },
];

/* MAWB - Flight Grid Columns */
export const MAWBFlightTableGridColumns: TableColumnsProp[] = [
  {
    key: 'airlineCode',
    label: 'Airline Code',
    isSelected: true,
    defaultView: true,
    defaultPrintWidth: 150,
  },
  {
    key: 'flightNumber',
    label: 'Flight Number',
    isSelected: true,
    defaultView: true,
    defaultPrintWidth: 150,
  },
  {
    key: 'slacCount',
    label: 'SLAC Count',
    isSelected: true,
    defaultView: true,
    defaultPrintWidth: 150,
  },
  {
    key: 'slacWeight',
    label: 'SLAC Weight',
    isSelected: true,
    defaultPrintWidth: 150,
    defaultView: true,
  },
  {
    key: 'arrivalDate',
    label: 'Arrival Date',
    defaultPrintWidth: 150,
    isSelected: true,
    defaultView: true,
  },
  {
    defaultPrintWidth: 150,
    key: 'mawbPart',
    label: 'MAWB Part',
    isSelected: true,
    defaultView: true,
  },
];

/* Flight Grid Input Row Data Configuration */
export const flightGridInputRowData: FlightGridColumns[] = [
  {
    airlineCode: '',
    flightNumber: '',
    slacCount: '',
    slacWeight: '',
    arrivalDate: '',
    mawbPart: '',
    isInputRow: true,
  },
];

/* Flight Grid Form Config */
export const mawbFlightConfig: FormConfig = {
  fields: [
    {
      id: 'airlineCode',
      type: 'input',
      label: 'Airline Code',
      required: true,
      requiredError: 'Airline Code is required',
    },
    {
      id: 'airlineId',
      type: 'input',
      label: 'Airline Id',
    },
    {
      id: 'flightNumber',
      type: 'input',
      label: 'Flight Number',
      minValue: 3,
      maxValue: 6,
      required: true,
      requiredError: 'Flight Number is required',
      minError: 'Flight Number must have minimum 3 characters',
    },
    {
      id: 'slacCount',
      type: 'input',
      label: 'SLAC Count',
      required: false,
      allowNumeric: true,
      maxValue: 9,
    },
    {
      id: 'slacWeight',
      type: 'input',
      label: 'SLAC Weight',
      required: false,
    },
    {
      id: 'arrivalDate',
      type: 'input',
      label: 'Arrival Date',
      required: true,
      requiredError: 'Arrival Date is required',
    },
    {
      id: 'mawbPart',
      type: 'input',
      label: 'dimW',
      required: true,
      requiredError: 'MAWB Part is required',
    },
    {
      id: 'id',
      type: 'input',
      label: 'ID',
    },
    {
      id: 'alreadyaddedAPI',
      type: 'input',
      label: 'ID',
    },
  ],
};

export const defaultMawbMiscellaneousData = (): IMawbMiscellaneousData => ({
  usCbpEccf86EntryNumber: '',
  clearanceAgent: '',
});

export const MAWBExcelHeaders: string[] = [
  'MAWB',
  'Origin',
  'Destination',
  'Published',
  'Agent Code',
  'Airline Code',
  'Clearance Type',
  'Agent Name',
  'Airline Name',
];

export const defaultMawbInformationData = (): IMawbInformationData => ({
  mawbId: 0,
  mawbExt: '',
  mawbDate: '',
  originAirport: 0,
  destinationAirport: 0,
  airlineId: 0,
  airlineName: '',
  information: '',
  receivingAgent: 0,
  clearanceType: '',
  agentCode: 0,
  agentName: '',
  clearanceTypeCode: '',
  destinationID: '',
  originID: '',
  notes: defaultMawbNotesData(),
  attachmentList: defaultMawbAttachmentsData(),
  miscellaneous: defaultMawbMiscellaneousData(),
  eventsList: [],
  manifestList: [],
  flights: [],
});

export const defaultMawbNotesData = (): IMawbNotesData => ({
  ibc: [],
  customer: [],
});

export const defaultMawbAttachmentsData = (): IMawbAttachmentData[] => [
  {
    id: '',
    type: '',
    fileName: '',
    signedUrl: '',
    action: '',
    attachmentTypeValuId: '',
  },
];

export const defaultFlightMiscellaneousData = (): IFlightMiscellaneousData => ({
  cargoOperator: '',
  firstUsAirport: '',
  firstUsAirportDate: '',
  id: null,
  flightId: null,
  isSavedData: false,
});

export const defautMawbPagination = {
  fromIndex: 0,
  toIndex: 10,
  sortingOrder: searchDataParam.asc,
  sortingField: searchDataParam.mawbId,
};

export const MAWBInfoSectionHeaders: string[] = [
  'MAWB Information',
  'MAWB Miscellaneous',
  'Notes',
  'Events',
  'Attachments',
  'Details',
  'Manifest(s) linked',
  'Flight(s) linked',
];

export const MAWBInfoSectionHeaderKeys: string[] = [
  'mawbInfo',
  'mawbmiscellaneous',
  'notes',
  'events',
  'attachments',
  'details',
  'mawbManifests',
  'mawbFlights',
];

export const MAWBInfoHeaders = [
  {
    key: 'mawbDate',
    label: 'MAWB Date',
    isSelected: true,
    defaultPrintWidth: 120,
  },
  {
    key: 'mawbExt',
    label: 'MAWB',
    isSelected: true,
    defaultPrintWidth: 130,
  },
  {
    key: 'originAirport',
    label: 'Origin Airport',
    isSelected: true,
    defaultPrintWidth: 150,
  },
  {
    key: 'destinationAirport',
    label: 'Destination Airport',
    isSelected: true,
    defaultPrintWidth: 160,
  },
  {
    key: 'clearanceType',
    label: 'Clearance Type',
    isSelected: true,
    defaultPrintWidth: 130,
  },
  {
    key: 'airlineName',
    label: 'Airline',
    isSelected: true,
    defaultPrintWidth: 120,
  },
  {
    key: 'agentName',
    label: 'Agent',
    isSelected: true,
    defaultPrintWidth: 120,
  },
  {
    key: 'information',
    label: 'Information',
    isSelected: true,
    defaultPrintWidth: 120,
  },
];

export const miscellaneousHeaders = [
  {
    key: 'usCbpEccf86EntryNumber',
    label: 'US CBP ECCF 86 Entry Number',
    isSelected: true,
    defaultPrintWidth: 240,
  },
  {
    key: 'clearanceAgent',
    label: 'Clearance Agent',
    isSelected: true,
    defaultPrintWidth: 150,
  },
];

export const MAWBDetailsHeaders = [
  {
    key: 'mawbId',
    label: 'MAWB ID',
    isSelected: true,
    defaultPrintWidth: 150,
  },
];

export const mawbWidgetTableGridColumns = [
  {
    key: 'noOfFlights',
    label: 'Number of Flights',
    isSelected: true,
    defaultPrintWidth: 160,
  },
  {
    key: 'noOfManifests',
    label: 'Number of Manifests',
    isSelected: true,
    defaultPrintWidth: 180,
  },
];
