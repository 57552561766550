import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { SnakbarService } from 'src/app/shared/services/snakbar.service';
import { SpinnerService } from '../../shared/services/spinner.service';
import {
  ErrorHandlerConstants,
  StatusCodes,
} from 'src/app/shared/utility/constants';
import { throwError } from 'rxjs';
import { IErrorResponse } from 'src/app/shared/models/dynamic.model';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService implements ErrorHandler {
  getErrorStatus: string | null = '';

  constructor(
    private readonly snakbarService: SnakbarService,
    private readonly spinnerService: SpinnerService
  ) {}

  public handleError(error: HttpErrorResponse) {
    let errorMessage = {};
    if ((error.error && error.error.error) || error.status === 0) {
      errorMessage = ErrorHandlerConstants.Connective_Error;
      this.spinnerService.hideSpinner();
      if (error.status == 0) {
        errorMessage = ErrorHandlerConstants.SessionTimout;
        this.snakbarService.openSnackBar(
          ErrorHandlerConstants.Initial_Error_Text + errorMessage
        );
        //this.signInService.logout();
      } else {
        this.snakbarService.openSnackBar(
          ErrorHandlerConstants.Initial_Error_Text + errorMessage
        );
      }
    } else {
      switch (error.status) {
        case 502:
          errorMessage =
            error.error && error.error.message
              ? `
            ${this.getErrorMessage(error.error.message)}`
              : ErrorHandlerConstants.Error_502;
          this.snakbarService.openSnackBar(
            ErrorHandlerConstants.Initial_Error_Text + errorMessage
          );
          this.spinnerService.hideSpinner();
          break;
        case 504:
          errorMessage =
            error.error && error.error.message
              ? `
            ${this.getErrorMessage(error.error.message)}`
              : ErrorHandlerConstants.Error_500;
          this.snakbarService.openSnackBar(
            ErrorHandlerConstants.Initial_Error_Text + errorMessage
          );
          this.spinnerService.hideSpinner();
          break;
        case 404:
          errorMessage =
            error.error && error.error?.message
              ? `
              ${this.getErrorMessage(error.error.message)}`
              : error.error && error.error?.errors?.length > 0
                ? `
              ${this.getErrorMessage(error.error?.errors[0].errorMessage)}`
                : ErrorHandlerConstants.Error_400;
          this.snakbarService.openSnackBar(
            ErrorHandlerConstants.Initial_Error_Text + errorMessage
          );
          this.spinnerService.hideSpinner();
          break;
        case 401:
          errorMessage = ErrorHandlerConstants.SessionTimout;
          this.snakbarService.openSnackBar(
            ErrorHandlerConstants.Initial_Error_Text + errorMessage
          );
          this.spinnerService.hideSpinner();

          break;
        case 400:
          errorMessage =
            error.error && error.error.message
              ? error.error.message
              : ErrorHandlerConstants.Default_Error;
          //   ? error.error.errors[0].errorMessage
          //   : ErrorHandlerConstants.Default_Error;
          // error.error && error.error.errors && error.error.errors.length > 0
          //   ? error.error.errors[0].errorMessage
          //   : ErrorHandlerConstants.Default_Error;
          // this.getErrorPopup(errorMessage);
          this.snakbarService.openSnackBar(
            ErrorHandlerConstants.Initial_Error_Text + errorMessage
          );
          this.spinnerService.hideSpinner();
          break;
        default:
          this.getErrorStatus =
            error.error && error.error.errors && error.error.errors.length > 0
              ? `${this.getErrorMessage(error.error.errors[0].errorMessage)}`
              : error.status !== undefined
                ? ErrorHandlerConstants.Default_Error
                : null;
          if (this.getErrorStatus) {
            return this.snakbarService.openSnackBar(this.getErrorStatus);
          }
          this.spinnerService.hideSpinner();
          break;
      }
    }
    return throwError(
      () => new Error('Something bad happened; please try again later.')
    );
  }

  public handleApiError(response: IErrorResponse) {
    switch (response.statusCode || response.status) {
      case StatusCodes.BadRequest:
      case StatusCodes.Unauthorised:
      case StatusCodes.Forbidden:
      case StatusCodes.NotFound:
      case StatusCodes.InternalServerError:
        this.snakbarService.openCustomisedSnackBar(response.message, 'error');
        break;
      default:
        break;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private getErrorMessage(error: any): string {
    let errorString = '';
    if (error) {
      if (error instanceof Object) {
        Object.keys(error).forEach(element => {
          errorString += `${element}: ${error[element]},\n`;
        });
      } else if (typeof error === ErrorHandlerConstants.String_Declare) {
        errorString = error;
      }
    }
    const constErrorString = errorString;
    this.snakbarService.openSnackBar(
      ErrorHandlerConstants.Initial_Error_Text + constErrorString
    );
    this.spinnerService.hideSpinner();
    return errorString;
  }
}
