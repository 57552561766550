import { Injectable } from '@angular/core';
import { IAccountsInformationData } from '../model/sm-accounts';
import { BehaviorSubject, Observable } from 'rxjs';
import { URLConstants } from 'src/app/shared/utility/url-constants';
import { BaseService } from 'src/app/core/services/base.httpService';

@Injectable({
  providedIn: 'root',
})
export class AccountsService {
  public ischeckAccordionTabsInfoValid = new BehaviorSubject<boolean>(false);
  public isCheckForAddressInfoData = new BehaviorSubject<boolean>(false);
  public isCheckForGovernmentInfoData = new BehaviorSubject<boolean>(false);
  public requiredAccountInfoData = new BehaviorSubject<any>({});
  public isFromPageSummary = new BehaviorSubject<string>('');
  public isCheckForGovernmentInfoDataValid = new BehaviorSubject<boolean>(
    false
  );

  constructor(private readonly baseService: BaseService) {}

  public checkAccordionTabsInfoValid(data: boolean) {
    this.ischeckAccordionTabsInfoValid.next(data);
  }

  public checkAddressInfoData(data: boolean) {
    this.isCheckForAddressInfoData.next(data);
  }

  public checkGovernmentInfoData(data: boolean) {
    this.isCheckForGovernmentInfoData.next(data);
  }

  public checkGovernmentInfoDataValid(data: boolean) {
    this.isCheckForGovernmentInfoDataValid.next(data);
  }

  public createAccount(data: IAccountsInformationData): Observable<any> {
    return this.baseService.initiateApiRequest(
      'PUT',
      URLConstants.createAccount,
      data,
      'json'
    );
  }

  public updateAccount(data: IAccountsInformationData): Observable<any> {
    return this.baseService.initiateApiRequest(
      'POST',
      URLConstants.updateAccount,
      data,
      'json'
    );
  }

  public getSearchData(event: any): Observable<any> {
    return this.baseService.initiateApiRequest(
      'POST',
      URLConstants.accountSearch,
      event,
      '',
      'json'
    );
  }

  public setRequiredDataForAccountInfoPage(data: any): void {
    this.requiredAccountInfoData.next(data);
  }

  public setPageFrom(data: string) {
    this.isFromPageSummary.next(data);
  }

  public checkAccountExistence(accountId: number): Observable<any> {
    const url = URLConstants.checkAccountInfo + accountId;
    return this.baseService.initiateApiRequest('GET', url, '', 'json');
  }
}
