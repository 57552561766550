import { HawbMiscellaneousProps } from '../../models/dynamic.model';
import { ShipmentsMiscellaneousMessages } from '../../utility/constants';

export const HawbMiscellaneousContent: HawbMiscellaneousProps[] = [
  {
    title: ShipmentsMiscellaneousMessages.ResidentialHeader,
    content: ShipmentsMiscellaneousMessages.Residential,
  },
  {
    title: ShipmentsMiscellaneousMessages.SignatureRequiredHeader,
    content: ShipmentsMiscellaneousMessages.SignatureRequired,
  },
];

export interface MainMiscModal {
  residential: string;
  signature_req: string;
}

export const defaultMiscsModal: MainMiscModal = {
  residential: '',
  signature_req: '',
};

export interface MainMiscStateModel {
  detailMiscModal: MainMiscModal;
}

// Initial state
export const defaultMiscState: MainMiscStateModel = {
  detailMiscModal: defaultMiscsModal,
};