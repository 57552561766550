import {
  FormConfig,
  IBasicSearchPaginationProps,
} from 'src/app/shared/models/dynamic.model';
import { MiscellaneousComponent } from './bag-information/miscellaneous/miscellaneous.component';
import {
  ComponentMapping,
  IBagAttachmentData,
  IBagInformationData,
  IBagLinkedShipmentData,
  IBagMiscellaneousData,
  IBagNotesData,
  ShipmentGridColumnData,
  TableColumns,
  Widget,
} from '../../model/bag.model';
import { searchDataParam } from 'src/app/shared/utility/constants';

/* Default Widget Configuration */
export const widgets: Widget[] = [
  {
    id: 1,
    title: 'Total Weight',
    value: '--',
    extraClasses: 'pt-6',
    controlName: 'totalWeight',
    iconName: 'weight',
    name: 'totalWeight',
  },
  {
    id: 2,
    extraClasses: 'pt-6',
    iconName: 'hawbNumbers',
    name: 'totalNoOfHawbs',
    controlName: 'noOfShipment',
    title: 'Number of Shipments',
    value: '--',
  },
];

/* Bag Information Form Fields Configuration */
export const bagInfoFormFieldsConfig: FormConfig = {
  fields: [
    {
      id: 'bagNumber',
      type: 'input',
      label: 'Bag',
      required: true,
      requiredError: 'Bag Ext is required',
      minValue: 3,
      maxValue: 126,
      minError: 'Invalid Bag entered',
      allowAlphaNumericWithDotUnderscoreDashSpecialCharacters: true,
    },
    {
      id: 'origin',
      type: 'input',
      label: 'Origin',
      required: true,
      requiredError: 'Origin is required',
      minValue: 1,
      maxValue: 126,
      noInitialWhiteSpace: true,
      isCheckForMaxValue: true,
    },
    {
      id: 'destination',
      type: 'input',
      label: 'Destination',
      required: true,
      requiredError: 'Destination is required',
      minValue: 1,
      maxValue: 126,
      noInitialWhiteSpace: true,
      isCheckForMaxValue: true,
    },
    {
      id: 'manifestNumber',
      type: 'input',
      label: 'Manifest',
    },
    {
      id: 'originId',
      type: 'input',
      label: 'Origin Id',
    },
    {
      id: 'destinationId',
      type: 'input',
      label: 'Destination Id',
    },
    {
      id: 'flightNumber',
      type: 'input',
      label: 'Flight Number',
      isDisableField: true,
    },
    { id: 'airline', type: 'input', label: 'Airline', isDisableField: true },
    { id: 'bagId', type: 'input', label: 'Bag Id', isDisableField: true },
    { id: 'description', type: 'input', label: 'Description', maxValue: 1000 },
  ],
};

/* Component Mappings Configuration */
export const componentMappings: ComponentMapping[] = [
  { title: 'Miscellaneous', componentType: MiscellaneousComponent },
  { title: 'Statuscodes', componentType: '' },
  { title: 'Notes', componentType: '' },
  { title: 'Events', componentType: '' },
  { title: 'Attachments', componentType: '' },
  { title: 'Details', componentType: '' },
];

/* Miscellaneous Form Fields Configuration */
export const miscellaneousFormfieldsConfig: FormConfig = {
  fields: [
    {
      id: 'tareWeight',
      type: 'input',
      label: 'Tare Weight(lb)',
    },
  ],
};

/* Piece Table Fields Configuration */
export const pieceTablefieldsConfig: FormConfig = {
  fields: [
    {
      id: 'pieceExt',
      type: 'input',
      label: 'Piece Ext',
    },
  ],
};

/* Piece Grid Input Row Data Configuration */
export const shipmentGridInputRowData: ShipmentGridColumnData[] = [
  {
    id: '',
    weight: '',
    dimensions: '',
    hawbExt: '',
    isInputRow: true,
    shipper: '',
    consignee: '',
  },
];

/* Search Form Fields Configuration */
export const searchFormfieldsConfig: FormConfig = {
  fields: [
    {
      id: 'bagId',
      type: 'input',
      label: 'Bag',
      minValue: 1,
      maxValue: 11,
      allowAlphaNumeric: true,
    },
    {
      id: 'origin',
      type: 'input',
      label: 'Origin',
      minValue: 1,
      maxValue: 126,
      noInitialWhiteSpace: true,
      isCheckForMaxValue: true,
    },
    {
      id: 'destination',
      type: 'input',
      label: 'Destination',
      minValue: 1,
      maxValue: 126,
      noInitialWhiteSpace: true,
      isCheckForMaxValue: true,
    },
    {
      id: 'package',
      type: 'input',
      label: 'Package',
      allowAlphabet: true,
      minValue: 1,
      maxValue: 50,
    },
    {
      id: 'manifest',
      type: 'input',
      label: 'Manifest',
      allowNumeric: true,
      maxValue: 14,
    },
    {
      id: 'flightNumber',
      type: 'input',
      label: 'Flight Number',
      minValue: 1,
      maxValue: 6,
    },
    { id: 'originData', type: 'input', label: 'Origin Data' },
    { id: 'packaging', type: 'input', label: 'Packaging Data' },
    { id: 'airline', type: 'input', label: 'Airline' },
  ],
};

/* Grid Summary Columns Configuration */
export const gridSummaryColumns: TableColumns[] = [
  ...new Set([
    {
      key: 'bagExt',
      label: 'Bag',
      value: 'bagExt',
      isSelected: true,
      defaultView: true,
      defaultPrintWidth: 175,
    },
    {
      key: 'noofShipments',
      label: 'Shipment',
      isSelected: true,
      defaultView: true,
      value: 'noofShipments',
      defaultPrintWidth: 100,
    },
    {
      key: 'weight',
      label: 'Weight (lb)',
      value: 'weight',
      isSelected: true,
      defaultView: true,
      defaultPrintWidth: 110,
    },
    {
      key: 'manifestId',
      isSelected: true,
      label: 'Manifest',
      value: 'manifestId',
      defaultView: true,
      defaultPrintWidth: 100,
    },
  ]),
];

/* Piece Table Grid Columns Configuration */
export const shipmentTableGridColumns = [
  {
    key: 'hawbExt',
    label: 'Shipment',
    isSelected: true,
    defaultPrintWidth: 175,
  },
  {
    key: 'weight',
    label: 'Weight (lb)',
    isSelected: true,
    defaultPrintWidth: 120,
  },
  {
    key: 'dimensions',
    label: 'Dimension L-W-H (inch)',
    isSelected: true,
    defaultPrintWidth: 190,
  },
  {
    key: 'shipper',
    label: 'Shipper',
    isSelected: true,
    defaultPrintWidth: 190,
  },
  {
    key: 'consignee',
    label: 'Consignee',
    isSelected: true,
    defaultPrintWidth: 190,
  },
];

/* Bag Information - Default Values Configuration */
export const defaultBagNotesData = (): IBagNotesData => ({
  ibc: [],
  customer: [],
});

export const defaultBagAttachmentsData = (): IBagAttachmentData[] => [
  {
    id: '',
    type: '',
    fileName: '',
    signedUrl: '',
    action: '',
    attachmentTypeValuId: '',
  },
];

export const defaultBagMiscellaneousData = (): IBagMiscellaneousData => ({
  tareWeight: '',
});
export const defaultBagShipmentData = (): IBagLinkedShipmentData[] => [
  { id: '', action: '' },
];
export const defaultBagInformationData = (): IBagInformationData => ({
  bagId: 0,
  bagNumber: '',
  hawbId: 0,
  origin: '',
  destination: '',
  packagingType: 0,
  manifestNumber: '',
  flightNumber: '',
  description: '',
  notes: defaultBagNotesData(),
  attachmentList: defaultBagAttachmentsData(),
  miscellaneous: defaultBagMiscellaneousData(),
  shipmentList: defaultBagShipmentData(),
  eventsList: [],
});
export const defaultGridDisplayColumns: string[] = [
  'select',
  'bagExt',
  'noofShipments',
  'weight',
  'manifestId',
  'action',
];

export const BagExcelHeaders: string[] = [
  'Bag',
  'Pieces',
  'Weight(lb)',
  'Manifest',
  'Packaging',
];

export const defautBagPagination = {
  fromIndex: 0,
  toIndex: 10,
  sortingOrder: searchDataParam.desc,
  sortingField: searchDataParam.bagExt,
};

export const desiredBagPackage = ['B', 'C', 'G', 'O', 'A'];

export const BagSearchConfig: IBasicSearchPaginationProps = {
  fromIndex: 0,
  toIndex: 10,
  sortingOrder: 'desc',
  sortingField: searchDataParam.bagId,
};

export const BagInfoSectionHeaders: string[] = [
  'Widgets',
  'Bag Information',
  'Miscellaneous',
  'Notes',
  'Events',
  'Attachments',
  'Details',
  'Shipment(s) linked',
];
export const BagInfoSectionHeaderKeys: string[] = [
  'widgets',
  'bagInfo',
  'bagMiscellaneous',
  'notes',
  'events',
  'attachments',
  'details',
  'bagPieces',
];
export const BagInfoHeaders = [
  {
    key: 'bagNumber',
    label: 'Bag',
    isSelected: true,
    defaultPrintWidth: 175,
  },
  {
    key: 'origin',
    label: 'Origin',
    isSelected: true,
    defaultPrintWidth: 150,
  },
  {
    key: 'destination',
    label: 'Destination',
    isSelected: true,
    defaultPrintWidth: 150,
  },
  {
    key: 'packagingTypeName',
    label: 'Packaging',
    isSelected: true,
    defaultPrintWidth: 100,
  },
  {
    key: 'manifestNumber',
    label: 'Manifest Number',
    isSelected: true,
    defaultPrintWidth: 150,
  },
  {
    key: 'description',
    label: 'Description',
    isSelected: true,
    defaultPrintWidth: 150,
  },
  {
    key: 'airline',
    label: 'Airline',
    isSelected: true,
    defaultPrintWidth: 120,
  },
  {
    key: 'flightNumber',
    label: 'Flight Number',
    isSelected: true,
    defaultPrintWidth: 150,
  },
];

export const BagDetailsHeaders = [
  {
    key: 'bagId',
    label: 'Bag ID',
    isSelected: true,
    defaultPrintWidth: 150,
  },
  {
    key: 'flightNumber',
    label: 'Flight Number',
    isSelected: true,
    defaultPrintWidth: 150,
  },
];

export const BagMiscellaneousHeaders = [
  {
    key: 'tareWeight',
    label: 'Tare Weight',
    isSelected: true,
    defaultPrintWidth: 170,
  },
];

export const bagWidgetTableGridColumns = [
  {
    key: 'totalWeight',
    label: 'Total Weight',
    isSelected: true,
    defaultPrintWidth: 150,
  },
  {
    key: 'totalNoOfHawbs',
    label: 'Number of Shipments',
    isSelected: true,
    defaultPrintWidth: 175,
  },
];

export const shipmentMultipleTableGridColumns = [
  {
    key: 'hawbExt',
    label: 'Shipment',
    isSelected: true,
    defaultPrintWidth: 175,
  },
  {
    key: 'accountDetails',
    label: 'Account',
    isSelected: true,
    defaultPrintWidth: 120,
  },
  {
    key: 'consignee',
    label: 'Consignee Name',
    isSelected: true,
    defaultPrintWidth: 190,
  },
  {
    key: 'origin',
    label: 'Origin',
    isSelected: true,
    defaultPrintWidth: 190,
  },
  {
    key: 'destination',
    label: 'Destination',
    isSelected: true,
    defaultPrintWidth: 190,
  },
  {
    key: 'weight',
    label: 'Weight (lb)',
    isSelected: true,
    defaultPrintWidth: 120,
  },
  {
    key: 'dimension',
    label: 'Dimension(L-W-H)',
    isSelected: true,
    defaultPrintWidth: 120,
  },
];
