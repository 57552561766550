<div
  class="custom-error-tooltip-container"
  [style.--custom-tooltip-width]="tooltipWidth">
  <div class="container-tooltip">
    <div class="ibc-body-table-text-small {{tooltipBlueColor ? 'blueColor' : ''}}">{{ tooltipText }}</div>
    <app-svg-icon
      class="cursor tooltipCloseIcon {{tooltipBlueColor ? 'blueColor' : ''}}"
      [iconName]="'tooltipClose'"
      [isStaticSvg]="true"
      [isFrom]="'Page'"
      (staticIconClicked)="dismiss()"></app-svg-icon>
  </div>
</div>
