import {
  Directive,
  ElementRef,
  HostListener,
  Renderer2,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';

import { MatPaginator } from '@angular/material/paginator';
import { MatExpansionPanel } from '@angular/material/expansion';
import { symbolToNumberMap } from '../../models/keystroke.model';

@Directive({
  selector: '[appFocusOnEscape]',
})
// Escape key brings focus to first input
export class FocusOnEscapeDirective {
  constructor(private readonly elementRef: ElementRef<HTMLInputElement>) {}
  @HostListener('document:keydown.escape')
  goToFirstInput() {
    this.elementRef.nativeElement.focus();
  }
}

// brings focus on the header checkbox
@Directive({
  selector: '[appTableFocusCheckboxShortcut]',
})
export class TableFocusCheckboxShortcutDirective {
  constructor(private readonly el: ElementRef) {}
  @HostListener('document:keydown.alt.shift.c', ['$event'])
  onKeyDown() {
    const checkboxElement =
      this.el.nativeElement.querySelector('mat-checkbox input');
    if (checkboxElement && !checkboxElement.hasAttribute('tabindex')) {
      checkboxElement.setAttribute('tabindex', '0');
    }
    if (checkboxElement) {
      checkboxElement.focus();
      checkboxElement.click();
    }
  }
}

// based on row number checkbox is checked
@Directive({
  selector: '[appfocusonindividualTabCheckbox]',
})
export class TabFocusOnIndividualCheckboxDirective implements OnDestroy {
  private firstDigit: number | null = null;
  private delayTimer: any;

  constructor(private readonly el: ElementRef) {
    this.bindKeyboardEvents();
  }

  private bindKeyboardEvents(): void {
    this.keydownHandler = this.keydownHandler.bind(this); // Bind the method to the instance
    document.addEventListener('keydown', this.keydownHandler);
  }

  private keydownHandler(event: KeyboardEvent): void {
    if (
      event.altKey &&
      ((event.shiftKey && event.key.match(/[!@#$%^&*()]/)) ||
        event.key.match(/[0-9]/))
    ) {
      const numericKey = event.key;
      const numericKeyDisp = symbolToNumberMap[numericKey] || 0;
      if (this.firstDigit === null) {
        this.firstDigit = numericKeyDisp;
        // Start a delay to wait for the second digit
        this.delayTimer = setTimeout(() => {
          this.handleCheckboxSelection(this.firstDigit!); // Type assertion
          this.firstDigit = null; // Reset the first digit after handling
        }, 500); // Adjust the delay time as needed (500 milliseconds here)
      } else {
        // Second digit entered, concatenate and handle
        clearTimeout(this.delayTimer); // Clear the previous delay timer
        const secondDigit = numericKeyDisp;
        const twoDigitNumber = this.firstDigit! * 10 + secondDigit; // Type assertion
        this.handleCheckboxSelection(twoDigitNumber);
        this.firstDigit = null; // Reset the first digit after handling
      }
    }
  }

  private handleCheckboxSelection(numericKey: number): void {
    let convertedNumericKey = numericKey;
    if (!isNaN(numericKey) && numericKey >= 0 && numericKey <= 9) {
      convertedNumericKey = numericKey + 1;
    }
    const targetCheckbox = this.el.nativeElement.querySelector(
      `mat-table mat-row:not(.mat-header-row):nth-child(${convertedNumericKey}) mat-checkbox input[type="checkbox"]`
    );
    if (targetCheckbox) {
      targetCheckbox.checked = !targetCheckbox.checked;
      targetCheckbox.dispatchEvent(new Event('change', { bubbles: true }));
      targetCheckbox.click();
    }
  }

  ngOnDestroy(): void {
    document.removeEventListener('keydown', this.keydownHandler);
  }
}

// bring focus on pagination select dropdown
@Directive({
  selector: '[appPaginatorKeyCombination]',
})
export class PaginatorKeyCombinationDirective {
  constructor(private el: ElementRef) {}

  @HostListener('document:keydown.control.alt.p', ['$event'])
  handleKeyCombination() {
    if (this.el.nativeElement.tagName.toLowerCase() === 'mat-paginator') {
      const matSelectElement =
        this.el.nativeElement.querySelector('mat-select');
      if (matSelectElement) {
        matSelectElement.focus(); // Focus on the mat-select element
      }
    }
  }
}

// brings 1st & last page when used up & down arrow keys, previous/next when used right & left arrow keys
@Directive({
  selector: '[appPaginatorNavigation]',
})
export class PaginatorNavigationDirective {
  constructor(
    private readonly el: ElementRef,
    private readonly renderer: Renderer2,
    private readonly matPaginator: MatPaginator
  ) {}

  @HostListener('document:keydown.control.arrowup', ['$event'])
  handleCtrlUpKey() {
    if (this.el.nativeElement.tagName.toLowerCase() === 'mat-paginator') {
      this.matPaginator.firstPage(); // Go to the first page
    }
  }

  @HostListener('document:keydown.control.arrowdown', ['$event'])
  handleCtrlDownKey() {
    if (this.el.nativeElement.tagName.toLowerCase() === 'mat-paginator') {
      this.matPaginator.lastPage(); // Go to the last page
      this.setFocusOnLastPageIcon();
    }
  }

  @HostListener('document:keydown.control.arrowright', ['$event'])
  handleCtrlRightKey() {
    if (this.el.nativeElement.tagName.toLowerCase() === 'mat-paginator') {
      this.matPaginator.nextPage(); // Go to the next page
      this.setFocusOnNextPageIcon();
    }
  }

  @HostListener('document:keydown.control.arrowleft', ['$event'])
  handleCtrlLeftKey() {
    if (this.el.nativeElement.tagName.toLowerCase() === 'mat-paginator') {
      this.matPaginator.previousPage(); // Go to the previous page
      this.setFocusOnPreviousPageIcon();
    }
  }

  private setFocusOnLastPageIcon(): void {
    const lastPageIcon = this.el.nativeElement.querySelector(
      '.mat-paginator-navigation-last'
    );
    if (lastPageIcon) {
      this.renderer.selectRootElement(lastPageIcon).focus();
    }
  }

  private setFocusOnNextPageIcon(): void {
    const nextPageIcon = this.el.nativeElement.querySelector(
      '.mat-paginator-navigation-next'
    );
    if (nextPageIcon) {
      this.renderer.selectRootElement(nextPageIcon).focus();
    }
  }

  private setFocusOnPreviousPageIcon(): void {
    const previousPageIcon = this.el.nativeElement.querySelector(
      '.mat-paginator-navigation-previous'
    );
    if (previousPageIcon) {
      this.renderer.selectRootElement(previousPageIcon).focus();
    }
  }
}
// makes action icons to trigger
@Directive({
  selector: '[appIconClickShortcut]',
})
export class IconClickShortcutDirective {
  constructor(private readonly el: ElementRef) {}

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.shiftKey && event.altKey) {
      let preventDefault = true;
      let iconClass: string | null = null;
      switch (event.key.toLowerCase()) {
        case 'a':
          iconClass = 'addIcon';
          break;
        case 'v':
          iconClass = 'viewIcon';
          break;
        case 'u':
          iconClass = 'saveIcon';
          break;
        case 'e':
          iconClass = 'editIcon';
          break;
        case 'd':
          iconClass = 'deleteIcon';
          break;
        case 's':
          iconClass = 'downloadFileIcon';
          break;
        case 'p':
          iconClass = 'printIcon';
          break;
        case 'f':
          iconClass = 'filterIcon';
          break;
        case 'r':
          iconClass = 'refreshIcon';
          break;
        case 't':
          iconClass = 'view-more-icon';
          break;
        case 'c':
          iconClass = 'deleteChildIcon';
          break;
        default:
          preventDefault = false;
          break;
      }
      if (iconClass && preventDefault) {
        const iconElement = this.el.nativeElement.querySelector(`.${iconClass}`) as HTMLElement;
        if (iconElement) {
          const iconStyle = window.getComputedStyle(iconElement);
          if (iconStyle.getPropertyValue('pointer-events') === 'none') {
            event.preventDefault();
            return;
          }
        }
        this.triggerIconClick(iconClass);
        event.preventDefault();
      }
    }
  }

  private triggerIconClick(iconClass: string) {
    const iconElement = this.el.nativeElement.querySelector(`.${iconClass}`);
    if (iconElement) {
      iconElement.click();
    }
  }
}

// making focus on particular input field
@Directive({
  selector: '[appFocusOnShortcut]',
})
export class FocusOnShortcutDirective {
  private isEnteringNumber = false;
  private targetNumber: number | null = null;
  private timeoutId: any;

  constructor(
    private readonly el: ElementRef,
    private readonly renderer: Renderer2
  ) {}

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.altKey && event.key === '/') {
      // event.preventDefault(); // Prevent default behavior of '/'
      this.isEnteringNumber = true;
      this.targetNumber = null;
    } else if (this.isEnteringNumber && !isNaN(parseInt(event.key, 10))) {
      clearTimeout(this.timeoutId);
      this.targetNumber = this.targetNumber
        ? this.targetNumber * 10 + parseInt(event.key, 10)
        : parseInt(event.key, 10);
      // Set a delay to allow additional input
      this.timeoutId = setTimeout(() => {
        this.handleSequenceEnd();
      }, 200); // Adjust the delay time as needed
    }
  }

  private handleSequenceEnd() {
    this.isEnteringNumber = false;
    const targetInput = this.el.nativeElement.querySelector(
      `[data-shortcut-number="${this.targetNumber}"]`
    );
    if (targetInput) {
      this.renderer.selectRootElement(targetInput).focus();
    } else {
    }
  }
}

// mat-tabs events handled here
@Directive({
  selector: '[appTabIconClickShortcut]',
})
export class TabShortcutDirective {
  constructor(
    private readonly el: ElementRef
  ) {}

  @HostListener('document:keydown.alt.c', ['$event'])
  handleAltCShortcut(event: KeyboardEvent) {
    // event.preventDefault();
    this.openTab('Piece');
  }

  @HostListener('document:keydown.alt.y', ['$event'])
  handleAltYShortcut(event: KeyboardEvent) {
    // event.preventDefault();
    this.openTab('Commodity');
  }

  @HostListener('document:keydown.alt.g', ['$event'])
  handleAltGShortcut(event: KeyboardEvent) {
    // event.preventDefault();
    this.openTab('Consignee');
  }

  @HostListener('document:keydown.alt.w', ['$event'])
  handleAltWShortcut(event: KeyboardEvent) {
    // event.preventDefault();
    this.openTab('True Shipper');
  }

  @HostListener('document:keydown.alt.i', ['$event'])
  handleAltIShortcut(event: KeyboardEvent) {
    // event.preventDefault();
    this.triggerIconClick('viewIcon');
  }

  @HostListener('document:keydown.alt.d', ['$event'])
  handleAltDShortcut(event: KeyboardEvent) {
    // event.preventDefault();
    this.triggerIconClick('deleteIcon');
  }

  @HostListener('document:keydown.alt.x', ['$event'])
  handleAltXShortcut(event: KeyboardEvent) {
    // event.preventDefault();
    this.triggerIconClick('editIcon');
  }

  @HostListener('document:keydown.alt.a', ['$event'])
  handleAltAShortcut(event: KeyboardEvent) {
    // event.preventDefault();
    this.triggerIconClick('addIcon');
  }
  
  @HostListener('document:keydown.alt.z', ['$event'])
  handleAltOShortcut(event: KeyboardEvent) {
    this.triggerIconClick('addComIcon');
  }

  private openTab(tabLabel: string) {
    const tabLabels = document.querySelectorAll(
      '.mdc-tab__text-label'
    ) as NodeListOf<HTMLElement>;
    tabLabels.forEach(labelElement => {
      if (labelElement.textContent?.trim() === tabLabel) {
        const tabElement = labelElement.closest(
          '.mdc-tab'
        ) as HTMLElement | null;
        if (tabElement) {
          const allTabs = document.querySelectorAll(
            '.mdc-tab'
          ) as NodeListOf<HTMLElement>;
          allTabs.forEach(tab =>
            tab.classList.remove('mdc-tab-indicator--active')
          );
          tabElement.classList.add('mdc-tab-indicator--active');
          tabElement.focus();
          tabElement.click();
          tabElement.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
          });
        }
      }
    });
  }

  private triggerIconClick(iconClass: string) {
    const iconElement = this.el.nativeElement.querySelector(`.${iconClass}`);
    if (iconElement && !iconElement.classList.contains('disabled')) {
      const clickEvent = new MouseEvent('click', { bubbles: true });
      iconElement.dispatchEvent(clickEvent);
    }
  }
}
// Accordion expand/collapse and titles open & close
@Directive({
  selector: '[accordionExpandCollapse]',
})
export class AccordionExpandCollapseDirective {
  @Input() tabShortcuts!: { [key: string]: string };

  constructor(private readonly matExpansionPanel: MatExpansionPanel) {}

  @HostListener('window:keydown.control.arrowright', ['$event'])
  expandAccordion(event: KeyboardEvent) {
    // event.preventDefault();
    this.matExpansionPanel.open();
    this.focusFirstElement();
  }

  @HostListener('window:keydown.control.arrowleft', ['$event'])
  collapseAccordion(event: KeyboardEvent) {
    // event.preventDefault();
    this.matExpansionPanel.close();
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (
      event.altKey &&
      !event.ctrlKey &&
      this.tabShortcuts &&
      this.tabShortcuts[event.key]
    ) {
      // event.preventDefault();
      this.openTab(this.tabShortcuts[event.key]);
      this.focusFirstElement();
    }
  }

  private openTab(tabName: string) {
    const titleElements = document.querySelectorAll('mat-panel-title');
    const titleArray = Array.from(titleElements) as HTMLElement[];
    for (const titleElement of titleArray) {
      const textContent = titleElement.textContent?.trim();
      if (textContent && textContent === tabName) {
        const clickEvent = new MouseEvent('click', { bubbles: true });
        titleElement.dispatchEvent(clickEvent);
        titleElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        return;
      }
    }
  }

  private focusFirstElement() {
    const panelElements = document.querySelectorAll('mat-expansion-panel');
    const panelArray = Array.from(panelElements) as HTMLElement[];
    const lastPanel = panelArray[panelArray.length - 1];
    const firstFocusable = lastPanel.querySelector(
      '[tabindex="0"]'
    ) as HTMLElement | null;
    if (firstFocusable) {
      firstFocusable.focus();
    }
  }
}

// Open notes tab popup on see more
@Directive({
  selector: '[appOpenPopupShortcut]',
})
export class OpenPopupShortcutDirective {
  @Output() publicShortcut: EventEmitter<void> = new EventEmitter<void>();

  @HostListener('document:keydown.control.shift.b', ['$event'])
  handlePublicPopup() {
    this.publicShortcut.emit();
  }
}

// Open details tab
@Directive({
  selector: '[appOpenDetailsShortcut]',
})
export class OpenDetailsShortcutDirective {
  private readonly keysPressed: string[] = [];

  constructor() {}

  @HostListener('document:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    if (!event.repeat) {
      const key = event.key.toLowerCase();
      if (!this.keysPressed.includes(key)) {
        this.keysPressed.push(key);
        if (this.keysPressed.length === 4 && this.checkKeyCombination()) {
          this.openDetails();
        }
      }
    }
  }

  @HostListener('document:keyup', ['$event'])
  handleKeyUp(event: KeyboardEvent) {
    const key = event.key.toLowerCase();
    const index = this.keysPressed.indexOf(key);
    if (index !== -1) {
      this.keysPressed.splice(index, 1);
    }
  }

  checkKeyCombination(): boolean {
    return (
      this.keysPressed.includes('control') &&
      this.keysPressed.includes('alt') &&
      this.keysPressed.includes('z') &&
      !isNaN(parseInt(this.keysPressed[this.keysPressed.length - 1]))
    );
  }

  openDetails() {
    const rowNumber =
      parseInt(this.keysPressed[this.keysPressed.length - 1]) - 1;
    const anchorTags = document.querySelectorAll('.detail');
    if (rowNumber >= 0 && rowNumber < anchorTags.length) {
      const thirdRowAnchor = anchorTags[rowNumber] as HTMLElement;
      thirdRowAnchor.click();
    } else {
    }
  }
}

// make table hawb-id to click and navigate
@Directive({
  selector: '[appHyperLinkShortcut]',
})
export class HawbShortcutDirective {
  private rowDigits: string[] = []; // Store the digits of the row number
  private delayTimer: any; // Timer for delay

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.ctrlKey && event.altKey && event.key >= '1' && event.key <= '9') {
      this.rowDigits.push(event.key); // Store the pressed digit
      // Start or reset the delay timer
      clearTimeout(this.delayTimer);
      this.delayTimer = setTimeout(() => {
        this.handleRowSelection();
        this.rowDigits = []; // Reset the stored digits after handling
      }, 500); // Adjust the delay time as needed (500 milliseconds here)
    }
  }

  private handleRowSelection(): void {
    const rowIndex = parseInt(this.rowDigits.join('')); // Combine digits to form row index
    const rowElements = document.querySelectorAll('.mat-mdc-row');
    if (rowElements.length >= rowIndex) {
      const rowElement = rowElements[rowIndex - 1];
      const hawbElement = rowElement.querySelector('.hawb-id') as HTMLElement;
      if (hawbElement) {
        hawbElement.focus();
        hawbElement.click();
      }
    }
  }
}
// makes sort icons to work
@Directive({
  selector: '[appSortIconFocus]',
})
export class SortShortcutDirective {
  @Input() columnIndex!: number;
  private altSlashPressed = false; // Flag to track Alt + '/' key

  constructor(private readonly el: ElementRef) {}

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    const keyPressed = event.key;
    const key = parseInt(keyPressed);
    // Check if Alt key is pressed and the key pressed is a number
    if (
      event.altKey &&
      !event.ctrlKey &&
      !event.shiftKey &&
      !event.metaKey &&
      !event.repeat &&
      !isNaN(key) &&
      !this.altSlashPressed // Check if Alt + '/' key was not pressed
    ) {
      if (key === this.columnIndex) {
        this.el.nativeElement.click();
        this.el.nativeElement.focus();
      }
    }
    // Check if '/' key is pressed after Alt key
    if (event.altKey && keyPressed === '/') {
      this.altSlashPressed = true;
    } else {
      this.altSlashPressed = false;
    }
  }
}

// Table tab icons
@Directive({
  selector: '[appNoTabIconClickShortcut]',
})
export class TabNoOnlyShortcutDirective {
  constructor(private readonly el: ElementRef) {}

  @HostListener('document:keydown.alt.i', ['$event'])
  handleAltIShortcut() {
    this.triggerIconClick('viewIcon');
  }

  @HostListener('document:keydown.alt.d', ['$event'])
  handleAltDShortcut() {
    this.triggerIconClick('deleteIcon');
  }

  @HostListener('document:keydown.alt.x', ['$event'])
  handleAltXShortcut() {
    this.triggerIconClick('editIcon');
  }

  private triggerIconClick(iconClass: string) {
    const iconElement = this.el.nativeElement.querySelector(`.${iconClass}`);
    if (iconElement && !iconElement.classList.contains('disabled')) {
      const clickEvent = new MouseEvent('click', { bubbles: true });
      iconElement.dispatchEvent(clickEvent);
    }
  }
}
