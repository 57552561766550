import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  Inject,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DefaultCrudPopupHeaderIcons } from '../../utility/svg-constants';
import { SvgIconComponent } from '../svg-icon/svg-icon.component';
import {
  ModalPopupMessages,
  popupButtons,
  popupWidth,
  SingleDeleteHeaderText,
  SnackBar,
  StatusCodes,
} from '../../utility/constants';
import { MaterialModule } from '../../material.module';
import { MessagePopupComponent } from '../message-popup/message-popup.component';
import { ModalService } from '../../services/modal.service';
import { CommonService } from '../../services/common.service';
import { Subject, takeUntil } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandlerService } from 'src/app/core/services/error-handler.service';
import { SnakbarService } from '../../services/snakbar.service';
import { HawbService } from 'src/app/features/service/hawb.service';

@Component({
  selector: 'app-crud-popup',
  standalone: true,
  imports: [CommonModule, SvgIconComponent, MaterialModule],
  templateUrl: './crud-popup.component.html',
  styleUrl: './crud-popup.component.scss',
})
export class CrudPopupComponent implements OnInit, AfterViewInit {
  @ViewChild('componentContainer', { read: ViewContainerRef })
  componentContainer!: ViewContainerRef;

  public injectedComponentRef!: ComponentRef<any>;
  public crudPopupData: any;
  public crudPopupHeaderIcons = JSON.parse(
    JSON.stringify(DefaultCrudPopupHeaderIcons)
  );
  public popupButtons = popupButtons;

  private readonly destroy$: Subject<boolean> = new Subject<boolean>();
  private bulkBagData: any;

  constructor(
    public commonService: CommonService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly ref: MatDialogRef<CrudPopupComponent>,
    private readonly cdref: ChangeDetectorRef,
    private readonly modalService: ModalService,
    private readonly snakbarService: SnakbarService,
    private readonly errorHandlerService: ErrorHandlerService,
    private readonly hawbService: HawbService
  ) {}

  ngOnInit(): void {
    this.crudPopupData = this.data;
  }

  ngAfterViewInit(): void {
    this.injectComponent();
    this.cdref.detectChanges();
  }

  public closePopup() {
    this.ref.close(true);
  }

  public getScreenData() {
    if (this.crudPopupData.code?.isStatusCodeAdditionalOrPodDetails) {
      return popupButtons.Save;
    } else if (this.crudPopupData.code?.isStatusCodeAttachmentDetails) {
      return popupButtons.Close;
    } else if (this.crudPopupData.code?.isForBulkBagging) {
      return popupButtons.BulkBag;
    } else {
      return popupButtons.Cancel;
    }
  }

  public injectComponent() {
    if (this.crudPopupData.injectedComponent) {
      this.componentContainer.clear();
      this.injectedComponentRef = this.componentContainer.createComponent(
        this.crudPopupData.injectedComponent
      );
      this.bulkBagData = this.injectedComponentRef.instance;
    }
  }

  public handleConfirmAction(event: string) {
    if (this.crudPopupData.code?.isForBulkBagging) {
      this.onBulkBagClicked();
    } else if (event == SnackBar.Close || event == popupButtons.Cancel) {
      this.closePopup();
    } else if (this.crudPopupData.code?.isFromStatusCodeSummaryPage) {
      this.ref.close();
    }
  }

  private onBulkBagClicked() {
    this.bulkBagData.bulkBagForm.markAllAsTouched();
    if (
      !this.bulkBagData.destinationError &&
      this.bulkBagData.bulkBagForm.valid
    ) {
      const dialogRef = this.modalService.openPopup(
        {
          isForBulkBagConfirmation: true,
          isFooterRequired: true,
          ...this.getMessage(),
        },
        ModalPopupMessages.bulkBag,
        MessagePopupComponent,
        popupWidth.searchPopup
      );
      dialogRef.afterClosed().subscribe(data => {
        if (data === true) {
          this.addPiecesToBag();
        }
      });
      this.ref.close(true);
    }
  }

  private getMessage() {
    const unBaggedPiecesList = this.bulkBagData.shipmentPiecesData.filter(
      (pieces: any) => !pieces.isBagging
    );
    if (
      unBaggedPiecesList.length === this.bulkBagData.shipmentPiecesData.length
    ) {
      return {
        message: ModalPopupMessages.bulkBagConfirmationMessage(
          this.bulkBagData.shipmentPiecesData.length,
          this.bulkBagData.bulkBagForm.get('bagExt')?.value
        ),
        confirmText: ModalPopupMessages.bulkBagOrGatewayConfirmationText(
          SingleDeleteHeaderText.Bag,
          this.bulkBagData.shipmentPiecesData.length
        ),
      };
    } else {
      return {
        message: ModalPopupMessages.partialBulkBagConfirmationMessage(
          this.bulkBagData.shipmentPiecesData.length,
          this.bulkBagData.shipmentPiecesData.length -
            unBaggedPiecesList.length,
          unBaggedPiecesList.length,
          this.bulkBagData.bulkBagForm.get('bagExt')?.value
        ),
        confirmText: ModalPopupMessages.bulkBagOrGatewayConfirmationText(
          SingleDeleteHeaderText.Bag,
          unBaggedPiecesList.length
        ),
      };
    }
  }

  private addPiecesToBag() {
    const piecesList = this.bulkBagData.shipmentPiecesData
      .filter((pieces: any) => !pieces.isBagging)
      .map((shipmentPiece: any) => shipmentPiece.hawbId);
    const bulkBaggingData = {
      ids: piecesList,
      bagId: this.bulkBagData.enteredBagData.bagId,
      originId: this.bulkBagData.shipmentPiecesData[0].originId,
      destinationId: this.bulkBagData.shipmentPiecesData[0].destinationId,
      bagExt: this.bulkBagData.bulkBagForm.get('bagExt')?.value,
    };
    this.commonService
      .createBagForBulkBagging(bulkBaggingData)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (resp: any) => {
          if (resp.statusCode == StatusCodes.Success) {
            this.snakbarService.openCustomisedSnackBar(
              resp.message,
              ModalPopupMessages.success
            );
            this.hawbService.ishawbSummaryApiData(true);
          } else {
            this.errorHandlerService.handleApiError(resp);
          }
        },
        error: (error: HttpErrorResponse) => {
          this.errorHandlerService.handleError(error);
        },
      });
  }
}
