<div class="crud-popup-container">
  <div class="crud-popup-header">
    <span class="ibc-body-text-large-bold">{{ crudPopupData.title }}</span>
    <div *ngIf="crudPopupData.code?.showCrudHeaderOptions">
      <app-svg-icon
        [list]="crudPopupHeaderIcons"
        class="icon-section"></app-svg-icon>
    </div>
    <div *ngIf="crudPopupData.code?.showModalCloseOption">
      <app-svg-icon
        [iconName]="'close'"
        class="cursor"
        [isStaticSvg]="true"
        [isFrom]="'Popup'"
        (staticIconClicked)="closePopup()"></app-svg-icon>
    </div>
  </div>
  <div class="crud-popup-content">
    <ng-container
      *ngIf="crudPopupData.injectedComponent"
      #componentContainer></ng-container>
  </div>
  <div class="crud-popup-footer" *ngIf="crudPopupData.code?.isFooterRequired">
    <div class="button-container">
      <button
        *ngIf="
          crudPopupData.code?.isStatusCodeAdditionalOrPodDetails ||
          crudPopupData.code?.isForBulkBagging
        "
        class="ibc-medium-button ibc-secondary-default-button cursor"
        (click)="closePopup()">
        <span class="ibc-body-text-regular">{{ popupButtons.Cancel }}</span>
      </button>
      <button
        class="ibc-medium-button ibc-primary-default-button cursor"
        (click)="handleConfirmAction(getScreenData())">
        <span class="ibc-body-text-regular">{{ getScreenData() }}</span>
      </button>
    </div>
    <div
      class="pagination-container"
      *ngIf="crudPopupData.code?.isPaginationRequired">
      <mat-paginator
        #paginator
        class="demo-paginator"
        [showFirstLastButtons]="false"
        [pageSizeOptions]="[]"
        [hidePageSize]="true">
      </mat-paginator>
    </div>
  </div>
</div>
