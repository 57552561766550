import { StateContext } from '@ngxs/store';

export function updateState<T>(
  ctx: StateContext<T>,
  partialState: Partial<T>
): void {
  const state = ctx.getState();
  ctx.setState({
    ...state,
    ...partialState,
  });
}
