import { Component, Inject, OnInit } from '@angular/core';
import { SvgIconComponent } from '../svg-icon/svg-icon.component';
import { MaterialModule } from '../../material.module';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ModalPopupMessages, popupButtons } from '../../utility/constants';
import { ValidatorPattern } from '../../utility/validators.pattern';
import {
  GatewayMasterShipmentsGridContent,
  ShipmentBagGridContent,
  ShipmentGatewayPieceGridContent,
} from '../../models/dynamic.model';
import { TableColumnsProp } from '../../models/tables.model';
import { SearchModule } from '../search/search.module';
import { SnakbarService } from '../../services/snakbar.service';

@Component({
  selector: 'app-message-popup',
  standalone: true,
  imports: [CommonModule, MaterialModule, SvgIconComponent, SearchModule],
  templateUrl: './message-popup.component.html',
  styleUrl: './message-popup.component.scss',
})
export class MessagePopupComponent implements OnInit {
  public messagePopupData: any;
  public confirmValue!: string;
  public popupMessages = ModalPopupMessages;
  public isDeleteAction = false;
  public deleteText = ModalPopupMessages.deleteConfirmationText;
  public requiredBulkDeleteText = '';
  public requiredBulkBagText = '';
  public requiredBulkGatewayText = '';
  public popupButtons = popupButtons;
  public fileType = '';
  public summaryTableColumns: any;
  public isPrintDisable = false;
  public shipmentGatewayPieceGridColumns!: TableColumnsProp[];
  public shipmentGatewayPieceTableData!: ShipmentGatewayPieceGridContent[];
  public page!: string;
  public masterShipmentTableColumnConfig!: TableColumnsProp[];
  public masterShipmentTableData!: GatewayMasterShipmentsGridContent[];
  public tableisFrom!: string;
  public shipmentBagGridColumns!: TableColumnsProp[];
  public shipmentBagPieceTableData!: ShipmentBagGridContent[];
  selectedRowData: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly ref: MatDialogRef<MessagePopupComponent>,
    private readonly snakbarService: SnakbarService
  ) {
    this.confirmValue = '';
  }

  ngOnInit(): void {
    this.messagePopupData = this.data;
    this.deleteText = ModalPopupMessages.deleteConfirmationText;
    this.updateDeleteConfirmationText();
    this.shipmentGatewayPieceGridColumns =
      this.messagePopupData.code?.tableColumnConfig;
    this.shipmentGatewayPieceTableData =
      this.messagePopupData.code?.tableColumnDataSource;
    this.page = this.messagePopupData.code?.isFrom;
    this.masterShipmentTableColumnConfig =
      this.messagePopupData.code?.tableColumns;
    this.masterShipmentTableData = this.messagePopupData.code?.tableData;
    this.shipmentBagGridColumns =
      this.messagePopupData.code?.tableBagColumnConfig;
    this.shipmentBagPieceTableData =
      this.messagePopupData.code?.tableBagColumnDataSource;
    this.tableisFrom = this.messagePopupData.code?.tableIsFrom;
  }

  public getConfirmText() {
    return this.messagePopupData.code?.isForBulkBagConfirmation ||
      this.messagePopupData.code?.isForBulkGateway
      ? this.messagePopupData.code?.confirmText
      : this.deleteText;
  }

  public getCancelButtonName() {
    let buttonName = popupButtons.Cancel;
    if (
      this.messagePopupData.code?.isMultiple ||
      this.messagePopupData.code?.isForDownload ||
      this.messagePopupData.code?.isForSearch ||
      this.messagePopupData.code?.isForPublish ||
      this.messagePopupData.code?.isForPrint ||
      this.messagePopupData.code?.renderTable ||
      this.messagePopupData.code?.isForBulkGateway ||
      this.messagePopupData.code?.isForBulkBagConfirmation ||
      this.messagePopupData.code?.renderTable ||
      this.messagePopupData.code?.isForGatewayLocationMismatch ||
      this.messagePopupData.code?.isForCbpFail ||
      this.messagePopupData.code?.renderBagShipmentTable ||
      this.messagePopupData.code?.isForHandlingExisitingAccount ||
      this.messagePopupData.code?.renderBagShipmentTable ||
      this.messagePopupData.code?.isForCreateLinkedAccount
    ) {
      buttonName = popupButtons.Cancel;
    } else if (this.messagePopupData.code?.isForDiscardChanges) {
      buttonName = popupButtons.ContinueEdit;
    } else if (!this.messagePopupData.code?.isMultiple) {
      buttonName = popupButtons.No;
      if (this.messagePopupData.code?.isOptionalTextbox) {
        buttonName = popupButtons.Cancel;
      }
    }
    return buttonName;
  }

  public confirm() {
    if (
      !this.messagePopupData.code?.isMultiple &&
      this.messagePopupData.code?.isOptionalTextbox
    ) {
      this.ref.close({ popupClose: true, comments: this.confirmValue });
    } else if (this.messagePopupData.code?.renderBagShipmentTable) {
      this.ref.close({ popupClose: true, data: this.selectedRowData });
    } else if (
      !this.messagePopupData.code?.isMultiple &&
      this.messagePopupData.code?.isForDownload
    ) {
      this.ref.close({ popupClose: true, fileType: this.fileType });
    } else {
      this.ref.close(true);
    }
    this.defaultData();
  }

  public closePopup() {
    this.ref.close(false);
    this.defaultData();
  }

  public editShipment() {
    this.ref.close(popupButtons.editShipment);
  }
  
  public getPopupButtonLabel(): string {
    const code = this.messagePopupData.code;
    if (code?.isMultiple || code?.isForSearch) {
      return this.popupButtons.Delete;
    } else if (code?.isForDiscardChanges) {
      return this.popupButtons.Discard;
    } else if (!code?.isMultiple && code?.isOptionalTextbox) {
      return code.isBlock;
    } else if (code?.renderTable || code?.renderBagShipmentTable) {
      return this.popupButtons.Ok;
    } else if (code?.isForBulkGateway || code?.isForBulkBagConfirmation) {
      return this.popupButtons.Confirm;
    } else if (code?.isForGatewayLocationMismatch) {
      return this.popupButtons.gateway;
    } else if (code?.isForCbpFail) {
      return this.popupButtons.proceedWithGateway;
    } else if (code?.isForCreateLinkedAccount) {
      return this.popupButtons.create;
    }
    return this.popupButtons.Yes;
  }

  public isButtonDisabled(): boolean {
    const code = this.messagePopupData.code;
    if (code?.renderBagShipmentTable && !this.selectedRowData) {
      return true;
    }
    return (
      this.getClassValueForEnableDisable() ||
      (!code?.isMultiple &&
        code?.isOptionalTextbox &&
        !this.confirmValue)
    );
  }

  public proccedAndCloseMasterShipmentSelection(selectedShipmentData: any) {
    this.selectedRowData = selectedShipmentData;
    if (this.messagePopupData.code?.renderMasterShipmentTable) {
      this.ref.close({
        popupClose: true,
        masterShipmentData: selectedShipmentData,
      });
    } else if (this.messagePopupData.code?.renderTable) {
      this.ref.close({
        popupClose: true,
        selectedShipmentData: selectedShipmentData,
      });
    }
  }

  public normalizeText(text: string): string {
    return text.toLowerCase().replace(/\s+/g, '');
  }

  public handleFooterButtonActions(btnName: string) {
    if (btnName === 'Cancel') {
      this.ref.close();
    }
  }

  private updateDeleteConfirmationText() {
    if (this.messagePopupData.code?.isBulk) {
      this.bulkDeleteTextMessage();
    } else if (this.messagePopupData.code?.isForBulkBagConfirmation) {
      this.bulkConfirmation();
    } else if (this.messagePopupData.code?.isForBulkGateway) {
      const match = this.messagePopupData.code?.confirmText.match(/\b\d+\b/);
      if (match && match.length > 0) {
        const number = parseInt(match[0]);
        this.requiredBulkGatewayText = `Gateway ${number}`;
      } else {
        this.requiredBulkGatewayText = '';
      }
    } else {
      this.defaultData();
    }
  }

  private bulkConfirmation() {
    const match = this.messagePopupData.code?.confirmText.match(/\b\d+\b/);
    if (match && match.length > 0) {
      const number = parseInt(match[0]);
      this.requiredBulkBagText = `Bag ${number}`;
    } else {
      this.requiredBulkBagText = '';
    }
  }

  private bulkDeleteTextMessage() {
    const message = this.messagePopupData.code?.message;
    const regexPattern = /\b\d+\b/;
    const match = message.match(regexPattern);
    if (match && match.length > 0) {
      const number = parseInt(match[0]);
      this.deleteText = `If so, type "Delete ${number}" and click Delete.`;
      this.isDeleteAction = true;
      this.requiredBulkDeleteText = `Delete ${number}`;
    } else {
      this.isDeleteAction = false;
      this.requiredBulkDeleteText = '';
    }
  }

  private defaultData() {
    this.isDeleteAction = false;
    this.deleteText = ModalPopupMessages.deleteConfirmationText;
    this.requiredBulkDeleteText = '';
    this.requiredBulkBagText = '';
    this.requiredBulkGatewayText = '';
  }

  public commentBoxChange(event: any) {
    const input = event.target as HTMLTextAreaElement;
    const trimmedValue = input.value.replace(
      ValidatorPattern.StartPositionSpaceNotAllowed,
      ''
    );
    if (input.value !== trimmedValue) {
      this.confirmValue = trimmedValue;
      input.value = trimmedValue;
    }
  }

  public getClassValueForEnableDisable() {
    return (
      (this.messagePopupData.code?.isMultiple &&
        this.confirmValue !== popupButtons.Delete) ||
      (this.messagePopupData.code?.isForBulkBagConfirmation &&
        this.normalizeText(this.confirmValue) !==
          this.normalizeText(this.requiredBulkBagText)) ||
      (this.messagePopupData.code?.isForBulkGateway &&
        this.normalizeText(this.confirmValue) !==
          this.normalizeText(this.requiredBulkGatewayText))
    );
  }
}
