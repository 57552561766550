import { Injectable } from '@angular/core';

@Injectable()
export class SharedService {
    
    getItem(key: string) {
        return  JSON.parse(sessionStorage.getItem(key) ?? '{}');
    }
    
    setItem(key: string, value: any) {
        return sessionStorage.setItem(key, JSON.stringify(value));
    }
    
    removeItem(key: string) {
        return sessionStorage.removeItem(key);
    }

    clearEntireSession() {
        sessionStorage.clear();
    }
}
