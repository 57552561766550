<div class="crud-table ibc-border-table table-input-field">
  <mat-table [dataSource]="crudTableDataSource">
    <mat-header-row
      *matHeaderRowDef="tableDisplayedColumns"
      class="ibc-body-text-large-bold"></mat-header-row>

    <ng-container>
      <mat-row *matRowDef="let row; columns: tableDisplayedColumns"></mat-row>
    </ng-container>

    <mat-divider></mat-divider>

    <ng-container matColumnDef="select">
      <mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          (change)="$event ? toggleAllRows() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
          [disabled]="checkForEnableOrDisable()">
        </mat-checkbox>
      </mat-header-cell>
      <mat-cell *matCellDef="let row; let i = index">
        <mat-checkbox
          *ngIf="
            !tableService.rowContainsInput(row) &&
            (i > 0 || (i === 0 && pageMode !== 'Add'))
          "
          (change)="
            $event.checked ? checked(row) : unchecked(row);
            emitCreateSelectionChange()
          "
          [checked]="isChecked(row)"
          (keydown)="$event.stopPropagation()">
        </mat-checkbox>
      </mat-cell>
    </ng-container>

    <form [formGroup]="crudTableForm">
      <ng-container
        *ngFor="let item of customiseCrudTableColumns; let i = index"
        [matColumnDef]="item.key">
        <mat-header-cell *matHeaderCellDef>
          <span class="column-header cursor">
            <span class="ibc-body-text-large">{{ item.label }}</span>
          </span>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <ng-container *ngIf="element.isInputRow; else inputRow">
            <span
              class="ibc-body-text-medium {{
                detectHyperlink(item.key) ? 'text-hyperlink' : ''
              }}"
              tabindex="-1"
              *ngIf="getValueField(item.key)"
              (click)="handleHyperlinkClickEvent(item.key)"
              (keydown)="$event.stopPropagation()">
              {{ element[item.key] }}
            </span>
            <ng-container
              *ngIf="item.key === 'dimLWH' && !getValueField(item.key)">
              <div class="dimension-dimLWH">
                <mat-form-field
                  appearance="outline"
                  class="mat-sm dimLWH dimension dim-length">
                  <input
                    matInput
                    [formControlName]="'dimL'"
                    [name]="item.key"
                    autocomplete="off"
                    (blur)="basicInputOnChanges()" />
                  <app-svg-icon
                    *ngIf="
                      commonService.checkControlError(crudTableForm, 'dimL')
                    "
                    matSuffix
                    [iconName]="'infoCircle'"
                    [isStaticSvg]="true"
                    [isFrom]="'Page'"
                    class="cursor"
                    (click)="fetchWidth($event, 'dimL')"
                    (keydown)="$event.stopPropagation()"></app-svg-icon>
                  <app-custom-error-tooltip
                    *ngIf="
                      commonService.checkControlError(crudTableForm, 'dimL') &&
                      setCrudTableErrorTooltip['dimL']
                    "
                    [tooltipWidth]="setCrudTableWidth['dimL']"
                    [tooltipText]="getErrorMessage('dimL')"
                    (tooltipCloseClicked)="
                      commonService.closeErrorTooltip(
                        'dimL',
                        setCrudTableErrorTooltip
                      )
                    ">
                  </app-custom-error-tooltip>
                </mat-form-field>
                <span>-</span>
                <mat-form-field
                  appearance="outline"
                  class="mat-sm dimension dimLWH dim-width">
                  <input
                    matInput
                    [formControlName]="'dimW'"
                    [name]="item.key"
                    autocomplete="off"
                    (blur)="basicInputOnChanges()" />
                  <app-svg-icon
                    *ngIf="
                      commonService.checkControlError(crudTableForm, 'dimW')
                    "
                    matSuffix
                    [iconName]="'infoCircle'"
                    [isStaticSvg]="true"
                    [isFrom]="'Page'"
                    class="cursor"
                    (click)="fetchWidth($event, 'dimW')"
                    (keydown)="$event.stopPropagation()"></app-svg-icon>
                  <app-custom-error-tooltip
                    *ngIf="
                      commonService.checkControlError(crudTableForm, 'dimW') &&
                      setCrudTableErrorTooltip['dimW']
                    "
                    [tooltipWidth]="setCrudTableWidth['dimW']"
                    [tooltipText]="getErrorMessage('dimW')"
                    (tooltipCloseClicked)="
                      commonService.closeErrorTooltip(
                        'dimW',
                        setCrudTableErrorTooltip
                      )
                    ">
                  </app-custom-error-tooltip>
                </mat-form-field>
                <span>-</span>
                <mat-form-field
                  appearance="outline"
                  class="mat-sm dimension dimLWH dim-height">
                  <input
                    matInput
                    [formControlName]="'dimH'"
                    [name]="item.key"
                    autocomplete="off"
                    (blur)="basicInputOnChanges()" />
                  <app-svg-icon
                    *ngIf="
                      commonService.checkControlError(crudTableForm, 'dimH')
                    "
                    matSuffix
                    [iconName]="'infoCircle'"
                    [isStaticSvg]="true"
                    [isFrom]="'Page'"
                    class="cursor"
                    (click)="fetchWidth($event, 'dimH')"
                    (keydown)="$event.stopPropagation()"></app-svg-icon>
                  <app-custom-error-tooltip
                    *ngIf="
                      commonService.checkControlError(crudTableForm, 'dimH') &&
                      setCrudTableErrorTooltip['dimH']
                    "
                    [tooltipWidth]="setCrudTableWidth['dimH']"
                    [tooltipText]="getErrorMessage('dimH')"
                    (tooltipCloseClicked)="
                      commonService.closeErrorTooltip(
                        'dimH',
                        setCrudTableErrorTooltip
                      )
                    ">
                  </app-custom-error-tooltip>
                </mat-form-field>
              </div>
            </ng-container>
            <mat-form-field
              appearance="outline"
              class="mat-sm mat-form-field-disabled"
              *ngIf="
                getAutocompleteFields(item.key) && !getValueField(item.key)
              ">
              <mat-icon
                matPrefix
                class="dropdown-icon"
                *ngIf="
                  item.key === formFields.codeAndDescription ||
                  item.key === formFields.serviceType ||
                  item.key === formFields.rateScale ||
                  item.key === formFields.fuelSurcharge
                "
                >{{ actionIcons.keyboardDownArrow }}</mat-icon
              >
              <input
                matInput
                [formControlName]="item.key"
                [matAutocomplete]="autoCompleteDropdown"
                (input)="formAutocompleteValueChanges(item.key)" />
              <mat-autocomplete
                #autoCompleteDropdown="matAutocomplete"
                (keydown)="$event.stopPropagation()"
                (optionSelected)="onOptionSelectedFormChanges(item.key)">
                <mat-option
                  *ngFor="
                    let data of getAutocompleteOptionsList(item.key) | async
                  "
                  [value]="
                    item.key === formFields.serviceType ? data.id : data.name
                  ">
                  {{
                    item.key === formFields.serviceType ? data.id : data.name
                  }}
                </mat-option>
              </mat-autocomplete>
              <app-svg-icon
                *ngIf="commonService.checkControlError(crudTableForm, item.key)"
                matSuffix
                [iconName]="'infoCircle'"
                [isStaticSvg]="true"
                [isFrom]="'Page'"
                class="cursor info-icon-error"
                (click)="fetchWidth($event, item.key)"
                (keydown)="$event.stopPropagation()"></app-svg-icon>
              <app-custom-error-tooltip
                *ngIf="
                  commonService.checkControlError(crudTableForm, item.key) &&
                  setCrudTableErrorTooltip[item.key]
                "
                [tooltipWidth]="setCrudTableWidth[item.key]"
                [tooltipText]="getErrorMessage(item.key)"
                (tooltipCloseClicked)="
                  commonService.closeErrorTooltip(
                    item.key,
                    setCrudTableErrorTooltip
                  )
                ">
              </app-custom-error-tooltip>
            </mat-form-field>
            <mat-form-field
              appearance="outline"
              class="mat-sm"
              *ngIf="getInputFormControl(item.key) && !getValueField(item.key)">
              <input
                *ngIf="getBasicInputControl(item.key)"
                matInput
                [formControlName]="item.key"
                #firstInput
                (input)="basicInputOnChanges()"
                (blur)="onBlurCrudTableInput()" />
              <input
                *ngIf="getInputControlWithAlphaNumbersHyphen(item.key)"
                matInput
                [formControlName]="item.key"
                (blur)="onBlurCrudTableInput()" />
              <input
                *ngIf="getInputControlWithAlphaNumbers(item.key)"
                matInput
                [formControlName]="item.key"
                (blur)="onBlurCrudTableInput()"
                [appAlphabetsWithNumbers]="2" />
              <input
                *ngIf="getInputControlWithDecimal(item.key)"
                matInput
                [formControlName]="item.key"
                (blur)="onBlurCrudTableInput()"
                [appDigitWithTwoDecimalNumber]="7" />
              <input
                *ngIf="getInputControlWithNumbers(item.key)"
                matInput
                [formControlName]="item.key"
                (blur)="onBlurCrudTableInput()" />
              <input
                *ngIf="getDiscountWithDecimal(item.key)"
                matInput
                [formControlName]="item.key"
                (blur)="onBlurCrudTableInput()"
                [appDigitWithTwoDecimalNumber]="2" />
              <app-svg-icon
                *ngIf="commonService.checkControlError(crudTableForm, item.key)"
                matSuffix
                [iconName]="'infoCircle'"
                [isStaticSvg]="true"
                [isFrom]="'Page'"
                class="cursor info-icon-error"
                (click)="fetchWidth($event, item.key)"
                (keydown)="$event.stopPropagation()"></app-svg-icon>
              <app-custom-error-tooltip
                *ngIf="
                  commonService.checkControlError(crudTableForm, item.key) &&
                  setCrudTableErrorTooltip[item.key]
                "
                [tooltipWidth]="setCrudTableWidth[item.key]"
                [tooltipText]="getErrorMessage(item.key)"
                (tooltipCloseClicked)="
                  commonService.closeErrorTooltip(
                    item.key,
                    setCrudTableErrorTooltip
                  )
                ">
              </app-custom-error-tooltip>
            </mat-form-field>
            <mat-form-field
              appearance="outline"
              class="mat-sm"
              *ngIf="
                (item.key === 'arrivalDate' ||
                  item.key === formFields.dateTimeAndTimezone) &&
                !getValueField(item.key)
              ">
              <input
                (click)="picker.open()"
                matInput
                [matDatepicker]="picker"
                [formControlName]="item.key"
                (dateChange)="onBlurCrudTableInput()" />
              <mat-datepicker
                #picker
                (closed)="datePickerclose('arrivalDate')"></mat-datepicker>
              <app-svg-icon
                *ngIf="commonService.checkControlError(crudTableForm, item.key)"
                matSuffix
                [iconName]="'infoCircle'"
                [isStaticSvg]="true"
                [isFrom]="'Page'"
                class="cursor"
                (click)="fetchWidth($event, item.key); $event.stopPropagation()"
                (keydown)="$event.stopPropagation()"></app-svg-icon>
              <app-custom-error-tooltip
                *ngIf="
                  commonService.checkControlError(crudTableForm, item.key) &&
                  setCrudTableErrorTooltip[item.key]
                "
                [tooltipWidth]="setCrudTableWidth[item.key]"
                [tooltipText]="getErrorMessage(item.key)"
                (tooltipCloseClicked)="
                  commonService.closeErrorTooltip(
                    item.key,
                    setCrudTableErrorTooltip
                  )
                ">
              </app-custom-error-tooltip>
            </mat-form-field>
          </ng-container>
          <ng-template #inputRow>
            <span
              class="ibc-body-text-medium {{
                detectHyperlink(item.key) ? 'text-hyperlink' : ''
              }}">
              {{ element[item.key] }}
            </span>
          </ng-template>
        </mat-cell>
      </ng-container>
    </form>

    <!-- Action column -->
    <ng-container matColumnDef="action">
      <mat-header-cell *matHeaderCellDef>
        <span class="ibc-body-text-large">Actions</span>
      </mat-header-cell>
      <ng-container>
        <mat-cell *matCellDef="let element; let i = index">
          <span
            [attr.aria-hidden]="true"
            *ngIf="!element.isInputRow"
            class="ibc-body-text-medium text-hyperlink cursor"
            (click)="navigateToInformationPage(element, i, 'view', false)"
            (keydown)="$event.stopPropagation()"
            >Details</span
          >
        </mat-cell>
      </ng-container>
    </ng-container>
  </mat-table>
</div>
