<div class="infoPage-Preview-Window">
  <div
    *ngIf="isInfoPagePrintWarning"
    class="infoPagePrintWarningText {{
      isInfoPagePrintClicked ? 'hide-warningText' : 'show-warningText'
    }}">
    {{ warningMessage }}
  </div>

  <div *ngIf="infoPageData" class="print-page {{ pageSize }}">
    <div class="logo" title="PACTrak">
      <img src="assets/Logo.svg" alt="" />
    </div>

    <ng-container *ngFor="let sectionKey of objectKeys(infoPageData)">
      <div class="section-header ibc-heading-h6-small-bold">
        {{ printService.getHeaderSectionKeys(sectionKey) }}
      </div>

      <table class="ibc-print-table">
        <thead>
          <tr>
            <ng-container
              *ngFor="let item of summaryCustomiseTableColumns[sectionKey]">
              <th
                *ngIf="
                  commonTableService.displaySelectedColumnsToTable(
                    item.key,
                    summaryCustomiseTableColumns[sectionKey],
                    displayedColumns[sectionKey]
                  )
                "
                [style.flex]="'0 0 ' + item.defaultPrintWidth + 'px'">
                <div class="ibc-body-text-large-bold table-text">{{ item.label }}</div>
              </th>
            </ng-container>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let element of dataSources[sectionKey]">
            <ng-container
              *ngFor="let item of summaryCustomiseTableColumns[sectionKey]">
              <td
                *ngIf="
                  commonTableService.displaySelectedColumnsToTable(
                    item.key,
                    summaryCustomiseTableColumns[sectionKey],
                    displayedColumns[sectionKey]
                  )
                "
                [style.flex]="'0 0 ' + item.defaultPrintWidth + 'px'">
                <span
                  class="ibc-body-text-regular table-text {{
                    commonTableService.isHyperlink(item.label, isFrom)
                      ? 'text-hyperlink'
                      : ''
                  }}">
                  {{
                    item.key !== 'date'
                      ? element[item.key]
                      : (element.date | date: dateFormat)
                  }}
                </span>
              </td>
            </ng-container>
          </tr>
        </tbody>
      </table>
    </ng-container>
  </div>

  <button
    class="ibc-medium-button ibc-primary-default-button cursor infoPage-preview-print-btn {{
      isInfoPagePrintClicked ? 'hide-btn' : 'show-btn'
    }}"
    (click)="print()">
    <span class="ibc-body-text-regular">
      {{ popupButtons.Print }}
    </span>
  </button>
</div>
