import { TableColumnsProp } from 'src/app/shared/models/tables.model';
import {
  AccountGridContent,
  CustomerServiceGridContent,
} from './accounts.model';
import {
  AccordionTabs,
  searchDataParam,
} from 'src/app/shared/utility/constants';
import {
  ButtonsConfig,
  ComponentMapping,
  FormConfig,
} from 'src/app/shared/models/dynamic.model';
import { BusinessInformationComponent } from './accounts-information/business-information/business-information.component';
import { GovernmentInformationComponent } from './accounts-information/government-information/government-information.component';
import { AddressComponent } from './accounts-information/address/address.component';
import {
  IAccountsDetailData,
  IAddressInfoDetailsData,
  IBusinessInfoDetailsData,
  IGovernmentInfoDetailsData,
} from '../../model/sm-accounts';

export const accountsAddressTab = ['address', 'billingAddress'];

export const defautAccountPagination = {
  sortingOrder: searchDataParam.desc,
  sortingField: searchDataParam.accountId,
  fromIndex: 0,
  toIndex: 10,
};

export const accountSummaryGridColumns: TableColumnsProp[] = [
  {
    key: 'accountNumber',
    label: 'Account Number',
    isSelected: true,
    defaultView: true,
  },
  {
    key: 'accountName',
    label: 'Account Name',
    isSelected: true,
    defaultView: true,
  },
  {
    key: 'organizationName',
    label: 'Organization Name',
    isSelected: true,
    defaultView: true,
  },
  {
    key: 'shipments',
    label: 'Shipment Count',
    isSelected: true,
    defaultView: true,
  },
  {
    key: 'additionalInfo',
    label: 'Additonal Information',
    isSelected: true,
    defaultView: true,
  },
];

export const AccountTableData: AccountGridContent[] = [
  // {
  //   accountNumber: '234567',
  //   accountName: 'US Logistics New York',
  //   organizationName: 'XPO Logistics',
  //   shipmentCount: 200,
  //   additionalInfo: 'Lorem ipsum',
  // },
  // {
  //   accountNumber: '345222',
  //   accountName: 'US Distribution Texas',
  //   organizationName: 'XPO Logistics',
  //   shipmentCount: 100,
  //   additionalInfo: 'Lorem ipsum',
  // },
  // {
  //   accountNumber: '341133',
  //   accountName: 'US Shipments California',
  //   organizationName: 'XPO Logistics',
  //   shipmentCount: 260,
  //   additionalInfo: 'Lorem ipsum',
  // },
  // {
  //   accountNumber: '345097',
  //   accountName: 'US Sales Texas',
  //   organizationName: 'XPO Logistics',
  //   shipmentCount: 408,
  //   additionalInfo: 'Lorem ipsum',
  // },
  // {
  //   accountNumber: '336134',
  //   accountName: 'US Distribution Hawaii',
  //   organizationName: 'XPO Logistics',
  //   shipmentCount: 408,
  //   additionalInfo: 'Lorem ipsum',
  // },
  // {
  //   accountNumber: '456134',
  //   accountName: 'US Logistics New Mexico ',
  //   organizationName: 'XPO Logistics',
  //   shipmentCount: 510,
  //   additionalInfo: 'Lorem ipsum',
  // },
];

export const AccountInfoCrudFormConfig: FormConfig = {
  fields: [
    {
      id: 'accountName',
      type: 'input',
      label: 'Account Name',
      required: true,
      requiredError: 'Account Name is required',
      allowAlphabet: true,
      minValue: 2,
      maxValue: 126,
      minError: 'Invalid Account Name entered',
    },
    {
      id: 'accountNumber',
      type: 'input',
      label: 'Account Number',
      required: true,
      requiredError: 'Account Number is required',
      allowNumeric: true,
      minValue: 7,
      maxValue: 7,
      minError: 'Invalid Account Number entered',
    },
    {
      id: 'organizationName',
      type: 'select',
      label: 'Organization Name',
      required: true,
      requiredError: 'Organization Name is required',
    },
    {
      id: 'description',
      type: 'input',
      label: 'Description',
      noInitialWhiteSpace: true,
      allowAlphaNumericWithSpace: true,
      maxValue: 126,
      isCheckForMaxValue: true,
    },
    {
      id: 'additionalInformation',
      type: 'input',
      label: 'Additonal Information',
      noInitialWhiteSpace: true,
      allowAlphaNumericWithSpace: true,
      maxValue: 500,
      isCheckForMaxValue: true,
    },
    {
      id: 'accountId',
      type: 'extra',
    },
    {
      id: 'organizationId',
      type: 'extra',
    },
  ],
};

export const accountsAccordionTabConfig: ComponentMapping[] = [
  {
    title: AccordionTabs.Address,
    componentType: AddressComponent,
  },
  {
    title: AccordionTabs.BusinessInformation,
    componentType: BusinessInformationComponent,
  },
  {
    title: AccordionTabs.GovernmentInformation,
    componentType: GovernmentInformationComponent,
  },
  { title: AccordionTabs.Attachments, componentType: '' },
  { title: AccordionTabs.Events, componentType: '' },
  { title: AccordionTabs.Details, componentType: '' },
];

export const organizationCrudFormConfig: FormConfig = {
  fields: [
    {
      id: 'organizationName',
      type: 'input',
      label: 'Organization Name',
      required: true,
      requiredError: 'Organization Name is required',
    },
    {
      id: 'description',
      type: 'input',
      label: 'Description',
    },
    {
      id: 'additionalInfo',
      type: 'input',
      label: 'Additional Information',
    },
  ],
};

export const commonBtnForModalFooter = [
  {
    name: 'Cancel',
    btnClass: 'ibc-medium-button ibc-secondary-default-button cursor',
  },
];

export const buttonsForAccountsAlreadyExistModal: ButtonsConfig[] = [
  ...commonBtnForModalFooter,
  {
    name: 'Try Another',
    btnClass: 'ibc-medium-button ibc-secondary-default-button cursor',
  },
  {
    name: 'Create Linked Account',
    btnClass: 'ibc-secondary-accent-button-md cursor',
  },
  {
    name: 'Use Suggested Account',
    btnClass: 'ibc-medium-button ibc-primary-default-button cursor',
  },
];

export const buttonsForFirstLinkedAccount: ButtonsConfig[] = [
  ...commonBtnForModalFooter,
  {
    name: 'Proceed',
    btnClass: 'ibc-medium-button ibc-primary-default-button cursor',
  },
];

export const businessInformationFormFields: FormConfig = {
  fields: [
    {
      id: 'DUNSNumber',
      type: 'input',
      label: 'DUNS Number',
      noInitialWhiteSpace: true,
      allowNumeric: true,
      minValue: 9,
      maxValue: 9,
      minError: 'Invalid DUNS Number entered',
    },
    {
      id: 'FEINNumber',
      type: 'input',
      label: 'FEIN Number',
      noInitialWhiteSpace: true,
      allowNumeric: true,
      minValue: 9,
      maxValue: 9,
      minError: 'Invalid FEIN Number entered',
    },
    {
      id: 'stateTaxId',
      type: 'input',
      label: 'State Tax ID',
      noInitialWhiteSpace: true,
      allowNumeric: true,
      minValue: 9,
      maxValue: 9,
      minError: 'Invalid State Tax ID entered',
    },
  ],
};

export const governmentInformationFields: FormConfig = {
  fields: [
    {
      id: 'salesPerson',
      type: 'select',
      label: 'Sales Person',
      required: true,
      requiredError: 'Sales Person is required',
    },
    {
      id: 'salesPersonId',
      type: 'extra',
      label: 'Sales Person',
    },
    {
      id: 'clearanceType',
      type: 'select',
      label: 'Clearance Type',
    },
    {
      id: 'clearanceTypeId',
      type: 'extra',
      label: 'Clearance Type',
    },
    {
      id: 'selectEmailsForInvoiceNotification',
      type: 'multi-select-autocomplete',
      label: 'Select Emails for Invoice Notification',
    },
    {
      id: 'selectEmailsforGatewayNotification',
      type: 'multi-select-autocomplete',
      label: 'Select Emails for Gateway Notification',
    },
  ],
};

export const customerServiceTableData: CustomerServiceGridContent[] = [
  {
    id: 0,
    tempId: 0,
    serviceType: '',
    serviceTypeId: 0,
    discount: '',
    rateScale: '',
    rateScaleId: 0,
    fuelSurcharge: '',
    fuelSurchargeId: 0,
    isInputRow: true,
  },
];

export const customerServiceTableColumnConfig: TableColumnsProp[] = [
  {
    key: 'serviceType',
    label: 'Service Type',
    isSelected: true,
    defaultPrintWidth: 100,
  },
  {
    key: 'discount',
    label: 'Discount',
    isSelected: true,
    defaultPrintWidth: 100,
  },
  {
    key: 'rateScale',
    label: 'Rate Scale',
    isSelected: true,
    defaultPrintWidth: 100,
  },
  {
    key: 'fuelSurcharge',
    label: 'Fuel Surcharge',
    isSelected: true,
    defaultPrintWidth: 100,
  },
];

export const customerServiceFormConfig: FormConfig = {
  fields: [
    {
      id: 'serviceType',
      type: 'select',
      label: 'Service Type',
      required: true,
      requiredError: 'Service Type is required',
    },
    {
      id: 'serviceTypeId',
      type: 'extra',
    },
    {
      id: 'discount',
      type: 'input',
      label: 'Discount',
    },
    {
      id: 'rateScale',
      type: 'select',
      label: 'Rate Scale',
      required: true,
      requiredError: 'Rate Scale is required',
    },
    {
      id: 'rateScaleId',
      type: 'extra',
    },
    {
      id: 'fuelSurcharge',
      type: 'select',
      label: 'Fuel Surcharge',
    },
    {
      id: 'fuelSurchargeId',
      type: 'extra',
    },
  ],
};

export const customerServiceInputRow: CustomerServiceGridContent[] = [
  {
    id: 0,
    tempId: 0,
    serviceType: '',
    serviceTypeId: 0,
    discount: '',
    rateScale: '',
    rateScaleId: 0,
    fuelSurcharge: '',
    fuelSurchargeId: 0,
    isInputRow: true,
  },
];

export const firstLinkedAccountRadioOptions: string[] = [
  'Change Existing Shipments to Account 1234501',
  'Leave existing shipments as Account 1234500',
];

export const defaultAccountsDetailData = (): IAccountsDetailData => ({
  accountName: '',
  accountNumber: 0,
  organizationName: '',
  organizationId: 0,
  description: '',
  additionalInformation: '',
  accountId: 0,
});

export const defaultAccountsAddressInfoData = (): IAddressInfoDetailsData => ({
  address: defaultAccountsAddressData,
  billingAddress: defaultAccountsAddressData,
  isSameAsAdress: false,
});

export const defaultAccountsAddressData = {
  country: '',
  phone: '',
  email: '',
  name: '',
  companyName: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  postalCode: '',
  contactPerson: '',
};

export const defaultAccountsBusinessInfoData =
  (): IBusinessInfoDetailsData => ({
    DUNSNumber: '',
    FEINNumber: '',
    stateTaxId: '',
  });

export const defaultAccountsGovernmentInfoData =
  (): IGovernmentInfoDetailsData => ({
    salesPerson: '',
    salesPersonId: 0,
    clearanceType: '',
    clearanceTypeId: 0,
    selectEmailsForInvoiceNotification: [],
    selectEmailsforGatewayNotification: [],
    customerService: defaultAccountsGovernmentCustomerServiceData,
  });

export const defaultAccountsGovernmentCustomerServiceData = [
  {
    id: 0,
    serviceType: '',
    serviceTypeId: 0,
    discount: '',
    rateScale: '',
    rateScaleId: 0,
    fuelSurcharge: '',
    fuelSurchargeId: 0,
    isInputRow: true,
  },
];

export const testData = {
  accountName: 'cdsf',
  accountNumber: 3450363,
  billingAddressId: 1260,
  salesPersonId: 17,
  doGateway: false,
  dunsNum: '0          ',
  depositAmount: 0,
  insuranceRate: 0,
  onboardingTicketId: 0,
  clearanceType: 'C',
  extra: {
    FEINNumber: 0,
    stateTaxId: 0,
    description: 'sdfsd',
    additionalInfo: 'sdgf',
    organizationId: 4,
    selectEmailsForInvoiceNotification: [],
    selectEmailsforGatewayNotification: [],
  },
  accountId: 36,
  addressData: {
    address: {
      id: 1259,
      city: 'sdgfsd',
      countryCode: 'IN',
      state: '',
      address_1: 'dgsd',
      address_2: '',
      zipCode: '',
      phone: '',
      email: '',
      name: 'sdgf',
      companyName: 'sdg',
      url: null,
    },
    billingAddress: {
      id: 1260,
      city: 'sdg',
      countryCode: 'IN',
      state: '',
      address_1: 'sdg',
      address_2: '',
      zipCode: '',
      phone: '',
      email: '',
      name: 'sdgs',
      companyName: 'sdgfsdg',
      url: null,
    },
  },
  businessInformation: {
    DUNSNumber: '0          ',
    FEINNumber: 0,
    stateTaxId: 0,
  },
  governmentInformation: {
    salesPerson: 17,
    salesPersonId: 17,
    clearanceType: 'C',
    clearanceTypeId: 'C',
    customerService: [
      {
        id: 25,
        serviceType: 'IP ',
        serviceTypeId: 'IP ',
        discount: '3.00',
        rateScale: 1,
        rateScaleId: 1,
        fuelSurcharge: 14,
        fuelSurchargeId: 14,
      },
    ],
  },
  details: {
    accountId: 36,
    organizationId: 4,
  },
  events: [
    {
      dateTime: '2024-12-06T11:29:44.449Z',
      eventType: 'acc',
      performedBy: 1,
    },
  ],
};
