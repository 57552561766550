import {
  defaultRoleModal,
  RoleModal,
} from '../advanced-search-roles/advanced-search-roles.config';

export interface ConsigneeModalStateModel {
  consigneeModals: RoleModal;
}

export const defaultConsigneeState: ConsigneeModalStateModel = {
  consigneeModals: defaultRoleModal,
};
